<script setup>
import { defineEmits, computed } from 'vue';
import FilterTag from './FilterTag';
const emits = defineEmits(['remove-filter'])
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
// eslint-disable-next-line no-unused-vars
const translationAliases = [
  t('taxhub.pages.Reports.ListReports.FilterTags.contractCountry.filterValues-'),
  t('taxhub.pages.Reports.ListReports.FilterTags.report.type.filterValues-'),
  t('taxhub.pages.Reports.ListReports.FilterTags.payment.instrument.filterValues-')
]

import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc);
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);

import { each, get } from 'lodash';

import { useReportsParameterStore } from "../../../stores/reportsParameters";
const reportsParametersStore = useReportsParameterStore();

const spCountryId = computed(() => {
  return t(`taxhub.pages.Reports.ListReports.FilterTags.contractCountry.filterValues-${reportsParametersStore.queryParameters.spCountryId}`);
})
const spReportTypeId = computed(() => {
  return  t(`taxhub.pages.Reports.ListReports.FilterTags.report.type.filterValues-${reportsParametersStore.queryParameters.spReportTypeId}`);
})
const spPaymentInstrumentId = computed(() => {
  return  t(`taxhub.pages.Reports.ListReports.FilterTags.payment.instrument.filterValues-${reportsParametersStore.queryParameters.spPaymentInstrumentId}`);
})
function mapOperator(operator) {
  switch (operator) {
    case 'eq':
      return t('operator.on.displayValue');
    case 'lte':
      return t('operator.onOrBefore.displayValue');
    case 'gte':
      return t('operator.onOrAfter.displayValue');
    default:
      return '';
  }
}
function readLocalizedDate(date) {
  return dayjs.utc(date).local().format('LL')
}
const orderDate = computed(() => {
  return t('order.date.label', {operator: mapOperator(reportsParametersStore.queryParameters.orderDateOp), date: readLocalizedDate(get(reportsParametersStore.queryParameters, 'orderDate', null))})
})
const paymentStartTime = computed(() => {
  return t('payment.startTime.label', {operator: mapOperator(reportsParametersStore.queryParameters.paymentStartTimeOp), date: readLocalizedDate(get(reportsParametersStore.queryParameters, 'paymentStartTime', null))})
})
const reportingPeriodStartDate = computed(() => {
  return t('reportingPeriod.startDate.label', {operator: mapOperator(reportsParametersStore.queryParameters.reportingPeriodStartDateOp), date: readLocalizedDate(get(reportsParametersStore.queryParameters, 'reportingPeriodStartDate', null))})
})
const reportingPeriodEndDate = computed(() => {
  return t('reportingPeriod.endDate.label', {operator: mapOperator(reportsParametersStore.queryParameters.reportingPeriodEndDateOp), date: readLocalizedDate(get(reportsParametersStore.queryParameters, 'reportingPeriodEndDate', null))})
})

async function removeFilters(properties) {
  let query = {};
  each(properties, function(property) {
    query[property] = undefined;
    query['page'] = undefined;
  });
  reportsParametersStore.pushQueryParameters(query);
  emits('remove-filter');
}
function removeAllFilters() {
  removeFilters([
    'spCountryId',
    'reportingPeriodStartDate',
    'reportingPeriodStartDateOp',
    'reportingPeriodEndDate',
    'reportingPeriodEndDateOp',
    'spReportTypeId',
    'orderDate',
    'orderDateOp',
    'paymentStartTime',
    'paymentStartTimeOp',
    'spPaymentInstrumentId'
  ]);
}

</script>
<script>
  export default {
    name: "FilterTags"
  }
</script>

<template>
  <div style="text-align: end;">
    <span v-if="reportsParametersStore.hasFilter" style="white-space: nowrap;">{{t('activeFilter.label')}}:</span>
    <FilterTag v-if="reportsParametersStore.hasSpCountryId" v-on:close="removeFilters(['spCountryId'])">{{spCountryId}}</FilterTag>
    <FilterTag v-if="reportsParametersStore.hasSpReportTypeId" v-on:close="removeFilters(['spReportTypeId'])">{{spReportTypeId}}</FilterTag>
    <FilterTag v-if="reportsParametersStore.hasSpPaymentInstrumentId" v-on:close="removeFilters(['spPaymentInstrumentId'])">{{spPaymentInstrumentId}}</FilterTag>
    <FilterTag v-if="reportsParametersStore.hasOrderDate" v-on:close="removeFilters(['orderDate', 'orderDateOp'])">{{orderDate}}</FilterTag>
    <FilterTag v-if="reportsParametersStore.hasPaymentStartTime" v-on:close="removeFilters(['paymentStartTime', 'paymentStartTimeOp'])">{{paymentStartTime}}</FilterTag>
    <FilterTag v-if="reportsParametersStore.hasReportingPeriodStartDate" v-on:close="removeFilters(['reportingPeriodStartDate', 'reportingPeriodStartDateOp'])">{{reportingPeriodStartDate}}</FilterTag>
    <FilterTag v-if="reportsParametersStore.hasReportingPeriodEndDate" v-on:close="removeFilters(['reportingPeriodEndDate', 'reportingPeriodEndDateOp'])">{{reportingPeriodEndDate}}</FilterTag>
    <dp-button purpose="secondary" type="text" @click="removeAllFilters" style="margin-inline-start: 1em; margin-bottom: 1em;" size="small">{{t('clearAllFilters.buttonLabel')}}</dp-button>
  </div>
</template>

<i18n>
{
  "ar-AE": {
    "activeFilter": {
      "label": "المرشحات النشطة"
    },
    "clearAllFilters": {
      "buttonLabel": "مرشحات واضحة"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "{operator} {date} :تاريخ بدء الإبلاغ"
      },
      "endDate": {
        "label": "{date} {operator} :تاريخ انتهاء التقرير"
      }
    },
    "order": {
      "date": {
        "label": "{date} {operator} :تاريخ الطلب"
      }
    },
    "payment": {
      "startTime": {
        "label": "{date} {operator} :وقت السداد"
      }
    }
  },
  "ar-EG": {
    "activeFilter": {},
    "clearAllFilters": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "order": {
      "date": {}
    },
    "payment": {
      "startTime": {}
    }
  },
  "ar-SA": {
    "activeFilter": {},
    "clearAllFilters": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "order": {
      "date": {}
    },
    "payment": {
      "startTime": {}
    }
  },
  "de-DE": {
    "activeFilter": {
      "label": "Aktive Filter"
    },
    "clearAllFilters": {
      "buttonLabel": "Filter löschen"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Meldezeitraumbeginn: {operator} {date}"
      },
      "endDate": {
        "label": "Meldezeitraumende: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Zahlungszeitpunkt: {operator} {date}"
      }
    }
  },
  "de-AT": {
    "activeFilter": {
      "label": "Aktive Filter"
    },
    "clearAllFilters": {
      "buttonLabel": "Filter löschen"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Meldezeitraumbeginn: {date}"
      },
      "endDate": {
        "label": "Meldezeitraumende: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    }
  },
  "de-CH": {
    "activeFilter": {
      "label": "Aktive Filter"
    },
    "clearAllFilters": {
      "buttonLabel": "Filter löschen"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Meldezeitraumbeginn: {date}"
      },
      "endDate": {
        "label": "Meldezeitraumende: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    }
  },
  "en-US": {
    "activeFilter": {
      "label": "Active filters"
    },
    "clearAllFilters": {
      "buttonLabel": "Clear filters"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Reporting start date: {operator} {date}"
      },
      "endDate": {
        "label": "Reporting end date: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Order date: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Transfer time: {operator} {date}"
      }
    }
  },
  "en-GB": {
    "activeFilter": {
      "label": "Active filters"
    },
    "clearAllFilters": {
      "buttonLabel": "Clear filters"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Reporting start date: {date}"
      },
      "endDate": {
        "label": "Reporting end date: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Order date: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Transfer time"
      }
    }
  },
  "it-IT": {
    "activeFilter": {
      "label": "Filtri attivi"
    },
    "clearAllFilters": {
      "buttonLabel": "Cancella filtri"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Data di inizio della segnalazione: {operator} {date}"
      },
      "endDate": {
        "label": "Data di fine del rapporto: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Data dell'ordine: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Tempo di trasferimento: {operator} {date}"
      }
    }
  },
  "fr-FR": {
    "activeFilter": {
      "label": "Filtres actifs"
    },
    "clearAllFilters": {
      "buttonLabel": "Effacer les filtres"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Date de début du rapport: {operator} {date}"
      },
      "endDate": {
        "label": "Date de fin du rapport: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Date de commande: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Temps de transfert: {operator} {date}"
      }
    }
  },
  "es-ES": {
    "activeFilter": {
      "label": "Filtros activos"
    },
    "clearAllFilters": {
      "buttonLabel": "Filtros claros"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Fecha de inicio de informes: {operator} {date}"
      },
      "endDate": {
        "label": "Fecha de finalización del informe: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Fecha de orden: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Tiempo de transferencia: {operator} {date}"
      }
    }
  },
  "pl-PL": {
    "activeFilter": {
      "label": "Aktywne filtry"
    },
    "clearAllFilters": {
      "buttonLabel": "wyczyść filtry"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Data rozpoczęcia raportowania: {operator} {date}"
      },
      "endDate": {
        "label": "Data zakończenia raportowania: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Data zatwierdzenia danych : {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Data płatności: {operator} {date}"
      }
    }
  },
  "nl-NL": {
    "activeFilter": {
      "label": "Actieve filters"
    },
    "clearAllFilters": {
      "buttonLabel": "duidelijke filters"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Startdatum rapportage: {operator} {date}"
      },
      "endDate": {
        "label": "Einddatum rapportage: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Besteldatum: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Tijdstip van betaling: {operator} {date}"
      }
    }
  },
  "bg-BG": {
    "activeFilter": {
      "label": "Активни филтри"
    },
    "clearAllFilters": {
      "buttonLabel": "изчистване на филтрите"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Отчитане на начална дата: {operator} {date}"
      },
      "endDate": {
        "label": "Крайна дата на отчитане: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Дата на поръчка: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Време на плащане: {operator} {date}"
      }
    }
  },
  "bs-BA": {
    "activeFilter": {
      "label": "Aktivni filtri"
    },
    "clearAllFilters": {
      "buttonLabel": "jasni filtri"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum početka izvještavanja: {operator} {date}"
      },
      "endDate": {
        "label": "Krajnji datum izvještavanja: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Datum narudžbe: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Vrijeme plaćanja: {operator} {date}"
      }
    }
  },
  "da-DK": {
    "activeFilter": {
      "label": "Aktive filtre"
    },
    "clearAllFilters": {
      "buttonLabel": "ryd filtre"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Rapporterings startdato: {operator} {date}"
      },
      "endDate": {
        "label": "Rapporterings slutdato: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Bestillingsdato: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Tidspunkt for betaling: {operator} {date}"
      }
    }
  },
  "et-EE": {
    "activeFilter": {
      "label": "Aktiivsed filtrid"
    },
    "clearAllFilters": {
      "buttonLabel": "tühjendage filtrid"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Aruande alguskuupäev: {operator} {date}"
      },
      "endDate": {
        "label": "Aruande lõppkuupäev: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Tellimuse kuupäev: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Makse aeg: {operator} {date}"
      }
    }
  },
  "fi-FI": {
    "activeFilter": {
      "label": "Aktiiviset suodattimet"
    },
    "clearAllFilters": {
      "buttonLabel": "tyhjennä suodattimet"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Raportoinnin alkamispäivä: {operator} {date}"
      },
      "endDate": {
        "label": "Raportoinnin lopetuspäivä: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Tilauspäivämäärä: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Maksuaika: {operator} {date}"
      }
    }
  },
  "el-GR": {
    "activeFilter": {
      "label": "Ενεργά φίλτρα"
    },
    "clearAllFilters": {
      "buttonLabel": "καθαρά φίλτρα"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ημερομηνία έναρξης αναφοράς: {operator} {date}"
      },
      "endDate": {
        "label": "Ημερομηνία λήξης αναφοράς: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Ώρα πληρωμής: {operator} {date}"
      }
    }
  },
  "he-IL": {
    "activeFilter": {
      "label": "פילטרים פעילים"
    },
    "clearAllFilters": {
      "buttonLabel": "מסננים ברורים"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "{operator} {date} :מדווח על תאריך התחלה"
      },
      "endDate": {
        "label": "תאריך סיום מדווח: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "{date} {operator} :תאריך הזמנה"
      }
    },
    "payment": {
      "startTime": {
        "label": "{date} {operator} :זמן התשלום"
      }
    }
  },
  "ga-IE": {
    "activeFilter": {
      "label": "Scagairí gníomhacha"
    },
    "clearAllFilters": {
      "buttonLabel": "scagairí soiléire"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Dáta tosaigh tuairiscithe: {operator} {date}"
      },
      "endDate": {
        "label": "Dáta deiridh tuairiscithe: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Dáta ordaithe: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Am na híocaíochta: {operator} {date}"
      }
    }
  },
  "ja-JP": {
    "activeFilter": {
      "label": "アクティブフィルター"
    },
    "clearAllFilters": {
      "buttonLabel": "フィルターをクリア"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "レポート開始日: {operator} {date}"
      },
      "endDate": {
        "label": "レポート終了日: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "注文日: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "お支払い時期: {operator} {date}"
      }
    }
  },
  "hr-HR": {
    "activeFilter": {
      "label": "Aktivni filtri"
    },
    "clearAllFilters": {
      "buttonLabel": "čisti filtri"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum početka izvještavanja: {operator} {date}"
      },
      "endDate": {
        "label": "Krajnji datum izvještavanja: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Datum narudžbe: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Vrijeme plaćanja: {operator} {date}"
      }
    }
  },
  "lv-LV": {
    "activeFilter": {
      "label": "Aktīvie filtri"
    },
    "clearAllFilters": {
      "buttonLabel": "notīrīt filtrus"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ziņošanas sākuma datums: {operator} {date}"
      },
      "endDate": {
        "label": "Pārskata beigu datums: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Pasūtījuma datums: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Apmaksas laiks: {operator} {date}"
      }
    }
  },
  "lt-LT": {
    "activeFilter": {
      "label": "Aktyvūs filtrai"
    },
    "clearAllFilters": {
      "buttonLabel": "išvalyti filtrus"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ataskaitų teikimo pradžios data: {operator} {date}"
      },
      "endDate": {
        "label": "Ataskaitos pabaigos data: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Užsakymo data: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Mokėjimo laikas: {operator} {date}"
      }
    }
  },
  "pt-PT": {
    "activeFilter": {
      "label": "Filtros ativos"
    },
    "clearAllFilters": {
      "buttonLabel": "limpar filtros"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Data de início do relatório: {operator} {date}"
      },
      "endDate": {
        "label": "Data de término do relatório: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Data do pedido: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Hora do pagamento: {operator} {date}"
      }
    }
  },
  "ro-RO": {
    "activeFilter": {
      "label": "Filtre active"
    },
    "clearAllFilters": {
      "buttonLabel": "filtre clare"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Data începerii raportării: {operator} {date}"
      },
      "endDate": {
        "label": "Data de încheiere a raportării: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Data comandă: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Ora de plată: {operator} {date}"
      }
    }
  },
  "ru-RU": {
    "activeFilter": {
      "label": "Активные фильтры"
    },
    "clearAllFilters": {
      "buttonLabel": "очистить фильтры"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Дата начала отчетности: {operator} {date}"
      },
      "endDate": {
        "label": "Дата окончания отчетности: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Дата заказа: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Время оплаты: {operator} {date}"
      }
    }
  },
  "sv-SE": {
    "activeFilter": {
      "label": "Aktiva filter"
    },
    "clearAllFilters": {
      "buttonLabel": "rensa filter"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Rapporteringsstartdatum: {operator} {date}"
      },
      "endDate": {
        "label": "Rapporteringens slutdatum: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Orderdatum: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Betalningstid: {operator} {date}"
      }
    }
  },
  "sk-SK": {
    "activeFilter": {
      "label": "Aktívne filtre"
    },
    "clearAllFilters": {
      "buttonLabel": "vyčistiť filtre"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Dátum začiatku vykazovania: {operator} {date}"
      },
      "endDate": {
        "label": "Dátum ukončenia prehľadu: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Dátum objednávky: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Čas platby: {operator} {date}"
      }
    }
  },
  "cs-CZ": {
    "activeFilter": {
      "label": "Aktivní filtry"
    },
    "clearAllFilters": {
      "buttonLabel": "vymazat filtry"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum zahájení hlášení: {operator} {date}"
      },
      "endDate": {
        "label": "Datum ukončení vykazování: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Datum objednávky: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Čas platby: {operator} {date}"
      }
    }
  },
  "tr-TR": {
    "activeFilter": {
      "label": "Aktif filtreler"
    },
    "clearAllFilters": {
      "buttonLabel": "filtreleri temizle"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Raporlama başlangıç tarihi: {operator} {date}"
      },
      "endDate": {
        "label": "Raporlama bitiş tarihi: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Ödeme zamanı: {operator} {date}"
      }
    }
  },
  "hu-HU": {
    "activeFilter": {
      "label": "Aktív szűrők"
    },
    "clearAllFilters": {
      "buttonLabel": "törölje a szűrőket"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Jelentés kezdete: {operator} {date}"
      },
      "endDate": {
        "label": "Jelentés befejezési dátuma: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Rendelés dátuma: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "A fizetés időpontja: {operator} {date}"
      }
    }
  },
  "fr-BE": {
    "activeFilter": {
      "label": "Filtres actifs"
    },
    "clearAllFilters": {
      "buttonLabel": "Effacer les filtres"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Date de début du rapport: {date}"
      },
      "endDate": {
        "label": "Date de fin du rapport: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Date de commande: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Temps de transfert: {operator} {date}"
      }
    }
  },
  "fr-LU": {
    "activeFilter": {
      "label": "Filtres actifs"
    },
    "clearAllFilters": {
      "buttonLabel": "Effacer les filtres"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Date de début du rapport: {date}"
      },
      "endDate": {
        "label": "Date de fin du rapport: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Date de commande: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Temps de transfert: {operator} {date}"
      }
    }
  },
  "mt-MT": {
    "activeFilter": {
      "label": "Filtri attivi"
    },
    "clearAllFilters": {
      "buttonLabel": "Filtri Ċari"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Bidu tal-perjodu tar-rappurtar: {data}"
      },
      "endDate": {
        "label": "Tmiem tal-perjodu tar-rappurtar: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Data tal-ordni: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Ħin tat-trasferiment: {operator} {date}"
      }
    }
  },
  "sl-SI": {
    "activeFilter": {
      "label": "Aktivni filtri"
    },
    "clearAllFilters": {
      "buttonLabel": "čisti filtri"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum začetka poročanja: {operator} {date}"
      },
      "endDate": {
        "label": "Končni datum poročanja: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Datum naročila: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Čas plačila: {operator} {date}"
      }
    }
  },
  "el-CY": {
    "activeFilter": {
      "label": "Ενεργά φίλτρα"
    },
    "clearAllFilters": {
      "buttonLabel": "καθαρά φίλτρα"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ημερομηνία έναρξης αναφοράς: {date}"
      },
      "endDate": {
        "label": "Ημερομηνία λήξης αναφοράς: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Ώρα πληρωμής: {operator} {date}"
      }
    }
  },
  "tr-CY": {
    "activeFilter": {
      "label": "Aktif filtreler"
    },
    "clearAllFilters": {
      "buttonLabel": "filtreleri temizle"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Raporlama başlangıç tarihi: {date}"
      },
      "endDate": {
        "label": "Raporlama bitiş tarihi: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi: {operator} {date}"
      }
    },
    "payment": {
      "startTime": {
        "label": "Ödeme zamanı: {operator} {date}"
      }
    }
  },
  "uk-UA": {
    "activeFilter": {},
    "clearAllFilters": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "order": {
      "date": {}
    },
    "payment": {
      "startTime": {}
    }
  },
  "zh-CN": {
    "activeFilter": {
      "label": "有源过滤器"
    },
    "clearAllFilters": {
      "buttonLabel": "清除筛选"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "报告开始日期: {operator} {date}"
      },
      "endDate": {
        "label": "报告结束日期: {operator} {date}"
      }
    },
    "order": {
      "date": {
        "label": "{date} {operator} :订购日期"
      }
    },
    "payment": {
      "startTime": {
        "label": "{date} {operator} :付款时间"
      }
    }
  }
}
</i18n>
