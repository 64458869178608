<script setup>
import { inject, ref, computed, defineEmits, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n'
import {useReportsParameterStore} from "../../../stores/reportsParameters";
import { isEmpty, get, trim } from 'lodash';
import {ElInput} from 'element-plus';
import 'element-plus/es/components/input/style/css';

const { t } = useI18n()
const composables = inject('composables');
const { isMobile } = composables.useViewportSize()
const searchQuery = ref('');
const reportsParameterStore = useReportsParameterStore();
const emit = defineEmits(['submit', 'clear-search']);

const { queryParameters } = storeToRefs(reportsParameterStore);
const hasSearchQueryParameter = computed(() => {
  return !isEmpty(get(queryParameters.value, 'search', undefined))
})
watch(queryParameters, () => {
  getSearchQuery();
})
function getSearchQuery() {
  searchQuery.value = get(queryParameters.value, 'search', '');
}
function setSearchParameter() {
  reportsParameterStore.pushQueryParameters({
    page: undefined,
    search: trim(searchQuery.value) || undefined
  });
}
function applySearch() {
  setSearchParameter();
  emit('submit');
}
function clearSearch() {
  searchQuery.value = undefined;
  setSearchParameter();
  emit('clear-search');
}
function inputSearch() {
  if (isEmpty(searchQuery.value))
    clearSearch();
}

onMounted(() => {
  getSearchQuery();
})
</script>
<script>
export default {
  name: "SearchBar"
}
</script>

<template>
  <form class="search-bar" :class="{'search-bar-mobile': isMobile}" @submit.prevent="applySearch">
    <el-input size="large"
              @keyup.enter="applySearch"
              style="width: 100%;"
              v-model="searchQuery"
              :clearable="true"
              v-on:input="inputSearch"
              v-on:clear="clearSearch"
              :class="{active: hasSearchQueryParameter}"
              :placeholder="t('search.placeholder')">
    </el-input>
  </form>
</template>

<style lang="scss">
  .search-bar {
    &.search-bar-mobile {
      .el-input__inner {
        padding-top: .9em;
        padding-bottom: .9em;
        height: 2.8em;
        font-size: 1.2em;

        &::placeholder {
          text-align: center;
        }
      }
    }

    .search-button {
      margin-inline-start: .7rem;
    }

    .el-input__wrapper {
      border-color: #DCDFE6;
    }

    &.is-focus {
      border-color: #5399d3;
    }
  }
</style>

<i18n>
{
  "ar-AE": {
    "search": {
      "placeholder": "بحث"
    }
  },
  "ar-EG": {
    "search": {}
  },
  "ar-SA": {
    "search": {}
  },
  "de-DE": {
    "search": {
      "placeholder": "Suche"
    }
  },
  "de-AT": {
    "search": {
      "placeholder": "Suche"
    }
  },
  "de-CH": {
    "search": {
      "placeholder": "Suche"
    }
  },
  "en-US": {
    "search": {
      "placeholder": "Search"
    }
  },
  "en-GB": {
    "search": {
      "placeholder": "Search"
    }
  },
  "it-IT": {
    "search": {
      "placeholder": "Ricerca"
    }
  },
  "fr-FR": {
    "search": {
      "placeholder": "Chercher"
    }
  },
  "es-ES": {
    "search": {
      "placeholder": "Buscar"
    }
  },
  "pl-PL": {
    "search": {
      "placeholder": "Szukaj"
    }
  },
  "nl-NL": {
    "search": {
      "placeholder": "Zoeken"
    }
  },
  "bg-BG": {
    "search": {
      "placeholder": "Търсене"
    }
  },
  "bs-BA": {
    "search": {
      "placeholder": "Traži"
    }
  },
  "da-DK": {
    "search": {
      "placeholder": "Søg"
    }
  },
  "et-EE": {
    "search": {
      "placeholder": "Otsing"
    }
  },
  "fi-FI": {
    "search": {
      "placeholder": "Hae"
    }
  },
  "el-GR": {
    "search": {
      "placeholder": "Αναζήτηση"
    }
  },
  "he-IL": {
    "search": {
      "placeholder": "לחפש"
    }
  },
  "ga-IE": {
    "search": {
      "placeholder": "Cuardaigh"
    }
  },
  "ja-JP": {
    "search": {
      "placeholder": "探す"
    }
  },
  "hr-HR": {
    "search": {
      "placeholder": "Traži"
    }
  },
  "lv-LV": {
    "search": {
      "placeholder": "Meklēt"
    }
  },
  "lt-LT": {
    "search": {
      "placeholder": "Paieška"
    }
  },
  "pt-PT": {
    "search": {
      "placeholder": "Procurar"
    }
  },
  "ro-RO": {
    "search": {
      "placeholder": "Căutare"
    }
  },
  "ru-RU": {
    "search": {
      "placeholder": "Поиск"
    }
  },
  "sv-SE": {
    "search": {
      "placeholder": "Sök"
    }
  },
  "sk-SK": {
    "search": {
      "placeholder": "Vyhľadávanie"
    }
  },
  "cs-CZ": {
    "search": {
      "placeholder": "Vyhledávání"
    }
  },
  "tr-TR": {
    "search": {
      "placeholder": "Arama"
    }
  },
  "hu-HU": {
    "search": {
      "placeholder": "Keresés"
    }
  },
  "fr-BE": {
    "search": {
      "placeholder": "Chercher"
    }
  },
  "fr-LU": {
    "search": {
      "placeholder": "Chercher"
    }
  },
  "mt-MT": {
    "search": {
      "placeholder": "Tfittxija"
    }
  },
  "sl-SI": {
    "search": {
      "placeholder": "Iskanje"
    }
  },
  "el-CY": {
    "search": {
      "placeholder": "Αναζήτηση"
    }
  },
  "tr-CY": {
    "search": {
      "placeholder": "Arama"
    }
  },
  "uk-UA": {
    "search": {}
  },
  "zh-CN": {
    "search": {
      "placeholder": "搜索"
    }
  }
}
</i18n>
