export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلق"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع"])}
            }
          }
        }
      },
      "ar-EG": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلق"])}
            },
            "paid": {
              
            }
          }
        }
      },
      "ar-SA": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلق"])}
            },
            "paid": {
              
            }
          }
        }
      },
      "de-DE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            }
          }
        }
      },
      "de-AT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            }
          }
        }
      },
      "de-CH": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            }
          }
        }
      },
      "en-US": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])}
            }
          }
        }
      },
      "en-GB": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])}
            }
          }
        }
      },
      "it-IT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In sospeso"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagato"])}
            }
          }
        }
      },
      "fr-FR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            }
          }
        }
      },
      "es-ES": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagado"])}
            }
          }
        }
      },
      "pl-PL": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W oczekiwaniu"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["płatny"])}
            }
          }
        }
      },
      "nl-NL": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanhangig"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betaald"])}
            }
          }
        }
      },
      "bg-BG": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Висящ"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["платен"])}
            }
          }
        }
      },
      "bs-BA": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na čekanju"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaćeni"])}
            }
          }
        }
      },
      "da-DK": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verserende"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalt"])}
            }
          }
        }
      },
      "et-EE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ootel"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["makstud"])}
            }
          }
        }
      },
      "fi-FI": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odotettaessa"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maksettu"])}
            }
          }
        }
      },
      "el-GR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκκρεμής"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί πληρωμή"])}
            }
          }
        }
      },
      "he-IL": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ממתין ל"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שולם"])}
            }
          }
        }
      },
      "ga-IE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar feitheamh"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["íoctha"])}
            }
          }
        }
      },
      "ja-JP": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未確定"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払った"])}
            }
          }
        }
      },
      "hr-HR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neriješen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaćen"])}
            }
          }
        }
      },
      "lv-LV": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaida"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samaksāts"])}
            }
          }
        }
      },
      "lt-LT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kol"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mokama"])}
            }
          }
        }
      },
      "pt-PT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pago"])}
            }
          }
        }
      },
      "ro-RO": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In asteptarea"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plătit"])}
            }
          }
        }
      },
      "ru-RU": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ожидании"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплаченный"])}
            }
          }
        }
      },
      "sv-SE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I väntan på"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betald"])}
            }
          }
        }
      },
      "sk-SK": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevykonaného"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaplatil"])}
            }
          }
        }
      },
      "cs-CZ": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekající"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaplaceno"])}
            }
          }
        }
      },
      "tr-TR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Askıda olan"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödenmiş"])}
            }
          }
        }
      },
      "hu-HU": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Függőben levő"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fizetett"])}
            }
          }
        }
      },
      "fr-BE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            }
          }
        }
      },
      "fr-LU": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            }
          }
        }
      },
      "mt-MT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendenti"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imħallas"])}
            }
          }
        }
      },
      "sl-SI": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V teku"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plačan"])}
            }
          }
        }
      },
      "el-CY": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκκρεμής"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί πληρωμή"])}
            }
          }
        }
      },
      "tr-CY": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Askıda olan"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödenmiş"])}
            }
          }
        }
      },
      "uk-UA": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В очікуванні"])}
            },
            "paid": {
              
            }
          }
        }
      },
      "zh-CN": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待定"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付"])}
            }
          }
        }
      }
    }
  })
}
