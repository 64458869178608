<script setup>
import { inject } from 'vue';
const composables = inject('composables');
// const { isRTL } = composables.useJurisdictionSwitch()
const { isMobile } = composables.useViewportSize()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>
<script>
import {mapState} from "pinia";
import { useEnvStore, useCompanyStore } from '@dutypay/store-modules'
import {interceptor} from '@dutypay/utilities'
import numeral from "numeral";
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);
import {to} from "await-to-js";
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import get from 'lodash/get';
import StatusTag from './ListReports/StatusTag';
import PaymentTimeForm from "./ReportDetails/PaymentTimeForm";
import AdditionalInformation from "./ReportDetails/AdditionalInformation";
import DocumentComposer from '@dutypay/document-composer';
import PaymentInformation from "./ReportDetails/PaymentInformation";
import MobileDownloads from "./ReportDetails/MobileDownloads";
import PaymentDetails from "./ReportDetails/PaymentDetails";

export default {
  name: "ReportDetails",
  components: {PaymentDetails, MobileDownloads, PaymentInformation, AdditionalInformation, PaymentTimeForm, StatusTag},
  props: {
    reportId: {required: true}
  },
  data() {
    return {
      loading: false,
      loadingDownload: false,
      loadingMobileCreatePDF: false,
      showPaymentTimeForm: false,
      data: {},
      PDFDownloadLink: '',
      PDFFileName: ''
    };
  },
  computed: {
    ...mapState(useEnvStore, ['apiUrls', 'debug']),
    ...mapState(useCompanyStore, ['selectedCompany']),
    get() {
      return get;
    },
    computedData() {
      return (!isEmpty(this.data)) ? {
        id: get(this.data, 'id', undefined),
        company_legal_name: get(this.selectedCompany, 'name.legal', undefined),
        agent_VATIN: get(this.data, 'agent.VATIN', undefined),
        agent_company_TIN: get(this.data, 'agent.company.TIN', undefined),
        document_number: get(this.data, 'document.number', undefined),
        order_date: get(this.data, 'order.date', null),
        sp_country_id: this.displayValues('taxhub.pages.Reports.ReportDetails.contractCountry.displayValues-', 'sp.country.id'),
        sp_country_translation: get(this.data, `sp.country.translations.${this.$i18n.locale}`, undefined),
        sp_country_id_alias: this.$t('taxhub.pages.Reports.ReportDetails.contractCountry.displayValues-'),
        reportingPeriod_startDate: get(this.data, 'reportingPeriod.startDate', null),
        reportingPeriod_endDate: get(this.data, 'reportingPeriod.endDate', null),
        sp_payment_instrument_id: this.displayValues('taxhub.pages.Reports.ReportDetails.payment.instrument.displayValues-', 'sp.payment.instrument.id'),
        sp_payment_instrument_id_alias: this.$t('taxhub.pages.Reports.ReportDetails.payment.instrument.displayValues-'),
        sp_authority_translation: get(this.data, `sp.authority.translations.${this.$i18n.locale}`, undefined),
        sp_report_type_id: this.displayValues('taxhub.pages.Reports.ReportDetails.report.type.displayValues-', 'sp.report.type.id'),
        sp_report_type_id_alias: this.$t('taxhub.pages.Reports.ReportDetails.report.type.displayValues-'),
        payment_instrument: get(this.data, 'payment.instrument', undefined),
        payment_startTime: get(this.data, 'payment.startTime', null),
        payment_net: get(this.data, 'payment.net', undefined),
        payment_agent_account_owner: get(this.data, 'payment.agent.account.owner', undefined),
        payment_agent_bank: get(this.data, 'payment.agent.bank', undefined),
        sp_payment_agent_bank_country_translation: get(this.data, `sp.payment.agent.bank.country.translations.${this.$i18n.locale}`, undefined),
        payment_agent_iban: get(this.data, 'payment.agent.iban', undefined),
        payment_agent_swiftcode: get(this.data, 'payment.agent.swiftcode', undefined),
        payment_recipient_account_owner: get(this.data, 'payment.recipient.account.owner', undefined),
        payment_recipient_bank: get(this.data, 'payment.recipient.bank', undefined),
        sp_payment_recipient_bank_country_translation: get(this.data, `sp.payment.recipient.bank.country.translations.${this.$i18n.locale}`, undefined),
        payment_recipient_iban: get(this.data, 'payment.recipient.iban', undefined),
        payment_recipient_swiftcode: get(this.data, 'payment.recipient.swiftcode', undefined),
        payment_endDueTime: get(this.data, 'payment.endDueTime', null),
        payment_startDueTime: get(this.data, 'payment.startDueTime', null),
        payment_reference: get(this.data, 'payment.reference', undefined),
        payment_purpose: get(this.data, 'payment.purpose', undefined),
        payment_amount: get(this.data, 'payment.amount', undefined),
        standardRate_outputVAT: get(this.data, 'standardRate.outputVAT', undefined),
        standardRate_net: get(this.data, 'standardRate.net', undefined),
        standardRate_rate: get(this.data, 'standardRate.rate', undefined),
        reducedRate_outputVAT: get(this.data, 'reducedRate.outputVAT', undefined),
        reducedRate_net: get(this.data, 'reducedRate.net', undefined),
        reducedRate_rate: get(this.data, 'reducedRate.rate', undefined),
        sRR_net: get(this.data, 'sRR.net', undefined),
        sRR_rate: get(this.data, 'sRR.rate', undefined),
        parkingRate_net: get(this.data, 'parkingRate.net', undefined),
        parkingRate_rate: get(this.data, 'parkingRate.rate', undefined),
        iCAOG_standardRate_net: get(this.data, 'iCAOG.standardRate.net', undefined),
        iCAOG_reducedRate_net: get(this.data, 'iCAOG.reducedRate.net', undefined),
        iCAOG_sRR_net: get(this.data, 'iCAOG.sRR.net', undefined),
        iCAOG_parkingRate_net: get(this.data, 'iCAOG.parkingRate.net', undefined),
        iCAOG_total: get(this.data, 'iCAOG.total', undefined),
        iCAOG_total_net: get(this.data, 'iCAOG.total.net', undefined),
        iCSOG_transfer: get(this.data, 'iCSOG.transfer', undefined),
        iCSOG_net: get(this.data, 'iCSOG.net', undefined),
        iCSOG_total: get(this.data, 'iCSOG.total', undefined),
        reverseCharge_net: get(this.data, 'reverseCharge.net', undefined),
        export_total: get(this.data, 'export.total', undefined),
        net: get(this.data, 'net', undefined),
        totalPaymentDue: get(this.data, 'totalPaymentDue', undefined),
        inputVAT_amount: get(this.data, 'inputVAT.amount', undefined),
        importVAT_amount: get(this.data, 'importVAT.amount', undefined),
        credit_amount: get(this.data, 'credit.amount', undefined),
        interest_amount: get(this.data, 'interest.amount', undefined),
        _isPaid: get(this.data, 'payment._isPaid', false),
        _isBankTransfer: (get(this.data, 'sp.payment.instrument.id', undefined) === "e46f7735-d1ae-4aa5-9220-4ece62672bc6"),
        _isSEPADirectDebit: (get(this.data, 'sp.payment.instrument.id', undefined) === "6e191cab-60d6-41ac-aa39-02c470f0e18e"),
        _hidePaymentInformation: (get(this.data, 'sp.payment.instrument.id', undefined) === "43e794a4-4f46-4cc6-86e6-5d280e723bca"),
        sp_information_additional_translation: get(this.data, `sp.information.additional.translations.${this.$i18n.locale}`, undefined),
        references_order_id: get(this.data, 'references.order.id', undefined),
        agent_number: get(this.data, 'agent.number', undefined)
      } : {}
    },
    hideBlockICAOG() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d");
    },
    hideBlockTaxFreeSales() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d");
    },
    hideBlockTaxableSales() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d");
    },
    hideBlockInterest() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          this.computedData.interest_amount <= 0);
    },
    hideBlockReverseCharge() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d");
    },
    hidePropertyInputVATAmount() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          this.computedData.inputVAT_amount <= 0);
    },
    hidePropertyImportVATAmount() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          this.computedData.importVAT_amount <= 0);
    },
    hidePropertyCreditAmount() { // Desktop and PDF
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          this.computedData.credit_amount <= 0);
    },
    hideBlockReportingDataSummary() { // Mobile
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      return (protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
          protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") ||
          (get(this.data, 'net', 0) <= 0 &&
          get(this.data, 'iCSOG.total', 0) <= 0 &&
          get(this.data, 'iCAOG.total.net', 0) <= 0 &&
          get(this.data, 'export.total', 0) <= 0 &&
          get(this.data, 'inputVAT.amount', 0) <= 0 &&
          get(this.data, 'importVAT.amount', 0) <= 0 &&
          get(this.data, 'interest.amount', 0) <= 0 &&
          get(this.data, 'reverseCharge.net', 0) <= 0 &&
          get(this.data, 'credit.amount', 0) <= 0);
    },
    PDFDocumentContent() {
      return concat([
            {
              type: 'reportHeader',
              data: {
                title: {label: this.computedData.company_legal_name},
                subTitle: {label: this.computedData.sp_country_translation},
                title2: {label: this.$t('report.label')},
                subTitle2: {
                  label: `
                  ${(dayjs.utc(this.computedData.reportingPeriod_startDate).format('YYYY') === dayjs.utc(this.computedData.reportingPeriod_endDate).format('YYYY'))
                      ? dayjs.utc(this.computedData.reportingPeriod_startDate).format('YYYY')
                      : dayjs.utc(this.computedData.reportingPeriod_startDate).format('YYYY') + ' - ' + dayjs.utc(this.computedData.reportingPeriod_endDate).format('YYYY')},
                  ${(dayjs.utc(this.computedData.reportingPeriod_startDate).format('M') === dayjs.utc(this.computedData.reportingPeriod_endDate).format('M'))
                      ? dayjs.utc(this.computedData.reportingPeriod_startDate).format('MMMM')
                      : dayjs.utc(this.computedData.reportingPeriod_startDate).format('MMMM') + ' - ' + dayjs.utc(this.computedData.reportingPeriod_endDate).format('MMMM')}`
                },
              }
            }, {
              type: 'reportFooter',
              data: {
                spId: this.computedData.id
              }
            }],
          (this.computedData.sp_information_additional_translation)
              ? [{
                type: 'paragraph',
                data: {
                  title: {label: this.computedData.sp_information_additional_translation.title},
                  subTitle: {label: this.computedData.sp_information_additional_translation.subTitle},
                  content: {textBlock: this.computedData.sp_information_additional_translation.content}
                }
              }]
              : [],
          [{
            type: 'keyValueList',
            data: {
              title: '',
              CSSClasses: ['inline-block', 'max-width-half', 'vertical-align-top'],
              keyValueListData: [
                {label: this.$t('document.created.startTime.label'), displayValue: dayjs().format('LLL')},
                {
                  label: this.$t('reportingPeriod.startDate.label'),
                  displayValue: dayjs.utc(this.computedData.reportingPeriod_startDate).format('LL')
                },
                {
                  label: this.$t('reportingPeriod.endDate.label'),
                  displayValue: dayjs.utc(this.computedData.reportingPeriod_endDate).format('LL')
                },
                {
                  label: this.$t('order.date.label'),
                  displayValue: dayjs.utc(this.computedData.order_date).local().format('LL')
                },
                {label: this.$t('report.type.label'), displayValue: this.computedData.sp_report_type_id},
                {label: this.$t('document.number.label'), displayValue: this.computedData.document_number},
                {label: this.$t('references.order.id.label'), displayValue: this.computedData.references_order_id},
                {label: this.$t('agent.number.label'), displayValue: this.computedData.agent_number},
                {label: this.$t('agent.VATIN.label'), displayValue: this.computedData.agent_VATIN},
                {label: this.$t('agent.company.TIN.label'), displayValue: this.computedData.agent_company_TIN},
                {label: this.$t('authority.label'), displayValue: this.computedData.sp_authority_translation}
              ]
            }
          },
            (!this.computedData._hidePaymentInformation)
                ? {
                  type: 'keyValueList',
                  data: {
                    title: this.$t('payment.information.label'),
                    CSSClasses: ['inline-block', 'max-width-half', 'vertical-align-top', 'float-right'],
                    keyValueListData: concat([
                          {
                            label: this.$t('payment.endDueTime.label'),
                            displayValue: dayjs.utc(this.computedData.payment_endDueTime).local().format('LLL')
                          },
                          {
                            label: this.$t('payment.startDueTime.label'),
                            displayValue: dayjs.utc(this.computedData.payment_startDueTime).local().format('LLL')
                          },
                          {
                            label: this.$t('payment.instrument.label'),
                            displayValue: this.computedData.sp_payment_instrument_id
                          }
                        ],
                        (this.computedData._isSEPADirectDebit)
                            ? [
                              {
                                label: this.$t('payment.agent.bank.label'),
                                displayValue: this.computedData.payment_agent_bank
                              },
                              {
                                label: this.$t('payment.agent.bank.country.label'),
                                displayValue: this.computedData.sp_payment_agent_bank_country_translation
                              },
                              {
                                label: this.$t('payment.agent.account.owner.label'),
                                displayValue: this.computedData.payment_agent_account_owner
                              },
                              {
                                label: this.$t('payment.agent.swiftcode.label'),
                                displayValue: this.computedData.payment_agent_swiftcode
                              },
                              {
                                label: this.$t('payment.agent.iban.label'),
                                displayValue: this.computedData.payment_agent_iban
                              },
                            ]
                            : [],
                        (this.computedData._isBankTransfer)
                            ? [
                              {
                                label: this.$t('payment.recipient.bank.label'),
                                displayValue: this.computedData.payment_recipient_bank
                              },
                              {
                                label: this.$t('payment.recipient.bank.country.label'),
                                displayValue: this.computedData.sp_payment_recipient_bank_country_translation
                              },
                              {
                                label: this.$t('payment.recipient.account.owner.label'),
                                displayValue: this.computedData.payment_recipient_account_owner
                              },
                              {
                                label: this.$t('payment.recipient.swiftcode.label'),
                                displayValue: this.computedData.payment_recipient_swiftcode
                              },
                              {
                                label: this.$t('payment.recipient.iban.label'),
                                displayValue: this.computedData.payment_recipient_iban
                              },
                            ]
                            : [],
                        [
                          {
                            label: this.$t('payment.reference.label'),
                            displayValue: this.computedData.payment_reference
                          },
                          {
                            label: this.$t('totalPaymentDue.label'),
                            displayValue: this.formatCurrency(this.computedData.totalPaymentDue)
                          },
                        ],
                        (this.debug)
                            ? [
                              {label: this.$t('payment.purpose.label'), displayValue: this.computedData.payment_purpose},
                              {
                                label: this.$t('payment.amount.label'),
                                displayValue: this.formatCurrency(this.computedData.payment_amount)
                              },
                            ]
                            : [])
                  }
                }
                : {},
            {
              type: 'clear',
              data: {
                clear: 'both'
              }
            },
            {
              type: 'detailTable',
              data: {
                title: this.$t('taxableSales.label'),
                CSSClasses: [],
                tableData: [
                  [ // standard rate
                    `${this.$t('standardRate.label')} (${this.formatPercentage(this.computedData.standardRate_rate)})`,
                    this.formatCurrency(this.computedData.standardRate_net),
                    this.formatCurrency(this.computedData.standardRate_outputVAT),
                  ],
                  [ // reduced rate
                    `${this.$t('reducedRate.label')} (${this.formatPercentage(this.computedData.reducedRate_rate)})`,
                    this.formatCurrency(this.computedData.reducedRate_net),
                    this.formatCurrency(this.computedData.reducedRate_outputVAT),
                  ],
                  [ // sRR
                    `${this.$t('sRR.label')} (${this.formatPercentage(this.computedData.sRR_rate)})`,
                    this.formatCurrency(this.computedData.sRR_net),
                    this.formatCurrency(this.computedData.sRR_outputVAT),
                  ],
                  [ // parking rate
                    `${this.$t('parkingRate.label')}  (${this.formatPercentage(this.computedData.parkingRate_rate)})`,
                    this.formatCurrency(this.computedData.parkingRate_net),
                    this.formatCurrency(this.computedData.parkingRate_outputVAT),
                  ],
                ],
                columns: [
                  {label: this.$t('VATRate.label'), alignment: 'left'},
                  {label: this.$t('net.label'), alignment: 'right'},
                  {label: this.$t('outputVAT.label'), alignment: 'right'},
                ]
              }
            },
            {
              type: 'detailTable',
              data: {
                title: this.$t('iCAOG.label'),
                CSSClasses: [],
                tableData: [
                  [ // standard rate
                    `${this.$t('standardRate.label')} (${this.formatPercentage(this.computedData.standardRate_rate)})`,
                    this.formatCurrency(this.computedData.iCAOG_standardRate_net),
                  ], // reduced rate
                  [
                    `${this.$t('reducedRate.label')}  (${this.formatPercentage(this.computedData.reducedRate_rate)})`,
                    this.formatCurrency(this.computedData.iCAOG_reducedRate_net),
                  ],
                  [ // sRR
                    `${this.$t('sRR.label')} (${this.formatPercentage(this.computedData.sRR_rate)})`,
                    this.formatCurrency(this.computedData.iCAOG_sRR_net),
                  ],
                  [ // parking rate
                    `${this.$t('parkingRate.label')}  (${this.formatPercentage(this.computedData.parkingRate_rate)})`,
                    this.formatCurrency(this.computedData.iCAOG_parkingRate_net),
                  ],
                ],
                columns: [
                  {label: this.$t('VATRate.label'), alignment: 'left'},
                  {label: this.$t('net.label'), alignment: 'right'},
                ]
              }
            },
            {
              type: 'keyValueList',
              data: {
                title: this.$t('taxFreeSales.label'),
                CSSClasses: [],
                keyValueListData: [
                  {
                    label: this.$t('iCSOG.transfer.label'),
                    displayValue: this.formatCurrency(this.computedData.iCSOG_transfer)
                  },
                  {
                    label: this.$t('iCSOG.net.label'),
                    displayValue: this.formatCurrency(this.computedData.iCSOG_net)
                  },
                  {
                    label: this.$t('reverseCharge.net.label'),
                    displayValue: this.formatCurrency(this.computedData.reverseCharge_net)
                  },
                  {
                    label: this.$t('export.label'),
                    displayValue: this.formatCurrency(this.computedData.export_total)
                  },
                ]
              }
            },
          ]);
    },
    currency() {
      // eslint-disable-next-line no-unused-vars
      let alias = this.$t("taxhub.pages.Reports.ReportDetails.currency.displayValues-");
      if (get(this.data, 'sp.currency.id', undefined) === undefined)
        return '';
      let currencyId = get(this.data, 'sp.currency.id');
      let currencyTka = "taxhub.pages.Reports.ReportDetails.currency.displayValues-" + currencyId;
      if (!this.debug && this.$t(currencyTka) === currencyTka)
        return currencyId;
      return this.$t(currencyTka);
    },
    isEmpty() {
      return isEmpty;
    },
    classMobileButton() {
      return ['mobile-button', 'full-width', 'overflow-hidden'];
    }
  },
  methods: {
    downloadPDF() {
      if (this.loadingDownload === true)
        return;
      this.loadingDownload = true;
      let self = this;
      let document = new DocumentComposer();
      document.createNewDocument(this.PDFDocumentContent, {
        documentTitle: `${this.computedData.document_number}`,
        fileName: `${this.computedData.document_number}`
      });
      document.requestPDFDocument().then(function (response) {
        let file = new Blob([response.data], {type: 'application/pdf'});
        document.downloadFile(file, document.getFileName);
        self.loadingDownload = false;
      }).catch(function (error) {
        self.loadingDownload = false;
        self.$dp.log(error);
        self.$notify.error({
          title: self.$t('notification.asyncError.title'),
          message: self.$t('notification.asyncError.message')
        });
      });
    },
    formatPercentage(value) {
      return numeral(value).format("0[.]0%");
    },
    formatCurrency(value) {
      return numeral(value).format("0,0.00") + " " + this.currency;
    },
    displayValues(path, key) {
      if (get(this.data, key, undefined) === undefined)
        return undefined;
      if (!this.debug && this.$t(path + get(this.data, key)) === path + get(this.data, key))
        return get(this.data, key);
      return this.$t(path + get(this.data, key));
    },
    async getReport() {
      this.loading = true;
      let [err, res] = await to(interceptor.get(`${this.apiUrls.taxhub}/api/v0/reports/${this.reportId}`));
      this.loading = false;
      if (err) throw err;
      this.data = res.data;
      // this.$dp.log(this.data);
    },
    updateReport(newReport) {
      this.data = newReport;
      this.$emit("update", newReport);
      this.showPaymentTimeForm = false;
    }
  },
  async created() {
    await this.getReport();
  }
}
</script>

<template>
  <div>
    <h2><router-link to="/reports">{{ t('overview.label') }}</router-link></h2>
    <h3>{{ t('details.label') }}</h3>

      <div v-if="!isMobile">
        <div v-if="loading">
          <div class="dp-flex wrap" style="height: 15.7779rem">
            <div class="dp-skeleton-load flex-basis-1 flex-grow-1 partition" style="width: 100%; height: 100%;"></div>
            <div class="dp-flex flex-basis-1 flex-grow-1" style="width: 100%; height: 75.3671rem">
              <div class="dp-skeleton-load flex-basis-1 flex-grow-1 partition" style="height: 100%;"></div>
              <div class="dp-skeleton-load flex-basis-1 flex-grow-1 partition" style="height: 100%;"></div>
            </div>
          </div>
        </div>
        <template v-else-if="isEmpty(data)">
          <DpNoDataIndicator/>
        </template>
        <div v-else class="desktop-content-container">
          <div v-if="debug && !isEmpty(computedData.sp_information_additional_translation)" class="dp-flex">
            <div class="flex-basis-1 flex-grow-1 partition depth-1 outlined container__information-additional">
              <AdditionalInformation :data="computedData.sp_information_additional_translation"/>
            </div>
          </div>
          <div class="dp-flex justify-content__space-between">
            <div class="flex-basis-1 flex-grow-1 partition depth-1">
              <h3>
                <DpFlag :flagISOCode="$dp.mapSpCountryToFlag(get(data, 'sp.country.id'))"/>
                {{ computedData.sp_country_id }}
              </h3>
              <DpReadValue inline padding-right :data-key="t('reportingPeriod.startDate.label')"
                           :data-value="dayjs.utc(computedData.reportingPeriod_startDate).format('LL')"/>
              <DpReadValue inline padding-right :data-key="t('reportingPeriod.endDate.label')"
                           :data-value="dayjs.utc(computedData.reportingPeriod_endDate).format('LL')"/>
              <DpReadValue inline padding-right :data-key="t('order.date.label')"
                           :data-value="dayjs.utc(computedData.order_date).local().format('LL')"/>
              <DpReadValue inline padding-right :data-key="t('agent.VATIN.label')"
                           :data-value="computedData.agent_VATIN"/>
              <DpReadValue inline padding-right :data-key="t('agent.company.TIN.label')"
                           :data-value="computedData.agent_company_TIN"/>
              <DpReadValue inline padding-right :data-key="t('report.type.label')"
                           :data-value="computedData.sp_report_type_id"/>
              <DpReadValue inline padding-right :data-key="t('document.number.label')"
                           :data-value="computedData.document_number"/>
              <DpReadValue inline padding-right :data-key="t('authority.label')"
                           :data-value="computedData.sp_authority_translation"/>
            </div>
            <div class="flex-basis-0 partition depth-1" v-show="debug">
              <h3>{{ t("downloads.label") }}</h3>
              <dp-button @click="downloadPDF" class="overflow-hidden" v-loading="loadingDownload">
                {{ t('downloads.downloadPDF.buttonLabel') }}
              </dp-button>
            </div>
          </div>
          <div class="dp-flex wrap align-items__flex-start justify-content__space-between">
            <div v-if="!computedData._hidePaymentInformation" class="dp-flex column flex-basis-1 flex-grow-1"
                 :style="{'order': (computedData._isPaid ? 99 : 0)}">
              <div class="flex-basis-1 partition depth-1">
                <h3>{{ t("payment.details.label") }}</h3>
                <h4>
                  <StatusTag :status="computedData._isPaid"></StatusTag>
                </h4>
                <dp-button v-if="!showPaymentTimeForm && computedData._isPaid" @click="showPaymentTimeForm = true"><i class="el-icon-edit"/>&nbsp;{{
                    t("payment.status.edit.buttonLabel") }}</dp-button>
                <DpExpand>
                  <div v-show="!computedData._isPaid || showPaymentTimeForm"
                       class="inline-block partition depth-2">
                  <PaymentTimeForm :payment_start-time="computedData.payment_startTime"
                                   :id="computedData.id"
                                   v-on:update="updateReport($event)"
                                   :cancelable="computedData._isPaid"
                                   v-on:cancel="showPaymentTimeForm = false"
                                   v-on:delete="data.payment.startTime = null"/>
                  </div>
                </DpExpand>
              </div>

              <div class="flex-basis-0 partition depth-1">
                <h3>{{ t("payment.information.label") }}</h3>
                <PaymentInformation :data="data"/>
              </div>
            </div>

            <div class="dp-flex column flex-basis-auto flex-shrink-0 flex-grow-1">
              <div v-if="!hideBlockTaxableSales" class="flex-basis-0 partition depth-1">
                <h3>{{ t("taxableSales.label") }}</h3>
                <DpTable
                    style="margin-bottom: .66em;"
                    border="no-outline"
                    size="large"
                    :data="[
                {
                  VATRate: `${t('standardRate.label')} (${formatPercentage(computedData.standardRate_rate)})`,
                  net: formatCurrency(computedData.standardRate_net),
                  outputVAT: formatCurrency(computedData.standardRate_outputVAT),
                }, // standard rate
                {
                  VATRate: `${t('reducedRate.label')} (${formatPercentage(computedData.reducedRate_rate)})`,
                  net: formatCurrency(computedData.reducedRate_net),
                  outputVAT: formatCurrency(computedData.reducedRate_outputVAT),
                }, // reduced rate
                {
                  VATRate: `${t('sRR.label')} (${formatPercentage(computedData.sRR_rate)})`,
                  net: formatCurrency(computedData.sRR_net),
                  outputVAT: formatCurrency(computedData.sRR_outputVAT),
                }, // sRR
                {
                  VATRate: `${t('parkingRate.label')}  (${formatPercentage(computedData.parkingRate_rate)})`,
                  net: formatCurrency(computedData.parkingRate_net),
                  outputVAT: formatCurrency(computedData.parkingRate_outputVAT),
                }, // parking rate
              ]"
                    :columns="[
                {
                  prop: 'VATRate',
                  label: t('VATRate.label'),
                  align: 'left',
                  head: true
                },
                { prop: 'net', label: t('net.label'), align: 'right' },
                {
                  prop: 'outputVAT',
                  label: t('outputVAT.label'),
                  align: 'right'
                },
              ]"
                ></DpTable>
              </div>
              <div v-if="!hideBlockICAOG" class="flex-basis-1 partition depth-1">
                <h3>{{ t("iCAOG.label") }}</h3>
                <DpTable
                    style="margin-bottom: .66em;"
                    border="no-outline"
                    size="large"
                    :data="[
                {
                  VATRate: `${t(
                    'standardRate.label'
                  )} (${formatPercentage(computedData.standardRate_rate)})`,
                  net: formatCurrency(computedData.iCAOG_standardRate_net),
                }, // standard rate
                {
                  VATRate: `${t(
                    'reducedRate.label'
                  )}  (${formatPercentage(computedData.reducedRate_rate)})`,
                  net: formatCurrency(computedData.iCAOG_reducedRate_net),
                }, // reduced rate
                {
                  VATRate: `${t('sRR.label')} (${formatPercentage(computedData.sRR_rate)})`,
                  net: formatCurrency(computedData.iCAOG_sRR_net),
                }, // sRR
                {
                  VATRate: `${t(
                    'parkingRate.label'
                  )}  (${formatPercentage(computedData.parkingRate_rate)})`,
                  net: formatCurrency(computedData.iCAOG_parkingRate_net),
                }, // parking rate
              ]"
                    :columns="[
                {
                  prop: 'VATRate',
                  label: t('VATRate.label'),
                  align: 'left',
                  head: true
                },
                { prop: 'net', label: t('net.label'), align: 'right' },
              ]"
                ></DpTable>
              </div>
              <div v-if="!hideBlockTaxFreeSales" class="flex-basis-1 partition depth-1">
                <h3>{{ t("taxFreeSales.label") }}</h3>
                <DpReadValue :data-key="t('iCSOG.transfer.label')"
                             :data-value="formatCurrency(computedData.iCSOG_transfer)"/>
                <DpReadValue :data-key="t('iCSOG.net.label')" :data-value="formatCurrency(computedData.iCSOG_net)"/>
                <DpReadValue :data-key="t('export.label')" :data-value="formatCurrency(computedData.export_total)"/>
              </div>
              <div class="flex-basis-1 partition depth-1" v-if="!hideBlockInterest">
                <h3>{{ t('interest.label') }}</h3>
                <DpReadValue :data-key="t('interest.amount.label')"
                             :data-value="formatCurrency(computedData.interest_amount)"/>
              </div>
              <div class="flex-basis-1 partition depth-1" v-if="hideBlockReverseCharge">
                <h3>{{ t('reverseCharge.label') }}</h3>
                <DpReadValue :data-key="t('reverseCharge.net.label')"
                             :data-value="formatCurrency(computedData.reverseCharge_net)"/>
              </div>
              <div class="flex-basis-1 partition depth-1"
                   v-if="!hidePropertyInputVATAmount && !hidePropertyImportVATAmount">
                <h3>{{ t('deductibleTax.label') }}</h3>
                <DpReadValue :data-key="t('inputVAT.amount.label')"
                             :data-value="formatCurrency(computedData.inputVAT_amount)"
                             v-if="!hidePropertyInputVATAmount"/>
                <DpReadValue :data-key="t('importVAT.amount.label')"
                             :data-value="formatCurrency(computedData.importVAT_amount)"
                             v-if="!hidePropertyImportVATAmount"/>
              </div>
              <div class="flex-basis-1 partition depth-1" v-if="!hidePropertyCreditAmount">
                <h3>{{ t('credit.label') }}</h3>
                <DpReadValue :data-key="t('credit.amount.label')"
                             :data-value="formatCurrency(computedData.credit_amount)"
                             v-if="!hidePropertyCreditAmount"/>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div v-if="isMobile">
        <div v-if="loading">
          <h3>
            <div class="dp-skeleton-load dp-skeleton-load__h3"></div>
          </h3>
          <div class="dp-skeleton-load__dp-read-value" v-for="i in 10" :key="`skeleton-load-1-${i}`">
            <div class="dp-skeleton-load"></div>
            <div class="dp-skeleton-load"></div>
          </div>
          <h3>
            <div class="dp-skeleton-load dp-skeleton-load__h3"></div>
          </h3>
          <div class="dp-skeleton-load__dp-read-value" v-for="i in 10" :key="`skeleton-load-2-${i}`">
            <div class="dp-skeleton-load"></div>
            <div class="dp-skeleton-load"></div>
          </div>
          <h3>
            <div class="dp-skeleton-load dp-skeleton-load__h3"></div>
          </h3>
          <div class="dp-skeleton-load__dp-read-value" v-for="i in 10" :key="`skeleton-load-3-${i}`">
            <div class="dp-skeleton-load"></div>
            <div class="dp-skeleton-load"></div>
          </div>
          <h3 v-show="debug">
            <div class="dp-skeleton-load dp-skeleton-load__h3"></div>
          </h3>
          <div v-show="debug" class="dp-skeleton-load__dp-read-value" v-for="i in 2" :key="`skeleton-load-4-${i}`">
            <div class="dp-skeleton-load"></div>
            <div class="dp-skeleton-load"></div>
          </div>
        </div>
        <template v-else-if="isEmpty(data)">
          <DpNoDataIndicator/>
        </template>
        <div v-else>
          <div v-if="debug && !isEmpty(computedData.sp_information_additional_translation)"
               class="partition depth-1 outlined container__information-additional">
            <AdditionalInformation :data="computedData.sp_information_additional_translation"/>
          </div>
          <h3 style="margin:1em;">
            <DpFlag :flagISOCode="$dp.mapSpCountryToFlag(get(data, 'sp.country.id'))"/>
            {{ computedData.sp_country_id }}
          </h3>
          <DpReadValue :data-key="t('reportingPeriod.label')"
                       v-if="!!(computedData.reportingPeriod_startDate) && !!(computedData.reportingPeriod_endDate)">
            <span style="white-space: nowrap;">{{
                dayjs.utc(computedData.reportingPeriod_startDate).format('LL')
              }}</span>&nbsp;-&nbsp;
            <span style="white-space: nowrap">{{
                dayjs.utc(computedData.reportingPeriod_endDate).format('LL')
              }}</span>
          </DpReadValue>
          <DpReadValue :data-key="t('order.date.label')"
                       :data-value="dayjs.utc(computedData.order_date).local().format('LL')"/>
          <DpReadValue :data-key="t('report.type.label')" :data-value="computedData.sp_report_type_id"/>

          <div class="dp-flex column">
            <div v-if="!computedData._hidePaymentInformation" class="flex-basis-1"
                 :style="{'order': (computedData._isPaid ? 99 : 0)}">
              <DpMobileCollapse :headline="t('payment.details.label')">
                <DpReadValue :data-key="t('payment.status.label')" style="color: #E6A23C;">
                  <el-tag v-if="computedData._isPaid"
                          style="border-width: 2px; border-radius: 6px;"
                          type="success"
                          size="small" effect="plain"><i
                      class="el-icon-success status-tag"
                      style="margin-inline-end: .2em;"/>{{ t('payment.status.paid.label') }}
                  </el-tag>
                  <el-tag v-else style="border-width: 2px; border-radius: 6px;"
                          type="warning"
                          size="small" effect="plain"><i
                      class="el-icon-warning status-tag"
                      style="margin-inline-end: .2em;"/>{{ t('payment.status.unpaid.label') }}
                  </el-tag>
                </DpReadValue>
                <DpExpand v-if="!computedData._isPaid">
                  <dp-button v-show="!showPaymentTimeForm"
                      :class="['dp-mobile-button', 'full-width']"
                      size="large"
                      @click="showPaymentTimeForm = true">{{ t("payment.status.set.buttonLabel") }}
                  </dp-button>
                </DpExpand>
                <dp-button v-else type="text" @click="showPaymentTimeForm = true">
                  {{ t('payment.status.edit.buttonLabel') }}
                </dp-button>
                <DpExpand>
                  <PaymentTimeForm v-show="showPaymentTimeForm"
                      class="partition depth-1"
                      :id="computedData.id"
                      :payment_start-time="computedData.payment_startTime"
                      v-on:cancel="showPaymentTimeForm = false"
                      v-on:update="updateReport($event)"
                      v-on:delete="data.payment.startTime = null"/>
                </DpExpand>
                <PaymentDetails :data="data" :mobile="true"/>

              </DpMobileCollapse>
              <DpMobileCollapse :headline="t('payment.information.label')">
                <PaymentInformation :data="data" :mobile="true"/>
              </DpMobileCollapse>
            </div>
            <div v-if="!hideBlockReportingDataSummary" class="flex-basis-1">
              <DpMobileCollapse :headline="t('reporting.data.summary.label')">
                <DpReadValue v-if="get(data, 'net', 0) > 0" :data-key="t('net.label')"
                             :data-value="formatCurrency(computedData.net)"/>
                <DpReadValue v-if="get(data, 'iCSOG.total', 0) > 0" :data-key="t('iCSOG.total.label')"
                             :data-value="formatCurrency(computedData.iCSOG_total)"/>
                <DpReadValue v-if="get(data, 'iCAOG.total.net', 0) > 0" :data-key="t('iCAOG.total.net.label')"
                             :data-value="formatCurrency(computedData.iCAOG_total_net)"/>
                <DpReadValue v-if="get(data, 'export.total', 0) > 0" :data-key="t('export.total.label')"
                             :data-value="formatCurrency(computedData.export_total)"/>
                <DpReadValue v-if="get(data, 'inputVAT.amount', 0) > 0" :data-key="t('inputVAT.amount.label')"
                             :data-value="formatCurrency(computedData.inputVAT_amount)"/>
                <DpReadValue v-if="get(data, 'importVAT.amount', 0) > 0" :data-key="t('importVAT.amount.label')"
                             :data-value="formatCurrency(computedData.importVAT_amount)"/>
                <DpReadValue v-if="get(data, 'interest.amount', 0) > 0" :data-key="t('interest.amount.label')"
                             :data-value="formatCurrency(computedData.interest_amount)"/>
                <DpReadValue v-if="get(data, 'reverseCharge.net', 0) > 0" :data-key="t('reverseCharge.net.label')"
                             :data-value="formatCurrency(computedData.reverseCharge_net)"/>
                <DpReadValue v-if="get(data, 'credit.amount', 0) > 0" :data-key="t('credit.amount.label')"
                             :data-value="formatCurrency(computedData.credit_amount)"/>
              </DpMobileCollapse>
            </div>
            <div class="flex-basis-1" v-show="debug">
              <DpMobileCollapse :headline="t('downloads.label')">
                <MobileDownloads :p-d-f-document-content="PDFDocumentContent" :computed-data="computedData"/>
              </DpMobileCollapse>
            </div>
          </div>
        </div>

      </div>

  </div>
</template>

<style scoped lang="scss">
.desktop-content-container {
  margin: -.66rem;
}

.dp-flex {
  .partition {
    margin: .66rem;

    h3, h4 {
      padding: 0 0 .3em 0;
    }
  }
}

.dp-skeleton-load__dp-read-value {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  div:nth-child(1) {
    width: 75%;
    height: 0.75rem;
    margin-bottom: 0.45rem;
  }

  div:nth-child(2) {
    width: 85%;
    height: 1.2rem;
  }
}

.dp-skeleton-load__h3 {
  width: 95%;
  height: 2.28571rem;
}
</style>

<i18n>
{
  "ar-AE": {
    "payment": {
      "agent": {
        "bank": {
          "label": "معهد",
          "country": {
            "label": "بلد مقر المعهد"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "معهد المدفوع لأمره",
          "country": {
            "label": "بلد مقر المعهد"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "إستعمال"
      },
      "status": {
        "paid": {
          "label": "دفع"
        },
        "label": "الحالة",
        "unpaid": {
          "label": "معلق"
        },
        "edit": {
          "buttonLabel": "تحرير تفاصيل الدفع"
        },
        "set": {
          "buttonLabel": "تحرير تفاصيل الدفع"
        }
      },
      "amount": {
        "label": "المبلغ المدفوع"
      },
      "information": {
        "label": "معلومات الدفع"
      },
      "endDueTime": {
        "label": "نهاية فترة السداد"
      },
      "instrument": {
        "label": "وسيلة الدفع"
      },
      "reference": {
        "label": "إشارة دفع"
      },
      "startDueTime": {
        "label": "بداية فترة السداد"
      },
      "details": {
        "label": "بيانات الدفع"
      }
    },
    "VATRate": {
      "label": "قيمة الضريبة"
    },
    "overview": {
      "label": "نظرة عامة"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "تم إنشاء ملف PDF في"
        }
      },
      "number": {
        "label": "رقم المستند"
      }
    },
    "agent": {
      "number": {
        "label": "رقم العميل"
      },
      "company": {
        "TIN": {
          "label": "الرقم الضريبي"
        }
      },
      "VATIN": {
        "label": "رقم تعريف الضريبة على القيمة المضافة"
      }
    },
    "notification": {
      "asyncError": {
        "message": "حدث خطأ في خدمتنا. حاول مرة اخرى.",
        "title": "خطأ"
      }
    },
    "downloads": {
      "label": "التحميلات",
      "downloadPDF": {
        "buttonLabel": "تحميل PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "ملخص بيانات التقارير"
        }
      }
    },
    "details": {
      "label": "تفاصيل"
    },
    "reportingPeriod": {
      "label": "الفترة المشمولة بالتقرير",
      "startDate": {
        "label": "تاريخ بدء الإبلاغ"
      },
      "endDate": {
        "label": "تاريخ انتهاء التقرير"
      }
    },
    "credit": {
      "label": "ائتمان",
      "amount": {
        "label": "ائتمان"
      }
    },
    "iCSOG": {
      "total": {
        "label": "الإمدادات داخل المجتمع ، مجموع"
      },
      "transfer": {
        "label": "النقل داخل المجتمع"
      },
      "net": {
        "label": "الولادات المعفاة من الضرائب"
      }
    },
    "parkingRate": {
      "label": "معدل الضريبة"
    },
    "sRR": {
      "label": "معدل الضريبة"
    },
    "taxFreeSales": {
      "label": "مبيعات معفاة من الضرائب"
    },
    "standardRate": {
      "label": "معدل الضريبة"
    },
    "authority": {
      "label": "السلطة"
    },
    "references": {
      "order": {
        "id": {
          "label": "رقم التعريف الخاص بالطلب"
        }
      }
    },
    "outputVAT": {
      "label": "ضريبة القيمة المضافة"
    },
    "export": {
      "total": {
        "label": "تصدير"
      },
      "label": "تصدير"
    },
    "totalPaymentDue": {
      "label": "مبلغ الدفع"
    },
    "interest": {
      "amount": {
        "label": "مبلغ الفائدة"
      },
      "label": "فائدة"
    },
    "order": {
      "date": {
        "label": "تاريخ الطلب"
      }
    },
    "net": {
      "label": "صافي المبيعات"
    },
    "report": {
      "type": {
        "label": "نوع التقرير"
      },
      "label": "نقل"
    },
    "reverseCharge": {
      "net": {
        "label": "صافي الإيرادات للرسوم العكسية (Reverse charge)"
      },
      "label": "(Reverse charge) عكس الالتزام الضريبي"
    },
    "reducedRate": {
      "label": "معدل الضريبة"
    },
    "taxableSales": {
      "label": "المبيعات الخاضعة للضريبة"
    },
    "iCAOG": {
      "label": "عمليات الاستحواذ داخل المجتمع",
      "total": {
        "net": {
          "label": "مجموع عمليات الاستحواذ داخل المجتمع"
        }
      }
    },
    "deductibleTax": {
      "label": "ضريبة المدخلات المقتطعة"
    },
    "inputVAT": {
      "amount": {
        "label": "ضريبة المدخلات من الفواتير"
      }
    },
    "importVAT": {
      "amount": {
        "label": "استيراد ضريبة القيمة المضافة"
      }
    }
  },
  "ar-EG": {
    "payment": {
      "agent": {
        "bank": {
          "country": {}
        },
        "iban": {},
        "swiftcode": {},
        "account": {
          "owner": {}
        }
      },
      "recipient": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "iban": {},
        "swiftcode": {}
      },
      "purpose": {},
      "status": {
        "paid": {},
        "unpaid": {
          "label": "معلق"
        },
        "edit": {},
        "set": {}
      },
      "amount": {},
      "information": {},
      "endDueTime": {},
      "instrument": {},
      "reference": {},
      "startDueTime": {},
      "details": {}
    },
    "VATRate": {},
    "overview": {},
    "document": {
      "created": {
        "startTime": {}
      },
      "number": {}
    },
    "agent": {
      "number": {},
      "company": {
        "TIN": {}
      },
      "VATIN": {}
    },
    "notification": {
      "asyncError": {}
    },
    "downloads": {
      "downloadPDF": {}
    },
    "reporting": {
      "data": {
        "summary": {}
      }
    },
    "details": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "credit": {
      "amount": {}
    },
    "iCSOG": {
      "total": {},
      "transfer": {},
      "net": {}
    },
    "parkingRate": {},
    "sRR": {},
    "taxFreeSales": {},
    "standardRate": {},
    "authority": {},
    "references": {
      "order": {
        "id": {}
      }
    },
    "outputVAT": {
      "label": "ضريبة القيمة المضافة"
    },
    "export": {
      "total": {}
    },
    "totalPaymentDue": {},
    "interest": {
      "amount": {}
    },
    "order": {
      "date": {}
    },
    "net": {},
    "report": {
      "type": {}
    },
    "reverseCharge": {
      "net": {}
    },
    "reducedRate": {},
    "taxableSales": {},
    "iCAOG": {
      "total": {
        "net": {}
      }
    },
    "deductibleTax": {},
    "inputVAT": {
      "amount": {}
    },
    "importVAT": {
      "amount": {}
    }
  },
  "ar-SA": {
    "payment": {
      "agent": {
        "bank": {
          "country": {}
        },
        "iban": {},
        "swiftcode": {},
        "account": {
          "owner": {}
        }
      },
      "recipient": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "iban": {},
        "swiftcode": {}
      },
      "purpose": {},
      "status": {
        "paid": {},
        "unpaid": {
          "label": "معلق"
        },
        "edit": {},
        "set": {}
      },
      "amount": {},
      "information": {},
      "endDueTime": {},
      "instrument": {},
      "reference": {},
      "startDueTime": {},
      "details": {}
    },
    "VATRate": {},
    "overview": {},
    "document": {
      "created": {
        "startTime": {}
      },
      "number": {}
    },
    "agent": {
      "number": {},
      "company": {
        "TIN": {}
      },
      "VATIN": {}
    },
    "notification": {
      "asyncError": {}
    },
    "downloads": {
      "downloadPDF": {}
    },
    "reporting": {
      "data": {
        "summary": {}
      }
    },
    "details": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "credit": {
      "amount": {}
    },
    "iCSOG": {
      "total": {},
      "transfer": {},
      "net": {}
    },
    "parkingRate": {},
    "sRR": {},
    "taxFreeSales": {},
    "standardRate": {},
    "authority": {},
    "references": {
      "order": {
        "id": {}
      }
    },
    "outputVAT": {
      "label": "ضريبة القيمة المضافة"
    },
    "export": {
      "total": {}
    },
    "totalPaymentDue": {},
    "interest": {
      "amount": {}
    },
    "order": {
      "date": {}
    },
    "net": {},
    "report": {
      "type": {}
    },
    "reverseCharge": {
      "net": {}
    },
    "reducedRate": {},
    "taxableSales": {},
    "iCAOG": {
      "total": {
        "net": {}
      }
    },
    "deductibleTax": {},
    "inputVAT": {
      "amount": {}
    },
    "importVAT": {
      "amount": {}
    }
  },
  "de-DE": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut des Zahlungsempfängers",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Verwendungszweck"
      },
      "status": {
        "paid": {
          "label": "bezahlt"
        },
        "label": "Status",
        "unpaid": {
          "label": "Offen"
        },
        "edit": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        },
        "set": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        }
      },
      "amount": {
        "label": "Bezahlter Betrag"
      },
      "information": {
        "label": "Zahlungsinformation"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "reference": {
        "label": "Zahlungsreferenz"
      },
      "startDueTime": {
        "label": "Beginn der Zahlfrist"
      },
      "details": {
        "label": "Zahlungsdetails"
      }
    },
    "VATRate": {
      "label": "Umsatzsteuersatz"
    },
    "overview": {
      "label": "Übersicht"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF erstellt am"
        }
      },
      "number": {
        "label": "Belegnummer"
      }
    },
    "agent": {
      "number": {
        "label": "Kundennummer"
      },
      "company": {
        "TIN": {
          "label": "Steuernummer"
        }
      },
      "VATIN": {
        "label": "Umsatzsteuer-Identifikationsnummer"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut.",
        "title": "Fehler"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "PDF herunterladen"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Zusammenfassung der Meldedaten"
        }
      }
    },
    "details": {
      "label": "Details"
    },
    "reportingPeriod": {
      "label": "Meldezeitraum",
      "startDate": {
        "label": "Meldezeitraumbeginn"
      },
      "endDate": {
        "label": "Meldezeitraumende"
      }
    },
    "credit": {
      "label": "Guthaben",
      "amount": {
        "label": "Guthaben"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Innergemeinschaftliche Lieferungen, gesamt"
      },
      "transfer": {
        "label": "Verbringungen"
      },
      "net": {
        "label": "Steuerfreie Lieferungen"
      }
    },
    "parkingRate": {
      "label": "Steuersatz"
    },
    "sRR": {
      "label": "Steuersatz"
    },
    "taxFreeSales": {
      "label": "Steuerfreie Umsätze"
    },
    "standardRate": {
      "label": "Steuersatz"
    },
    "authority": {
      "label": "Behörde"
    },
    "references": {
      "order": {
        "id": {
          "label": "Auftragsnummer"
        }
      }
    },
    "outputVAT": {
      "label": "Umsatzsteuer"
    },
    "export": {
      "total": {
        "label": "Ausfuhr"
      },
      "label": "Ausfuhr"
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    },
    "interest": {
      "amount": {
        "label": "Zinsbetrag"
      },
      "label": "Zinsen"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "net": {
      "label": "Nettoumsatz"
    },
    "report": {
      "type": {
        "label": "Berichtsart"
      },
      "label": "Bericht"
    },
    "reverseCharge": {
      "net": {
        "label": "Nettoumsatz für Umkehrung der Steuerschuldnerschaft (Reverse charge)"
      },
      "label": "Umkehrung der Steuerschuldnerschaft (Reverse charge)"
    },
    "reducedRate": {
      "label": "Steuersatz"
    },
    "taxableSales": {
      "label": "Steuerpflichtige Umsätze"
    },
    "iCAOG": {
      "label": "Innergemeinschaftliche Erwerbe",
      "total": {
        "net": {
          "label": "Summe der innergemeinschaftliche Erwerbe"
        }
      }
    },
    "deductibleTax": {
      "label": "Abziehbare Vorsteuerbeträge"
    },
    "inputVAT": {
      "amount": {
        "label": "Vorsteuer aus Rechnungen"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Einfuhrumsatzsteuer"
      }
    }
  },
  "de-AT": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut des Zahlungsempfängers",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Verwendungszweck"
      },
      "status": {
        "paid": {
          "label": "bezahlt"
        },
        "label": "Status",
        "unpaid": {
          "label": "Offen"
        },
        "edit": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        },
        "set": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        }
      },
      "amount": {
        "label": "Bezahlter Betrag"
      },
      "information": {
        "label": "Zahlungsinformation"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "reference": {
        "label": "Zahlungsreferenz"
      },
      "startDueTime": {
        "label": "Beginn der Zahlfrist"
      },
      "details": {
        "label": "Zahlungsdetails"
      }
    },
    "VATRate": {
      "label": "Mehrwertsteuersatz"
    },
    "overview": {
      "label": "Übersicht"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF erstellt am"
        }
      },
      "number": {
        "label": "Belegnummer"
      }
    },
    "agent": {
      "number": {
        "label": "Kundennummer"
      },
      "company": {
        "TIN": {
          "label": "Steuernummer"
        }
      },
      "VATIN": {
        "label": "Umsatzsteuer-Identifikationsnummer"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut.",
        "title": "Fehler"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "PDF herunterladen"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Zusammenfassung der Meldedaten"
        }
      }
    },
    "details": {
      "label": "Details"
    },
    "reportingPeriod": {
      "label": "Meldezeitraum",
      "startDate": {
        "label": "Meldezeitraumbeginn"
      },
      "endDate": {
        "label": "Meldezeitraumende"
      }
    },
    "credit": {
      "label": "Guthaben",
      "amount": {
        "label": "Guthaben"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Innergemeinschaftliche Lieferungen, gesamt"
      },
      "transfer": {
        "label": "Verbringungen"
      },
      "net": {
        "label": "Steuerfreie Lieferungen"
      }
    },
    "parkingRate": {
      "label": "Steuersatz"
    },
    "sRR": {
      "label": "Steuersatz"
    },
    "taxFreeSales": {
      "label": "Steuerfreie Umsätze"
    },
    "standardRate": {
      "label": "Steuersatz"
    },
    "authority": {
      "label": "Behörde"
    },
    "references": {
      "order": {
        "id": {
          "label": "Auftragsnummer"
        }
      }
    },
    "outputVAT": {
      "label": "Umsatzsteuer"
    },
    "export": {
      "total": {
        "label": "Ausfuhr"
      },
      "label": "Ausfuhr"
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    },
    "interest": {
      "amount": {
        "label": "Zinsbetrag"
      },
      "label": "Zinsen"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "net": {
      "label": "Nettoumsatz"
    },
    "report": {
      "type": {
        "label": "Berichtsart"
      },
      "label": "Bericht"
    },
    "reverseCharge": {
      "net": {
        "label": "Nettoumsatz für Umkehrung der Steuerschuldnerschaft (Reverse charge)"
      },
      "label": "Umkehrung der Steuerschuldnerschaft (Reverse charge)"
    },
    "reducedRate": {
      "label": "Steuersatz"
    },
    "taxableSales": {
      "label": "Steuerpflichtiger Umsatz"
    },
    "iCAOG": {
      "label": "Innergemeinschaftliche Erwerbe",
      "total": {
        "net": {
          "label": "Summe der innergemeinschaftliche Erwerbe"
        }
      }
    },
    "deductibleTax": {
      "label": "Abziehbare Vorsteuerbeträge"
    },
    "inputVAT": {
      "amount": {
        "label": "Vorsteuer aus Rechnungen"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Einfuhrumsatzsteuer"
      }
    }
  },
  "de-CH": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut des Zahlungsempfängers",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Verwendungszweck"
      },
      "status": {
        "paid": {
          "label": "bezahlt"
        },
        "label": "Status",
        "unpaid": {
          "label": "Offen"
        },
        "edit": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        },
        "set": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        }
      },
      "amount": {
        "label": "Bezahlter Betrag"
      },
      "information": {
        "label": "Zahlungsinformation"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "reference": {
        "label": "Zahlungsreferenz"
      },
      "startDueTime": {
        "label": "Beginn der Zahlfrist"
      },
      "details": {
        "label": "Zahlungsdetails"
      }
    },
    "VATRate": {
      "label": "Mehrwertsteuersatz"
    },
    "overview": {
      "label": "Übersicht"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF erstellt am"
        }
      },
      "number": {
        "label": "Belegnummer"
      }
    },
    "agent": {
      "number": {
        "label": "Kundennummer"
      },
      "company": {
        "TIN": {
          "label": "Steuernummer"
        }
      },
      "VATIN": {
        "label": "Umsatzsteuer-Identifikationsnummer"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut.",
        "title": "Fehler"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "PDF herunterladen"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Zusammenfassung der Meldedaten"
        }
      }
    },
    "details": {
      "label": "Details"
    },
    "reportingPeriod": {
      "label": "Meldezeitraum",
      "startDate": {
        "label": "Meldezeitraumbeginn"
      },
      "endDate": {
        "label": "Meldezeitraumende"
      }
    },
    "credit": {
      "label": "Guthaben",
      "amount": {
        "label": "Guthaben"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Innergemeinschaftliche Lieferungen, gesamt"
      },
      "transfer": {
        "label": "Verbringungen"
      },
      "net": {
        "label": "Steuerfreie Lieferungen"
      }
    },
    "parkingRate": {
      "label": "Steuersatz"
    },
    "sRR": {
      "label": "Steuersatz"
    },
    "taxFreeSales": {
      "label": "Steuerfreie Umsätze"
    },
    "standardRate": {
      "label": "Steuersatz"
    },
    "authority": {
      "label": "Behörde"
    },
    "references": {
      "order": {
        "id": {
          "label": "Auftragsnummer"
        }
      }
    },
    "outputVAT": {
      "label": "Umsatzsteuer"
    },
    "export": {
      "total": {
        "label": "Ausfuhr"
      },
      "label": "Ausfuhr"
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    },
    "interest": {
      "amount": {
        "label": "Zinsbetrag"
      },
      "label": "Zinsen"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "net": {
      "label": "Nettoumsatz"
    },
    "report": {
      "type": {
        "label": "Berichtsart"
      },
      "label": "Bericht"
    },
    "reverseCharge": {
      "net": {
        "label": "Nettoumsatz für Umkehrung der Steuerschuldnerschaft (Reverse charge)"
      },
      "label": "Umkehrung der Steuerschuldnerschaft (Reverse charge)"
    },
    "reducedRate": {
      "label": "Steuersatz"
    },
    "taxableSales": {
      "label": "Steuerpflichtiger Umsatz"
    },
    "iCAOG": {
      "label": "Innergemeinschaftliche Erwerbe",
      "total": {
        "net": {
          "label": "Summe der innergemeinschaftliche Erwerbe"
        }
      }
    },
    "deductibleTax": {
      "label": "Abziehbare Vorsteuerbeträge"
    },
    "inputVAT": {
      "amount": {
        "label": "Vorsteuer aus Rechnungen"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Einfuhrumsatzsteuer"
      }
    }
  },
  "en-US": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institute",
          "country": {
            "label": "Country of Seat of the Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Payee's Institute",
          "country": {
            "label": "Country of Seat of the Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Usage"
      },
      "status": {
        "paid": {
          "label": "paid"
        },
        "label": "Status",
        "unpaid": {
          "label": "Pending"
        },
        "edit": {
          "buttonLabel": "Edit payment details"
        },
        "set": {
          "buttonLabel": "Edit payment details"
        }
      },
      "amount": {
        "label": "Amount paid"
      },
      "information": {
        "label": "Payment information"
      },
      "endDueTime": {
        "label": "End of the payment period"
      },
      "instrument": {
        "label": "Payment instrument"
      },
      "reference": {
        "label": "Payment reference"
      },
      "startDueTime": {
        "label": "Beginning of the payment period"
      },
      "details": {
        "label": "Payment details"
      }
    },
    "VATRate": {
      "label": "VAT rate"
    },
    "overview": {
      "label": "Overview"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF created on"
        }
      },
      "number": {
        "label": "Document number"
      }
    },
    "agent": {
      "number": {
        "label": "Customer number"
      },
      "company": {
        "TIN": {
          "label": "Tax number"
        }
      },
      "VATIN": {
        "label": "VAT identification number"
      }
    },
    "notification": {
      "asyncError": {
        "message": "An error has occurred in our service. Please try again.",
        "title": "Error"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "Download PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Summary of reporting data"
        }
      }
    },
    "details": {
      "label": "Details"
    },
    "reportingPeriod": {
      "label": "Reporting period",
      "startDate": {
        "label": "Reporting start date"
      },
      "endDate": {
        "label": "Reporting end date"
      }
    },
    "credit": {
      "label": "Credit",
      "amount": {
        "label": "Credit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Intra-community supplies, total"
      },
      "transfer": {
        "label": "Intra-community transfers"
      },
      "net": {
        "label": "Tax free deliveries"
      }
    },
    "parkingRate": {
      "label": "Tax rate"
    },
    "sRR": {
      "label": "Tax rate"
    },
    "taxFreeSales": {
      "label": "Tax free sales"
    },
    "standardRate": {
      "label": "Tax rate"
    },
    "authority": {
      "label": "Authority"
    },
    "references": {
      "order": {
        "id": {
          "label": "Order Id"
        }
      }
    },
    "outputVAT": {
      "label": "Value Added Tax"
    },
    "export": {
      "total": {
        "label": "Export"
      },
      "label": "Export"
    },
    "totalPaymentDue": {
      "label": "Payment amount"
    },
    "interest": {
      "amount": {
        "label": "Interest amount"
      },
      "label": "Interest"
    },
    "order": {
      "date": {
        "label": "Order date"
      }
    },
    "net": {
      "label": "Net sales"
    },
    "report": {
      "type": {
        "label": "Report type"
      },
      "label": "Report"
    },
    "reverseCharge": {
      "net": {
        "label": "Net revenues for reverse charge"
      },
      "label": "Reversal of tax liability (Reverse charge)"
    },
    "reducedRate": {
      "label": "Tax rate"
    },
    "taxableSales": {
      "label": "Taxable sales"
    },
    "iCAOG": {
      "label": "Intra-community acquisitions",
      "total": {
        "net": {
          "label": "Sum of intra-community acquisitions"
        }
      }
    },
    "deductibleTax": {
      "label": "Deductible input tax"
    },
    "inputVAT": {
      "amount": {
        "label": "Input tax from invoices"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Import VAT"
      }
    }
  },
  "en-GB": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institute",
          "country": {
            "label": "Country of Seat of the Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Payee's Institute",
          "country": {
            "label": "Country of Seat of the Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Purpose of transfer"
      },
      "status": {
        "paid": {
          "label": "paid"
        },
        "label": "Status",
        "unpaid": {
          "label": "Pending"
        },
        "edit": {
          "buttonLabel": "Edit payment details"
        },
        "set": {
          "buttonLabel": "Edit payment details"
        }
      },
      "amount": {
        "label": "Amount paid"
      },
      "information": {
        "label": "Payment information"
      },
      "endDueTime": {
        "label": "End of the payment period"
      },
      "instrument": {
        "label": "Payment instrument"
      },
      "reference": {
        "label": "Payment reference"
      },
      "startDueTime": {
        "label": "Beginning of the payment period"
      },
      "details": {
        "label": "Payment details"
      }
    },
    "VATRate": {
      "label": "VAT rate"
    },
    "overview": {
      "label": "Overview"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF created on"
        }
      },
      "number": {
        "label": "Document number"
      }
    },
    "agent": {
      "number": {
        "label": "Customer number"
      },
      "company": {
        "TIN": {
          "label": "Tax number"
        }
      },
      "VATIN": {
        "label": "VAT identification number"
      }
    },
    "notification": {
      "asyncError": {
        "message": "An error has occurred in our service. Please try again.",
        "title": "Error"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "Download PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Summary of reporting data"
        }
      }
    },
    "details": {
      "label": "Details"
    },
    "reportingPeriod": {
      "label": "Reporting period",
      "startDate": {
        "label": "Reporting start date"
      },
      "endDate": {
        "label": "Reporting end date"
      }
    },
    "credit": {
      "label": "Credit",
      "amount": {
        "label": "Credit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Intra-community supplies, total"
      },
      "transfer": {
        "label": "Intra-community transfer"
      },
      "net": {
        "label": "Tax free deliveries"
      }
    },
    "parkingRate": {
      "label": "Tax rate"
    },
    "sRR": {
      "label": "Tax rate"
    },
    "taxFreeSales": {
      "label": "Tax-free sales"
    },
    "standardRate": {
      "label": "Tax rate"
    },
    "authority": {
      "label": "Authority"
    },
    "references": {
      "order": {
        "id": {
          "label": "Order Id"
        }
      }
    },
    "outputVAT": {
      "label": "Value Added Tax"
    },
    "export": {
      "total": {
        "label": "Export"
      },
      "label": "Export"
    },
    "totalPaymentDue": {
      "label": "Payment amount"
    },
    "interest": {
      "amount": {
        "label": "Interest amount"
      },
      "label": "Interest"
    },
    "order": {
      "date": {
        "label": "Order date"
      }
    },
    "net": {
      "label": "Net sales"
    },
    "report": {
      "type": {
        "label": "Report type"
      },
      "label": "Report"
    },
    "reverseCharge": {
      "net": {
        "label": "Net revenues for reverse charge"
      },
      "label": "Reversal of tax liability (Reverse charge)"
    },
    "reducedRate": {
      "label": "Tax rate"
    },
    "taxableSales": {
      "label": "Taxable sales"
    },
    "iCAOG": {
      "label": "Intra-community acquisitions",
      "total": {
        "net": {
          "label": "Sum of intra-community acquisitions"
        }
      }
    },
    "deductibleTax": {
      "label": "Deductible input tax"
    },
    "inputVAT": {
      "amount": {
        "label": "Input tax from invoices"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Import VAT"
      }
    }
  },
  "it-IT": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Istituto",
          "country": {
            "label": "Paese di sede dell'Istituto"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Titolare dell'account"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Istituto del beneficiario",
          "country": {
            "label": "Paese di sede dell'Istituto"
          }
        },
        "account": {
          "owner": {
            "label": "Titolare dell'account"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Utilizzo"
      },
      "status": {
        "paid": {
          "label": "pagato"
        },
        "label": "Stato",
        "unpaid": {
          "label": "In sospeso"
        },
        "edit": {
          "buttonLabel": "Modifica i dettagli di pagamento"
        },
        "set": {
          "buttonLabel": "Modifica i dettagli di pagamento"
        }
      },
      "amount": {
        "label": "Importo pagato"
      },
      "information": {
        "label": "Informazioni sul pagamento"
      },
      "endDueTime": {
        "label": "Fine del periodo di pagamento"
      },
      "instrument": {
        "label": "Strumento di pagamento"
      },
      "reference": {
        "label": "Referenza di pagamento"
      },
      "startDueTime": {
        "label": "Inizio del periodo di pagamento"
      },
      "details": {
        "label": "Dettagli di pagamento"
      }
    },
    "VATRate": {
      "label": "Aliquota IVA"
    },
    "overview": {
      "label": "Panoramica"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF creato in data"
        }
      },
      "number": {
        "label": "Numero del documento"
      }
    },
    "agent": {
      "number": {
        "label": "Numero cliente"
      },
      "company": {
        "TIN": {
          "label": "codice fiscale"
        }
      },
      "VATIN": {
        "label": "Numero di identificazione IVA"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Si è verificato un errore nel nostro servizio. Per favore riprova.",
        "title": "Errore"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "Scarica il PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Riepilogo dei dati di reporting"
        }
      }
    },
    "details": {
      "label": "Dettagli"
    },
    "reportingPeriod": {
      "label": "Periodo di riferimento",
      "startDate": {
        "label": "Data di inizio della segnalazione"
      },
      "endDate": {
        "label": "Data di fine del rapporto"
      }
    },
    "credit": {
      "label": "Credito",
      "amount": {
        "label": "Credito"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Forniture intracomunitarie, totale"
      },
      "transfer": {
        "label": "Trasferimento intracomunitario"
      },
      "net": {
        "label": "Consegne esenti da imposte"
      }
    },
    "parkingRate": {
      "label": "Aliquota fiscale"
    },
    "sRR": {
      "label": "Aliquota fiscale"
    },
    "taxFreeSales": {
      "label": "Vendite esenti da imposte"
    },
    "standardRate": {
      "label": "Aliquota fiscale"
    },
    "authority": {
      "label": "Autorità"
    },
    "references": {
      "order": {
        "id": {
          "label": "ID ordine"
        }
      }
    },
    "outputVAT": {
      "label": "IVA"
    },
    "export": {
      "total": {
        "label": "Esportare"
      },
      "label": "Esportare"
    },
    "totalPaymentDue": {
      "label": "Importo del pagamento"
    },
    "interest": {
      "amount": {
        "label": "Importo degli interessi"
      },
      "label": "Interesse"
    },
    "order": {
      "date": {
        "label": "Data dell'ordine"
      }
    },
    "net": {
      "label": "Vendite nette"
    },
    "report": {
      "type": {
        "label": "Tipo di Rapporto"
      },
      "label": "Rapporto"
    },
    "reverseCharge": {
      "net": {
        "label": "Entrate nette per inversione contabile (Reverse charge)"
      },
      "label": "Annullamento dell'obbligo fiscale (Reverse charge)"
    },
    "reducedRate": {
      "label": "Aliquota fiscale"
    },
    "taxableSales": {
      "label": "Vendite imponibili"
    },
    "iCAOG": {
      "label": "Acquisizione intracomunitaria",
      "total": {
        "net": {
          "label": "Somma delle acquisizioni intracomunitarie"
        }
      }
    },
    "deductibleTax": {
      "label": "Imposta a monte deducibile"
    },
    "inputVAT": {
      "amount": {
        "label": "Imposta a monte dalle fatture"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Importazione IVA"
      }
    }
  },
  "fr-FR": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut du bénéficiaire",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Usage"
      },
      "status": {
        "paid": {
          "label": "payé"
        },
        "label": "Statut",
        "unpaid": {
          "label": "En attente"
        },
        "edit": {
          "buttonLabel": "Modifier les détails du paiement"
        },
        "set": {
          "buttonLabel": "Modifier les détails du paiement"
        }
      },
      "amount": {
        "label": "Le montant payé"
      },
      "information": {
        "label": "Informations de paiement"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "reference": {
        "label": "Référence de paiement"
      },
      "startDueTime": {
        "label": "Début de la période de paiement"
      },
      "details": {
        "label": "Détails de paiement"
      }
    },
    "VATRate": {
      "label": "Taux de TVA"
    },
    "overview": {
      "label": "Aperçu"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF créé le"
        }
      },
      "number": {
        "label": "Numéro de document"
      }
    },
    "agent": {
      "number": {
        "label": "Numéro de client"
      },
      "company": {
        "TIN": {
          "label": "Numéro d'identification fiscale"
        }
      },
      "VATIN": {
        "label": "Numéro d'identification TVA"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Une erreur s'est produite dans notre service. Veuillez réessayer.",
        "title": "Erreur"
      }
    },
    "downloads": {
      "label": "Téléchargements",
      "downloadPDF": {
        "buttonLabel": "Télécharger le PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Résumé des données de rapport"
        }
      }
    },
    "details": {
      "label": "Détails"
    },
    "reportingPeriod": {
      "label": "Période de rapport",
      "startDate": {
        "label": "Date de début du rapport"
      },
      "endDate": {
        "label": "Date de fin du rapport"
      }
    },
    "credit": {
      "label": "Crédit",
      "amount": {
        "label": "Crédit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Fournitures intracommunautaires, total"
      },
      "transfer": {
        "label": "Transfert intra-communautaire"
      },
      "net": {
        "label": "Livraisons hors taxes"
      }
    },
    "parkingRate": {
      "label": "Taux d'imposition"
    },
    "sRR": {
      "label": "Taux d'imposition"
    },
    "taxFreeSales": {
      "label": "Ventes hors taxes"
    },
    "standardRate": {
      "label": "Taux d'imposition"
    },
    "authority": {
      "label": "Autorité"
    },
    "references": {
      "order": {
        "id": {
          "label": "Numéro de commande"
        }
      }
    },
    "outputVAT": {
      "label": "Taxe sur la valeur ajoutée"
    },
    "export": {
      "total": {
        "label": "Exportation"
      },
      "label": "Exportation"
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    },
    "interest": {
      "amount": {
        "label": "Montant des intérêts"
      },
      "label": "Intérêt"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "net": {
      "label": "Ventes nettes"
    },
    "report": {
      "type": {
        "label": "Type de rapport"
      },
      "label": "Rapport"
    },
    "reverseCharge": {
      "net": {
        "label": "Recettes nettes pour l'autoliquidation (Reverse charge)"
      },
      "label": "Inversion de l'obligation fiscale (Reverse charge)"
    },
    "reducedRate": {
      "label": "Taux d'imposition"
    },
    "taxableSales": {
      "label": "Ventes taxables"
    },
    "iCAOG": {
      "label": "Acquisition intracommunautaire",
      "total": {
        "net": {
          "label": "Somme des acquisitions intracommunautaires"
        }
      }
    },
    "deductibleTax": {
      "label": "Taxe en amont déductible"
    },
    "inputVAT": {
      "amount": {
        "label": "Taxe déductible des factures"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Taxe de vente à l'importation"
      }
    }
  },
  "es-ES": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Instituto",
          "country": {
            "label": "País de sede del Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Propietario de la cuenta"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Instituto del beneficiario",
          "country": {
            "label": "País de sede del Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propietario de la cuenta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Uso"
      },
      "status": {
        "paid": {
          "label": "pagado"
        },
        "label": "Estado",
        "unpaid": {
          "label": "Pendiente"
        },
        "edit": {
          "buttonLabel": "Editar detalles de pago"
        },
        "set": {
          "buttonLabel": "Editar detalles de pago"
        }
      },
      "amount": {
        "label": "Cantidad pagada"
      },
      "information": {
        "label": "Información del pago"
      },
      "endDueTime": {
        "label": "Fin del período de pago"
      },
      "instrument": {
        "label": "Instrumento de pago"
      },
      "reference": {
        "label": "Referencia de pago"
      },
      "startDueTime": {
        "label": "Inicio del período de pago"
      },
      "details": {
        "label": "Detalles del pago"
      }
    },
    "VATRate": {
      "label": "tipo de IVA"
    },
    "overview": {
      "label": "Visión general"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF creado en"
        }
      },
      "number": {
        "label": "Número del Documento"
      }
    },
    "agent": {
      "number": {
        "label": "Número de cliente"
      },
      "company": {
        "TIN": {
          "label": "Número de impuesto"
        }
      },
      "VATIN": {
        "label": "Número de identificación de IVA"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Ha habido un error en nuestro servicio. Inténtelo de nuevo, por favor.",
        "title": "Error"
      }
    },
    "downloads": {
      "label": "Descargas",
      "downloadPDF": {
        "buttonLabel": "Descargar PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Resumen de los datos de informes"
        }
      }
    },
    "details": {
      "label": "Detalles"
    },
    "reportingPeriod": {
      "label": "Período de información",
      "startDate": {
        "label": "Fecha de inicio de informes"
      },
      "endDate": {
        "label": "Fecha de finalización del informe"
      }
    },
    "credit": {
      "label": "Crédito",
      "amount": {
        "label": "Crédito"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Suministros intracomunitarios, total"
      },
      "transfer": {
        "label": "Transferencia intracomunitaria"
      },
      "net": {
        "label": "Entregas libres de impuestos"
      }
    },
    "parkingRate": {
      "label": "Tasa de impuesto"
    },
    "sRR": {
      "label": "Tasa de impuesto"
    },
    "taxFreeSales": {
      "label": "Ventas libres de impuestos"
    },
    "standardRate": {
      "label": "Tasa de impuesto"
    },
    "authority": {
      "label": "Autoridad"
    },
    "references": {
      "order": {
        "id": {
          "label": "Solicitar ID"
        }
      }
    },
    "outputVAT": {
      "label": "Impuesto al valor añadido"
    },
    "export": {
      "total": {
        "label": "Exportar"
      },
      "label": "Exportar"
    },
    "totalPaymentDue": {
      "label": "Monto del pago"
    },
    "interest": {
      "amount": {
        "label": "Cantidad de interés"
      },
      "label": "Interesar"
    },
    "order": {
      "date": {
        "label": "Fecha de orden"
      }
    },
    "net": {
      "label": "Las ventas netas"
    },
    "report": {
      "type": {
        "label": "Tipo de Informe"
      },
      "label": "Reporte"
    },
    "reverseCharge": {
      "net": {
        "label": "Ingresos netos por cargo inverso (Reverse charge)"
      },
      "label": "Reversión de la obligación tributaria (Reverse charge)"
    },
    "reducedRate": {
      "label": "Tasa de impuesto"
    },
    "taxableSales": {
      "label": "Ventas gravables"
    },
    "iCAOG": {
      "label": "Adquisición intracomunitaria",
      "total": {
        "net": {
          "label": "Suma de adquisiciones intracomunitarias"
        }
      }
    },
    "deductibleTax": {
      "label": "Impuesto soportado deducible"
    },
    "inputVAT": {
      "amount": {
        "label": "Impuesto soportado de facturas"
      }
    },
    "importVAT": {
      "amount": {
        "label": "IVA de importación"
      }
    }
  },
  "pl-PL": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Instytut",
          "country": {
            "label": "Kraj siedziby instytutu"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Właściciel konta"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Instytut odbiorcy płatności",
          "country": {
            "label": "Kraj siedziby instytutu"
          }
        },
        "account": {
          "owner": {
            "label": "Właściciel konta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Stosowanie"
      },
      "status": {
        "paid": {
          "label": "płatny"
        },
        "label": "Status",
        "unpaid": {
          "label": "W oczekiwaniu"
        },
        "edit": {
          "buttonLabel": "Edytuj szczegóły płatności"
        },
        "set": {
          "buttonLabel": "Edytuj szczegóły płatności"
        }
      },
      "amount": {
        "label": "Opłata zapłacona"
      },
      "information": {
        "label": "Informacja o płatności"
      },
      "endDueTime": {
        "label": "Koniec terminu płatności"
      },
      "instrument": {
        "label": "Metoda płatności"
      },
      "reference": {
        "label": "Tytuł przelewu"
      },
      "startDueTime": {
        "label": "Początek okresu płatności"
      },
      "details": {
        "label": "Szczegóły płatności"
      }
    },
    "VATRate": {
      "label": "Stawka VAT"
    },
    "overview": {
      "label": "Przegląd"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF utworzony dnia"
        }
      },
      "number": {
        "label": "Numer dokumentu"
      }
    },
    "agent": {
      "number": {
        "label": "Numer klienta"
      },
      "company": {
        "TIN": {
          "label": "numer identyfikacji podatkowej"
        }
      },
      "VATIN": {
        "label": "Numer identyfikacyjny VAT"
      }
    },
    "notification": {
      "asyncError": {
        "message": "W naszym serwisie wystąpił błąd. Prosimy, spróbuj ponownie.",
        "title": "Błąd"
      }
    },
    "downloads": {
      "label": "Pliki do pobrania",
      "downloadPDF": {
        "buttonLabel": "pobierz PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Podsumowanie danych sprawozdawczych"
        }
      }
    },
    "details": {
      "label": "Informacja szczegółowa"
    },
    "reportingPeriod": {
      "label": "Okres sprawozdawczy",
      "startDate": {
        "label": "Początek okresu podlegającego zgłoszeniu"
      },
      "endDate": {
        "label": "Koniec okresu podlegającego zgłoszeniu"
      }
    },
    "credit": {
      "label": "Kwota do zwrotu",
      "amount": {
        "label": "Kwota do zwrotu"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Dostawy wewnątrzwspólnotowe, całkowity"
      },
      "transfer": {
        "label": "Wewnątrzwspólnotowy przepływ towarów"
      },
      "net": {
        "label": "Bezpodatkowa dostawa towarów"
      }
    },
    "parkingRate": {
      "label": "Stawka"
    },
    "sRR": {
      "label": "Stawka"
    },
    "taxFreeSales": {
      "label": "Sprzedaż bezpodatkowa"
    },
    "standardRate": {
      "label": "Stawka"
    },
    "authority": {
      "label": "Urząd"
    },
    "references": {
      "order": {
        "id": {
          "label": "Numer usługi"
        }
      }
    },
    "outputVAT": {
      "label": "Podatek VAT"
    },
    "export": {
      "total": {
        "label": "Eksport"
      },
      "label": "Eksport"
    },
    "totalPaymentDue": {
      "label": "Wysokość należności"
    },
    "interest": {
      "amount": {
        "label": "Kwota odsetek"
      },
      "label": "Zainteresowanie"
    },
    "order": {
      "date": {
        "label": "Data zatwierdzenia danych"
      }
    },
    "net": {
      "label": "Sprzedaż netto"
    },
    "report": {
      "type": {
        "label": "Typ Raportu"
      },
      "label": "Raport"
    },
    "reverseCharge": {
      "net": {
        "label": "Sprzedaż z odwrotnym obciążeniem (reverse charge)"
      },
      "label": "Odwrócenie zobowiązania podatkowego (Reverse charge)"
    },
    "reducedRate": {
      "label": "Stawka"
    },
    "taxableSales": {
      "label": "Sprzedaż opodatkowana"
    },
    "iCAOG": {
      "label": "Wewnątrzwspólnotowe nabycia towarów",
      "total": {
        "net": {
          "label": "Suma przejęć wewnątrzwspólnotowych"
        }
      }
    },
    "deductibleTax": {
      "label": "Podatek naliczony do odliczenia"
    },
    "inputVAT": {
      "amount": {
        "label": "Podatek naliczony z faktur"
      }
    },
    "importVAT": {
      "amount": {
        "label": "VAT importowy"
      }
    }
  },
  "nl-NL": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Instituut",
          "country": {
            "label": "Land van zetel van het Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Accounteigenaar"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Het instituut van de begunstigde",
          "country": {
            "label": "Land van zetel van het Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Accounteigenaar"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Gebruik"
      },
      "status": {
        "paid": {
          "label": "betaald"
        },
        "label": "Toestand",
        "unpaid": {
          "label": "Aanhangig"
        },
        "edit": {
          "buttonLabel": "Betalingsgegevens bewerken"
        },
        "set": {
          "buttonLabel": "Betalingsgegevens bewerken"
        }
      },
      "amount": {
        "label": "Betaald bedrag"
      },
      "information": {
        "label": "Betalingsinformatie"
      },
      "endDueTime": {
        "label": "Einde van de betalingsperiode"
      },
      "instrument": {
        "label": "Betaalinstrument"
      },
      "reference": {
        "label": "Betalingskenmerk"
      },
      "startDueTime": {
        "label": "Begin van de betalingsperiode"
      },
      "details": {
        "label": "Betalingsdetails"
      }
    },
    "VATRate": {
      "label": "BTW-tarief"
    },
    "overview": {
      "label": "Overzicht"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF gemaakt op"
        }
      },
      "number": {
        "label": "Document Nummer"
      }
    },
    "agent": {
      "number": {
        "label": "Klantnummer"
      },
      "company": {
        "TIN": {
          "label": "BTW Nummer"
        }
      },
      "VATIN": {
        "label": "BTW-identificatienummer"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Er is een fout opgetreden in onze service. Gelieve opnieuw te proberen.",
        "title": "Fout"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "Download PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Samenvatting van rapportagegegevens"
        }
      }
    },
    "details": {
      "label": "Details"
    },
    "reportingPeriod": {
      "label": "Verslagperiode",
      "startDate": {
        "label": "Startdatum rapportage"
      },
      "endDate": {
        "label": "Einddatum rapportage"
      }
    },
    "credit": {
      "label": "Credit",
      "amount": {
        "label": "Credit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Intracommunautaire leveringen, totaal"
      },
      "transfer": {
        "label": "Overdracht binnen de gemeenschap"
      },
      "net": {
        "label": "Belastingvrije leveringen"
      }
    },
    "parkingRate": {
      "label": "Belastingtarief"
    },
    "sRR": {
      "label": "Belastingtarief"
    },
    "taxFreeSales": {
      "label": "Belastingvrije verkoop"
    },
    "standardRate": {
      "label": "Belastingtarief"
    },
    "authority": {
      "label": "Gezag"
    },
    "references": {
      "order": {
        "id": {
          "label": "Order ID"
        }
      }
    },
    "outputVAT": {
      "label": "Belasting toegevoegde waarde"
    },
    "export": {
      "total": {
        "label": "Exporteren"
      },
      "label": "Exporteren"
    },
    "totalPaymentDue": {
      "label": "Te betalen bedrag"
    },
    "interest": {
      "amount": {
        "label": "Rentebedrag"
      },
      "label": "Interesseren"
    },
    "order": {
      "date": {
        "label": "Besteldatum"
      }
    },
    "net": {
      "label": "Netto-omzet"
    },
    "report": {
      "type": {
        "label": "Rapporttype"
      },
      "label": "Rapport"
    },
    "reverseCharge": {
      "net": {
        "label": "Netto-inkomsten voor verleggingskosten (Reverse charge)"
      },
      "label": "Terugname van belastingverplichting (Reverse charge)"
    },
    "reducedRate": {
      "label": "Belastingtarief"
    },
    "taxableSales": {
      "label": "Belastbare verkopen"
    },
    "iCAOG": {
      "label": "Intracommunautaire verwervingen",
      "total": {
        "net": {
          "label": "Som van intracommunautaire acquisities"
        }
      }
    },
    "deductibleTax": {
      "label": "Aftrekbare voorbelasting"
    },
    "inputVAT": {
      "amount": {
        "label": "Voorbelasting van facturen"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Omzetbelasting importeren"
      }
    }
  },
  "bg-BG": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Институт",
          "country": {
            "label": "Държава на седалището на Института"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Cобственик на акаунт"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Институтът на бенефициента",
          "country": {
            "label": "Държава на седалището на Института"
          }
        },
        "account": {
          "owner": {
            "label": "Cобственик на акаунт"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Употреба"
      },
      "status": {
        "paid": {
          "label": "платен"
        },
        "label": "Cтатус",
        "unpaid": {
          "label": "Висящ"
        },
        "edit": {
          "buttonLabel": "Редактиране на данните за плащане"
        },
        "set": {
          "buttonLabel": "Редактиране на данните за плащане"
        }
      },
      "amount": {
        "label": "Платено количество"
      },
      "information": {
        "label": "Информация за плащане"
      },
      "endDueTime": {
        "label": "Срок за плащане на ДДС"
      },
      "instrument": {
        "label": "Платежно нареждане"
      },
      "reference": {
        "label": "Справка за плащане"
      },
      "startDueTime": {
        "label": "Начало на периода на плащане"
      },
      "details": {
        "label": "Подробности на плащане"
      }
    },
    "VATRate": {
      "label": "Ставка на ДДС"
    },
    "overview": {
      "label": "Преглед"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF, създаден на"
        }
      },
      "number": {
        "label": "Номер на документ"
      }
    },
    "agent": {
      "number": {
        "label": "Клиентски номер"
      },
      "company": {
        "TIN": {
          "label": "данъчен номер"
        }
      },
      "VATIN": {
        "label": "Идентификационен номер по ДДС"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Възникна грешка в нашата услуга. Моля, опитайте отново.",
        "title": "грешка"
      }
    },
    "downloads": {
      "label": "Изтегляния",
      "downloadPDF": {
        "buttonLabel": "Изтеглете PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Обобщение на отчетни данни"
        }
      }
    },
    "details": {
      "label": "детайли"
    },
    "reportingPeriod": {
      "label": "Отчетен период",
      "startDate": {
        "label": "Отчитане на начална дата"
      },
      "endDate": {
        "label": "Крайна дата на отчитане"
      }
    },
    "credit": {
      "label": "Кредит",
      "amount": {
        "label": "Кредит"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Вътрешнообщностни доставки, обща сума"
      },
      "transfer": {
        "label": "Вътрешнообщностен трансфер"
      },
      "net": {
        "label": "Без такси доставки"
      }
    },
    "parkingRate": {
      "label": "Данъчна ставка"
    },
    "sRR": {
      "label": "Данъчна ставка"
    },
    "taxFreeSales": {
      "label": "Без данъци"
    },
    "standardRate": {
      "label": "Данъчна ставка"
    },
    "authority": {
      "label": "власт"
    },
    "references": {
      "order": {
        "id": {
          "label": "Идент. № на поръчката"
        }
      }
    },
    "outputVAT": {
      "label": "данък върху добавената стойност"
    },
    "export": {
      "total": {
        "label": "износ"
      },
      "label": "износ"
    },
    "totalPaymentDue": {
      "label": "Сума за плащане"
    },
    "interest": {
      "amount": {
        "label": "Сума на лихвите"
      },
      "label": "Лихва"
    },
    "order": {
      "date": {
        "label": "Дата на поръчка"
      }
    },
    "net": {
      "label": "Нетните продажби"
    },
    "report": {
      "type": {
        "label": "Тип Oтчет"
      },
      "label": "Доклад"
    },
    "reverseCharge": {
      "net": {
        "label": "Нетни приходи за обратна такса (Reverse charge)"
      },
      "label": "отмяна на данъчно задължение (Reverse charge)"
    },
    "reducedRate": {
      "label": "Данъчна ставка"
    },
    "taxableSales": {
      "label": "Облагаеми продажби"
    },
    "iCAOG": {
      "label": "Придобивания в рамките на Общността",
      "total": {
        "net": {
          "label": "Сума на вътреобщностните придобивания"
        }
      }
    },
    "deductibleTax": {
      "label": "Приспадаем входен данък"
    },
    "inputVAT": {
      "amount": {
        "label": "Входен данък от фактури"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Данък върху продажбите върху вноса"
      }
    }
  },
  "bs-BA": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Zemlja sjedišta Instituta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut primatelja",
          "country": {
            "label": "Zemlja sjedišta Instituta"
          }
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Upotreba"
      },
      "status": {
        "paid": {
          "label": "plaćeni"
        },
        "label": "Statusa",
        "unpaid": {
          "label": "Na čekanju"
        },
        "edit": {
          "buttonLabel": "Uređivanje podataka o plaćanju"
        },
        "set": {
          "buttonLabel": "Uređivanje podataka o plaćanju"
        }
      },
      "amount": {
        "label": "Iznos plaćen"
      },
      "information": {
        "label": "Informacije o plaćanju"
      },
      "endDueTime": {
        "label": "Kraj perioda plaćanja"
      },
      "instrument": {
        "label": "Instrument plaćanja"
      },
      "reference": {
        "label": "Referenca o uplati"
      },
      "startDueTime": {
        "label": "Početak perioda plaćanja"
      },
      "details": {
        "label": "Detalji plaćanja"
      }
    },
    "VATRate": {
      "label": "Stopa PDV-a"
    },
    "overview": {
      "label": "Pregled"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF kreiran na"
        }
      },
      "number": {
        "label": "Broj dokumenta"
      }
    },
    "agent": {
      "number": {
        "label": "Broj kupca"
      },
      "company": {
        "TIN": {
          "label": "porezni broj"
        }
      },
      "VATIN": {
        "label": "PDV broj"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Došlo je do greške u našem servisu. Molimo pokušajte ponovo.",
        "title": "Greška"
      }
    },
    "downloads": {
      "label": "Preuzimanja",
      "downloadPDF": {
        "buttonLabel": "Preuzmite PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Sažetak podataka izvještavanja"
        }
      }
    },
    "details": {
      "label": "Detalji"
    },
    "reportingPeriod": {
      "label": "Izveštajni period",
      "startDate": {
        "label": "Datum početka izvještavanja"
      },
      "endDate": {
        "label": "Krajnji datum izvještavanja"
      }
    },
    "credit": {
      "label": "Kredit",
      "amount": {
        "label": "Kredit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Opskrba unutar zajednice, ukupno"
      },
      "transfer": {
        "label": "Prenos unutar zajednice"
      },
      "net": {
        "label": "Isporuke bez poreza"
      }
    },
    "parkingRate": {
      "label": "Stopa poreza"
    },
    "sRR": {
      "label": "Stopa poreza"
    },
    "taxFreeSales": {
      "label": "Prodaja bez poreza"
    },
    "standardRate": {
      "label": "Stopa poreza"
    },
    "authority": {
      "label": "Autoritet"
    },
    "references": {
      "order": {
        "id": {
          "label": "Id narudžbe"
        }
      }
    },
    "outputVAT": {
      "label": "Porez na dodanu vrijednost"
    },
    "export": {
      "total": {
        "label": "Izvoz"
      },
      "label": "Izvoz"
    },
    "totalPaymentDue": {
      "label": "Iznos plaćanja"
    },
    "interest": {
      "amount": {
        "label": "Iznos kamate"
      },
      "label": "Kamata"
    },
    "order": {
      "date": {
        "label": "Datum narudžbe"
      }
    },
    "net": {
      "label": "Neto prihoda od prodaje"
    },
    "report": {
      "type": {
        "label": "Vrsta Izvještaja"
      },
      "label": "Izvještaj"
    },
    "reverseCharge": {
      "net": {
        "label": "Neto prihod za obrnuto trošak (Reverse charge)"
      },
      "label": "Ukidanje poreske obaveze (Reverse charge)"
    },
    "reducedRate": {
      "label": "Stopa poreza"
    },
    "taxableSales": {
      "label": "Oporeziva prodaja"
    },
    "iCAOG": {
      "label": "Akvizicije unutar zajednice",
      "total": {
        "net": {
          "label": "Zbir akvizicija unutar zajednice"
        }
      }
    },
    "deductibleTax": {
      "label": "Odbitni pretporez"
    },
    "inputVAT": {
      "amount": {
        "label": "Ulazni porez iz računa"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Uvezi PDV"
      }
    }
  },
  "da-DK": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Institut for sæde"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Kontoindehaver"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Betalingsmodtagerens institut",
          "country": {
            "label": "Institut for sæde"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoindehaver"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Anvendelse"
      },
      "status": {
        "paid": {
          "label": "betalt"
        },
        "label": "Status",
        "unpaid": {
          "label": "Verserende"
        },
        "edit": {
          "buttonLabel": "Rediger betalingsoplysninger"
        },
        "set": {
          "buttonLabel": "Rediger betalingsoplysninger"
        }
      },
      "amount": {
        "label": "Betalt beløb"
      },
      "information": {
        "label": "Betalingsinformation"
      },
      "endDueTime": {
        "label": "Betalingsperiodens slutning"
      },
      "instrument": {
        "label": "Betalingsinstrument"
      },
      "reference": {
        "label": "Betalings reference"
      },
      "startDueTime": {
        "label": "Begyndelsen af betalingsperioden"
      },
      "details": {
        "label": "Betalingsoplysninger"
      }
    },
    "VATRate": {
      "label": "Momssats"
    },
    "overview": {
      "label": "Oversigt"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF oprettet den"
        }
      },
      "number": {
        "label": "Dokument Nummer"
      }
    },
    "agent": {
      "number": {
        "label": "Kundenummer"
      },
      "company": {
        "TIN": {
          "label": "Skattenummer"
        }
      },
      "VATIN": {
        "label": "Moms-identifikationsnummer"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Der opstod en fejl i vores service. Prøv igen.",
        "title": "Fejl"
      }
    },
    "downloads": {
      "label": "Downloads",
      "downloadPDF": {
        "buttonLabel": "Download PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Resumé af rapporteringsdata"
        }
      }
    },
    "details": {
      "label": "Detaljer"
    },
    "reportingPeriod": {
      "label": "Rapporteringsperiode",
      "startDate": {
        "label": "Rapporterings startdato"
      },
      "endDate": {
        "label": "Rapporterings slutdato"
      }
    },
    "credit": {
      "label": "Kredit",
      "amount": {
        "label": "Kredit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Forsyninger inden for samfundet, Total"
      },
      "transfer": {
        "label": "Intra-community overførsel"
      },
      "net": {
        "label": "Skattefrie leveringer"
      }
    },
    "parkingRate": {
      "label": "Skatteprocent"
    },
    "sRR": {
      "label": "Skatteprocent"
    },
    "taxFreeSales": {
      "label": "Skattefrit salg"
    },
    "standardRate": {
      "label": "Skatteprocent"
    },
    "authority": {
      "label": "Myndighed"
    },
    "references": {
      "order": {
        "id": {
          "label": "Ordre ID"
        }
      }
    },
    "outputVAT": {
      "label": "Merværdiafgift"
    },
    "export": {
      "total": {
        "label": "Eksport"
      },
      "label": "Eksport"
    },
    "totalPaymentDue": {
      "label": "Betalingsbeløb"
    },
    "interest": {
      "amount": {
        "label": "Rente beløb"
      },
      "label": "Interesse"
    },
    "order": {
      "date": {
        "label": "Bestillingsdato"
      }
    },
    "net": {
      "label": "Nettosalg"
    },
    "report": {
      "type": {
        "label": "Rapporttype"
      },
      "label": "Rapport"
    },
    "reverseCharge": {
      "net": {
        "label": "Nettoomsætning til omvendt gebyr (Reverse charge)"
      },
      "label": "Tilbageførsel af skattepligt (Reverse charge)"
    },
    "reducedRate": {
      "label": "Skatteprocent"
    },
    "taxableSales": {
      "label": "Skattepligtigt salg"
    },
    "iCAOG": {
      "label": "Erhvervelser inden for Fællesskabet",
      "total": {
        "net": {
          "label": "Summen af erhvervelser inden for samfundet"
        }
      }
    },
    "deductibleTax": {
      "label": "Fradragsberettiget indgående skat"
    },
    "inputVAT": {
      "amount": {
        "label": "Indgående skat fra fakturaer"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Import moms"
      }
    }
  },
  "et-EE": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Instituut",
          "country": {
            "label": "Instituudi asukohariik"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Konto omanik"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Saaja instituut",
          "country": {
            "label": "Instituudi asukohariik"
          }
        },
        "account": {
          "owner": {
            "label": "Konto omanik"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Kasutamine"
      },
      "status": {
        "paid": {
          "label": "makstud"
        },
        "label": "Staatus",
        "unpaid": {
          "label": "Ootel"
        },
        "edit": {
          "buttonLabel": "Muutke makse üksikasju"
        },
        "set": {
          "buttonLabel": "Muutke makse üksikasju"
        }
      },
      "amount": {
        "label": "Makstud summa"
      },
      "information": {
        "label": "Makseteave"
      },
      "endDueTime": {
        "label": "Makseperioodi lõpp"
      },
      "instrument": {
        "label": "Maksevahend"
      },
      "reference": {
        "label": "Makseviide"
      },
      "startDueTime": {
        "label": "Makseperioodi algus"
      },
      "details": {
        "label": "Makse andmed"
      }
    },
    "VATRate": {
      "label": "Käibemaksumäär"
    },
    "overview": {
      "label": "Ülevaade"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF loodud"
        }
      },
      "number": {
        "label": "Dokumendi number"
      }
    },
    "agent": {
      "number": {
        "label": "Kliendi number"
      },
      "company": {
        "TIN": {
          "label": "maksunumber"
        }
      },
      "VATIN": {
        "label": "Käibemaksukohustuslase registreerimisnumber"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Meie teenuses on ilmnenud tõrge. Palun proovi uuesti.",
        "title": "Viga"
      }
    },
    "downloads": {
      "label": "Allalaadimised",
      "downloadPDF": {
        "buttonLabel": "Laadige alla PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Aruandlusandmete kokkuvõte"
        }
      }
    },
    "details": {
      "label": "Üksikasjad"
    },
    "reportingPeriod": {
      "label": "Aruandeperiood",
      "startDate": {
        "label": "Aruande alguskuupäev"
      },
      "endDate": {
        "label": "Aruande lõppkuupäev"
      }
    },
    "credit": {
      "label": "Krediit",
      "amount": {
        "label": "Krediit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Ühendusesisesed tarned, kokku"
      },
      "transfer": {
        "label": "Kogukonnasisene ülekandmine"
      },
      "net": {
        "label": "Maksuvabad tarned"
      }
    },
    "parkingRate": {
      "label": "Maksumäär"
    },
    "sRR": {
      "label": "Maksumäär"
    },
    "taxFreeSales": {
      "label": "Maksuvaba müük"
    },
    "standardRate": {
      "label": "Maksumäär"
    },
    "authority": {
      "label": "Autoriteet"
    },
    "references": {
      "order": {
        "id": {
          "label": "Tellimuse ID"
        }
      }
    },
    "outputVAT": {
      "label": "Käibemaks"
    },
    "export": {
      "total": {
        "label": "Eksport"
      },
      "label": "Eksport"
    },
    "totalPaymentDue": {
      "label": "Makse summa"
    },
    "interest": {
      "amount": {
        "label": "Intressi summa"
      },
      "label": "Huvi"
    },
    "order": {
      "date": {
        "label": "Tellimuse kuupäev"
      }
    },
    "net": {
      "label": "Müügitulu"
    },
    "report": {
      "type": {
        "label": "Aruande Tüüp"
      },
      "label": "Aruanne"
    },
    "reverseCharge": {
      "net": {
        "label": "Pöördmaksustamise puhastulu (Reverse charge)"
      },
      "label": "Maksukohustuse tühistamine (Reverse charge)"
    },
    "reducedRate": {
      "label": "Maksumäär"
    },
    "taxableSales": {
      "label": "Maksustatav müük"
    },
    "iCAOG": {
      "label": "Ühendusesisesed omandamised",
      "total": {
        "net": {
          "label": "Ühendusesiseste omandamiste summa"
        }
      }
    },
    "deductibleTax": {
      "label": "Mahaarvatav sisendkäibemaks"
    },
    "inputVAT": {
      "amount": {
        "label": "Sisendmaks arvetelt"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Impordi käibemaks"
      }
    }
  },
  "fi-FI": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Instituutti",
          "country": {
            "label": "Instituutin kotimaa"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Tilin omistaja"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Maksunsaajan instituutti",
          "country": {
            "label": "Instituutin kotimaa"
          }
        },
        "account": {
          "owner": {
            "label": "Tilin omistaja"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Käyttö"
      },
      "status": {
        "paid": {
          "label": "maksettu"
        },
        "label": "Tila",
        "unpaid": {
          "label": "Odotettaessa"
        },
        "edit": {
          "buttonLabel": "Muokkaa maksutietoja"
        },
        "set": {
          "buttonLabel": "Muokkaa maksutietoja"
        }
      },
      "amount": {
        "label": "Maksettu summa"
      },
      "information": {
        "label": "Tietoja maksusta"
      },
      "endDueTime": {
        "label": "Maksuajan päättyminen"
      },
      "instrument": {
        "label": "Maksutapa"
      },
      "reference": {
        "label": "Maksuviite"
      },
      "startDueTime": {
        "label": "Maksuajan alku"
      },
      "details": {
        "label": "Maksutiedot"
      }
    },
    "VATRate": {
      "label": "ALV"
    },
    "overview": {
      "label": "Yleiskatsaus"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF luotu"
        }
      },
      "number": {
        "label": "Asiakirjan numero"
      }
    },
    "agent": {
      "number": {
        "label": "Asiakasnumero"
      },
      "company": {
        "TIN": {
          "label": "Veronumero"
        }
      },
      "VATIN": {
        "label": "ALV-tunnistenumero"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Palvelussamme on tapahtunut virhe. Olkaa hyvä, ja yrittäkää uudelleen.",
        "title": "Virhe"
      }
    },
    "downloads": {
      "label": "Lataukset",
      "downloadPDF": {
        "buttonLabel": "Lataa PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Yhteenveto raportointitiedoista"
        }
      }
    },
    "details": {
      "label": "Yksityiskohdat"
    },
    "reportingPeriod": {
      "label": "Raportointikausi",
      "startDate": {
        "label": "Raportoinnin alkamispäivä"
      },
      "endDate": {
        "label": "Raportoinnin lopetuspäivä"
      }
    },
    "credit": {
      "label": "Luotto",
      "amount": {
        "label": "Luotto"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Yhteisön sisäiset tarvikkeet, kaikki yhteensä"
      },
      "transfer": {
        "label": "Yhteisön sisäinen siirto"
      },
      "net": {
        "label": "Veroton toimitus"
      }
    },
    "parkingRate": {
      "label": "Veroaste"
    },
    "sRR": {
      "label": "Veroaste"
    },
    "taxFreeSales": {
      "label": "Verovapaa myynti"
    },
    "standardRate": {
      "label": "Veroaste"
    },
    "authority": {
      "label": "Auktoriteetti"
    },
    "references": {
      "order": {
        "id": {
          "label": "Tilausnumero"
        }
      }
    },
    "outputVAT": {
      "label": "Arvonlisävero"
    },
    "export": {
      "total": {
        "label": "Viedä"
      },
      "label": "Viedä"
    },
    "totalPaymentDue": {
      "label": "Maksun määrä"
    },
    "interest": {
      "amount": {
        "label": "Korkomäärä"
      },
      "label": "Kiinnostuksen kohde"
    },
    "order": {
      "date": {
        "label": "Tilauspäivämäärä"
      }
    },
    "net": {
      "label": "Liikevaihdosta"
    },
    "report": {
      "type": {
        "label": "Raporttityyppi"
      },
      "label": "Raportti"
    },
    "reverseCharge": {
      "net": {
        "label": "Käänteisen verotuksen nettotuotot (Reverse charge)"
      },
      "label": "Verovelvollisuuden peruuttaminen (Reverse charge)"
    },
    "reducedRate": {
      "label": "Veroaste"
    },
    "taxableSales": {
      "label": "Verollinen myynti"
    },
    "iCAOG": {
      "label": "Yhteisön sisäiset hankinnat",
      "total": {
        "net": {
          "label": "Yhteisön sisäisten yritysostojen summa"
        }
      }
    },
    "deductibleTax": {
      "label": "Vähennyskelpoinen ostoihin sisältyvä vero"
    },
    "inputVAT": {
      "amount": {
        "label": "Laskujen vero"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Tuo myyntivero"
      }
    }
  },
  "el-GR": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Ινστιτούτο",
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Ινστιτούτο δικαιούχου πληρωμής",
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          }
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Χρήση"
      },
      "status": {
        "paid": {
          "label": "επί πληρωμή"
        },
        "label": "κατάσταση",
        "unpaid": {
          "label": "Εκκρεμής"
        },
        "edit": {
          "buttonLabel": "Επεξεργασία στοιχείων πληρωμής"
        },
        "set": {
          "buttonLabel": "Επεξεργασία στοιχείων πληρωμής"
        }
      },
      "amount": {
        "label": "Ποσό που καταβάλλεται"
      },
      "information": {
        "label": "Πληροφορίες Πληρωμής"
      },
      "endDueTime": {
        "label": "Λήξη της περιόδου πληρωμής"
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      },
      "reference": {
        "label": "Αναφορά πληρωμής"
      },
      "startDueTime": {
        "label": "Έναρξη της περιόδου πληρωμής"
      },
      "details": {
        "label": "Οι λεπτομέρειες πληρωμής"
      }
    },
    "VATRate": {
      "label": "Συντελεστή ΦΠΑ"
    },
    "overview": {
      "label": "ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "Το PDF δημιουργήθηκε στις"
        }
      },
      "number": {
        "label": "Αριθμός Εγγράφου"
      }
    },
    "agent": {
      "number": {
        "label": "Αριθμός των πελατών"
      },
      "company": {
        "TIN": {
          "label": "ΑΦΜ"
        }
      },
      "VATIN": {
        "label": "Αριθμός αναγνώρισης ΦΠΑ"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.",
        "title": "Σφάλμα"
      }
    },
    "downloads": {
      "label": "Λήψεις",
      "downloadPDF": {
        "buttonLabel": "Λήψη PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Περίληψη των δεδομένων αναφοράς"
        }
      }
    },
    "details": {
      "label": "Λεπτομέριες"
    },
    "reportingPeriod": {
      "label": "Περίοδος αναφοράς",
      "startDate": {
        "label": "Ημερομηνία έναρξης αναφοράς"
      },
      "endDate": {
        "label": "Ημερομηνία λήξης αναφοράς"
      }
    },
    "credit": {
      "label": "Πίστωση",
      "amount": {
        "label": "Πίστωση"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Ενδοκοινοτικές προμήθειες, σύνολο"
      },
      "transfer": {
        "label": "Ενδοκοινοτική μεταφορά"
      },
      "net": {
        "label": "Αφορολόγητες παραδόσεις"
      }
    },
    "parkingRate": {
      "label": "Φορολογικός συντελεστής"
    },
    "sRR": {
      "label": "Φορολογικός συντελεστής"
    },
    "taxFreeSales": {
      "label": "Αφορολόγητες πωλήσεις"
    },
    "standardRate": {
      "label": "Φορολογικός συντελεστής"
    },
    "authority": {
      "label": "εξουσία"
    },
    "references": {
      "order": {
        "id": {
          "label": "Αριθμός Παραγγελίας"
        }
      }
    },
    "outputVAT": {
      "label": "Φόρος Προστιθέμενης Αξίας"
    },
    "export": {
      "total": {
        "label": "εξαγωγή"
      },
      "label": "εξαγωγή"
    },
    "totalPaymentDue": {
      "label": "Ποσό ΠΛΗΡΩΜΗΣ"
    },
    "interest": {
      "amount": {
        "label": "Ποσό τόκου"
      },
      "label": "Ενδιαφέρον"
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας"
      }
    },
    "net": {
      "label": "Καθαρές πωλήσεις"
    },
    "report": {
      "type": {
        "label": "Τύπος Aναφοράς"
      },
      "label": "Κανω ΑΝΑΦΟΡΑ"
    },
    "reverseCharge": {
      "net": {
        "label": "Καθαρά έσοδα για αντίστροφη χρέωση (Reverse charge)"
      },
      "label": "αντιστροφή της φορολογικής υποχρέωσης (Reverse charge)"
    },
    "reducedRate": {
      "label": "Φορολογικός συντελεστής"
    },
    "taxableSales": {
      "label": "Φορολογητέες πωλήσεις"
    },
    "iCAOG": {
      "label": "Ενδοκοινοτικές εξαγορές",
      "total": {
        "net": {
          "label": "Άθροισμα ενδοκοινοτικών εξαγορών"
        }
      }
    },
    "deductibleTax": {
      "label": "Εκπεστέος φόρος εισροών"
    },
    "inputVAT": {
      "amount": {
        "label": "Φόρος εισροών από τιμολόγια"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Εισαγωγή ΦΠΑ"
      }
    }
  },
  "he-IL": {
    "payment": {
      "agent": {
        "bank": {
          "label": "מכון",
          "country": {
            "label": "ארץ מושב המכון"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "בעל החשבון"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "מכון מקבל התשלום",
          "country": {
            "label": "ארץ מושב המכון"
          }
        },
        "account": {
          "owner": {
            "label": "בעל החשבון"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "נוֹהָג"
      },
      "status": {
        "paid": {
          "label": "שולם"
        },
        "label": "סטָטוּס",
        "unpaid": {
          "label": "ממתין ל"
        },
        "edit": {
          "buttonLabel": "ערוך את פרטי התשלום"
        },
        "set": {
          "buttonLabel": "ערוך את פרטי התשלום"
        }
      },
      "amount": {
        "label": "סכום ששולם"
      },
      "information": {
        "label": "מידע על התשלום"
      },
      "endDueTime": {
        "label": "סוף תקופת התשלום"
      },
      "instrument": {
        "label": "מכשיר תשלום"
      },
      "reference": {
        "label": "הפניה לתשלום"
      },
      "startDueTime": {
        "label": "תחילת תקופת התשלום"
      },
      "details": {
        "label": "פרטי תשלום"
      }
    },
    "VATRate": {
      "label": "שיעור מע＂מ"
    },
    "overview": {
      "label": "סקירה כללית"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF נוצר ב-"
        }
      },
      "number": {
        "label": "מספר מסמך"
      }
    },
    "agent": {
      "number": {
        "label": "מספר לקוח"
      },
      "company": {
        "TIN": {
          "label": "מספר מס"
        }
      },
      "VATIN": {
        "label": "מספר זיהוי מע＂מ"
      }
    },
    "notification": {
      "asyncError": {
        "message": "אירעה שגיאה בשירות שלנו. בבקשה נסה שוב.",
        "title": "שְׁגִיאָה"
      }
    },
    "downloads": {
      "label": "הורדות",
      "downloadPDF": {
        "buttonLabel": "הורד PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "סיכום נתוני הדיווח"
        }
      }
    },
    "details": {
      "label": "פרטים"
    },
    "reportingPeriod": {
      "label": "תקופת הדיווח",
      "startDate": {
        "label": "מדווח על תאריך התחלה"
      },
      "endDate": {
        "label": "תאריך סיום מדווח"
      }
    },
    "credit": {
      "label": "אַשׁרַאי",
      "amount": {
        "label": "אַשׁרַאי"
      }
    },
    "iCSOG": {
      "total": {
        "label": "אספקה פנים-קהילתית, סה＂כ"
      },
      "transfer": {
        "label": "העברה פנים-קהילתית"
      },
      "net": {
        "label": "משלוחים ללא מס"
      }
    },
    "parkingRate": {
      "label": "שיעור מס"
    },
    "sRR": {
      "label": "שיעור מס"
    },
    "taxFreeSales": {
      "label": "מכירות ללא מס"
    },
    "standardRate": {
      "label": "שיעור מס"
    },
    "authority": {
      "label": "רְשׁוּת"
    },
    "references": {
      "order": {
        "id": {
          "label": "מספר הזמנה"
        }
      }
    },
    "outputVAT": {
      "label": "מס ערך מוס"
    },
    "export": {
      "total": {
        "label": "יְצוּא"
      },
      "label": "יְצוּא"
    },
    "totalPaymentDue": {
      "label": "סכום לתשלום"
    },
    "interest": {
      "amount": {
        "label": "סכום ריבית"
      },
      "label": "ריבית"
    },
    "order": {
      "date": {
        "label": "תאריך הזמנה"
      }
    },
    "net": {
      "label": "מכירות רשת"
    },
    "report": {
      "type": {
        "label": "סוג דוח"
      },
      "label": "להגיש תלונה"
    },
    "reverseCharge": {
      "net": {
        "label": "הכנסות נטו לחיוב הפוך (Reverse charge)"
      },
      "label": "(Reverse charge) ביטול חבות המס"
    },
    "reducedRate": {
      "label": "שיעור מס"
    },
    "taxableSales": {
      "label": "מכירות חייבות במס"
    },
    "iCAOG": {
      "label": "רכישות פנים-קהילתיות",
      "total": {
        "net": {
          "label": "סכום הרכישות התוך-קהילתיות"
        }
      }
    },
    "deductibleTax": {
      "label": "מס תשומות לניכוי"
    },
    "inputVAT": {
      "amount": {
        "label": "מס תשומות מחשבוניות"
      }
    },
    "importVAT": {
      "amount": {
        "label": "יבוא מע＂מ"
      }
    }
  },
  "ga-IE": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institiúid",
          "country": {
            "label": "Tír Suíochán an Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Úinéir an chuntais"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institiúid an íocaí",
          "country": {
            "label": "Tír Suíochán an Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Úinéir an chuntais"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Úsáid"
      },
      "status": {
        "paid": {
          "label": "íoctha"
        },
        "label": "Stádas",
        "unpaid": {
          "label": "Ar feitheamh"
        },
        "edit": {
          "buttonLabel": "Cuir sonraí íocaíochta in eagar"
        },
        "set": {
          "buttonLabel": "Cuir sonraí íocaíochta in eagar"
        }
      },
      "amount": {
        "label": "Méid a d'íoc"
      },
      "information": {
        "label": "Faisnéis íocaíochta"
      },
      "endDueTime": {
        "label": "Deireadh na tréimhse íocaíochta"
      },
      "instrument": {
        "label": "Lonstraim íocaíochta"
      },
      "reference": {
        "label": "Tagairt íocaíochta"
      },
      "startDueTime": {
        "label": "Tús na tréimhse íocaíochta"
      },
      "details": {
        "label": "Sonraí íocaíochta"
      }
    },
    "VATRate": {
      "label": "Ráta CBL"
    },
    "overview": {
      "label": "Forbhreathnú"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF cruthaithe ar"
        }
      },
      "number": {
        "label": "Uimhir an doiciméid"
      }
    },
    "agent": {
      "number": {
        "label": "Uimhir an chustaiméara"
      },
      "company": {
        "TIN": {
          "label": "Uimhir chánach"
        }
      },
      "VATIN": {
        "label": "Uimhir aitheantais CBL"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Tharla earráid inár seirbhís. Arís, le d'thoil.",
        "title": "Earráid"
      }
    },
    "downloads": {
      "label": "Íoslódálacha",
      "downloadPDF": {
        "buttonLabel": "Íoslódáil PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Achoimre ar na sonraí tuairiscithe"
        }
      }
    },
    "details": {
      "label": "Sonraí"
    },
    "reportingPeriod": {
      "label": "Tréimhse tuairiscithe",
      "startDate": {
        "label": "Dáta tosaigh tuairiscithe"
      },
      "endDate": {
        "label": "Dáta deiridh tuairiscithe"
      }
    },
    "credit": {
      "label": "Creidmheas",
      "amount": {
        "label": "Creidmheas"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Soláthairtí laistigh den phobal, Iomlán"
      },
      "transfer": {
        "label": "Aistriú laistigh den phobal"
      },
      "net": {
        "label": "Seachadtaí saor ó cháin"
      }
    },
    "parkingRate": {
      "label": "Ráta cánach"
    },
    "sRR": {
      "label": "Ráta cánach"
    },
    "taxFreeSales": {
      "label": "Díolacháin saor ó cháin"
    },
    "standardRate": {
      "label": "Ráta cánach"
    },
    "authority": {
      "label": "Údarás"
    },
    "references": {
      "order": {
        "id": {
          "label": "Ordú Id"
        }
      }
    },
    "outputVAT": {
      "label": "Cáin bhreisluacha"
    },
    "export": {
      "total": {
        "label": "Onnmhairiú"
      },
      "label": "Onnmhairiú"
    },
    "totalPaymentDue": {
      "label": "Méid íocaíochta"
    },
    "interest": {
      "amount": {
        "label": "Méid úis"
      },
      "label": "leas"
    },
    "order": {
      "date": {
        "label": "Dáta ordaithe"
      }
    },
    "net": {
      "label": "Glan-dhíolacháin"
    },
    "report": {
      "type": {
        "label": "Cineál Tuairisc"
      },
      "label": "Tuarascáil"
    },
    "reverseCharge": {
      "net": {
        "label": "Glanioncam le haghaidh muirear droim ar ais (Reverse charge)"
      },
      "label": "Dliteanas cánach a aisiompú (Reverse charge)"
    },
    "reducedRate": {
      "label": "Ráta cánach"
    },
    "taxableSales": {
      "label": "Díolacháin inchánach"
    },
    "iCAOG": {
      "label": "Éadálacha laistigh den Chomhphobal",
      "total": {
        "net": {
          "label": "Suim na n-éadálacha laistigh den phobal"
        }
      }
    },
    "deductibleTax": {
      "label": "Cáin ionchuir inasbhainte"
    },
    "inputVAT": {
      "amount": {
        "label": "Cáin ionchuir ó shonraisc"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Cáin díolacháin ar allmhairí"
      }
    }
  },
  "ja-JP": {
    "payment": {
      "agent": {
        "bank": {
          "label": "研究所",
          "country": {
            "label": "研究所の座席の国"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "アカウントの所有者"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "受取人の研究所",
          "country": {
            "label": "研究所の座席の国"
          }
        },
        "account": {
          "owner": {
            "label": "アカウントの所有者"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "使用法"
      },
      "status": {
        "paid": {
          "label": "支払った"
        },
        "label": "状態",
        "unpaid": {
          "label": "未確定"
        },
        "edit": {
          "buttonLabel": "お支払いの詳細を編集"
        },
        "set": {
          "buttonLabel": "お支払いの詳細を編集"
        }
      },
      "amount": {
        "label": "払込金額"
      },
      "information": {
        "label": "支払情報"
      },
      "endDueTime": {
        "label": "支払い期間の終了"
      },
      "instrument": {
        "label": "支払い方法"
      },
      "reference": {
        "label": "支払いリファレンス"
      },
      "startDueTime": {
        "label": "支払い期間の開始"
      },
      "details": {
        "label": "支払詳細"
      }
    },
    "VATRate": {
      "label": "VAT率"
    },
    "overview": {
      "label": "概観"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "で作成されたPDF"
        }
      },
      "number": {
        "label": "書類番号"
      }
    },
    "agent": {
      "number": {
        "label": "顧客番号"
      },
      "company": {
        "TIN": {
          "label": "税番号"
        }
      },
      "VATIN": {
        "label": "VAT識別番号"
      }
    },
    "notification": {
      "asyncError": {
        "message": "サービスでエラーが発生しました。 もう一度お試しください。",
        "title": "エラー"
      }
    },
    "downloads": {
      "label": "ファイルのダウンロード",
      "downloadPDF": {
        "buttonLabel": "PDFをダウンロード"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "レポートデータの要約"
        }
      }
    },
    "details": {
      "label": "細部"
    },
    "reportingPeriod": {
      "label": "報告期間",
      "startDate": {
        "label": "レポート開始日"
      },
      "endDate": {
        "label": "レポート終了日"
      }
    },
    "credit": {
      "label": "クレジット",
      "amount": {
        "label": "クレジット"
      }
    },
    "iCSOG": {
      "total": {
        "label": "コミュニティ内供給、 合計"
      },
      "transfer": {
        "label": "コミュニティ内転送"
      },
      "net": {
        "label": "免税配送"
      }
    },
    "parkingRate": {
      "label": "税率"
    },
    "sRR": {
      "label": "税率"
    },
    "taxFreeSales": {
      "label": "免税販売"
    },
    "standardRate": {
      "label": "税率"
    },
    "authority": {
      "label": "権限"
    },
    "references": {
      "order": {
        "id": {
          "label": "注文ID"
        }
      }
    },
    "outputVAT": {
      "label": "付加価値税"
    },
    "export": {
      "total": {
        "label": "書き出す"
      },
      "label": "書き出す"
    },
    "totalPaymentDue": {
      "label": "支払金額"
    },
    "interest": {
      "amount": {
        "label": "利息額"
      },
      "label": "興味"
    },
    "order": {
      "date": {
        "label": "注文日"
      }
    },
    "net": {
      "label": "純売上高"
    },
    "report": {
      "type": {
        "label": "レポートタイプ"
      },
      "label": "報告する"
    },
    "reverseCharge": {
      "net": {
        "label": "リバースチャージの純収益 (Reverse charge)"
      },
      "label": "納税義務の反転 (Reverse charge)"
    },
    "reducedRate": {
      "label": "税率"
    },
    "taxableSales": {
      "label": "課税売上"
    },
    "iCAOG": {
      "label": "コミュニティ内の買収",
      "total": {
        "net": {
          "label": "コミュニティ内買収の合計"
        }
      }
    },
    "deductibleTax": {
      "label": "控除可能な仮払消費税"
    },
    "inputVAT": {
      "amount": {
        "label": "請求書からの仮払消費税"
      }
    },
    "importVAT": {
      "amount": {
        "label": "VATのインポート"
      }
    }
  },
  "hr-HR": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Zemlja sjedišta Instituta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut primatelja",
          "country": {
            "label": "Zemlja sjedišta Instituta"
          }
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Upotreba"
      },
      "status": {
        "paid": {
          "label": "plaćen"
        },
        "label": "Status",
        "unpaid": {
          "label": "Neriješen"
        },
        "edit": {
          "buttonLabel": "Uređivanje podataka o plaćanju"
        },
        "set": {
          "buttonLabel": "Uređivanje podataka o plaćanju"
        }
      },
      "amount": {
        "label": "Uplaćeni iznos"
      },
      "information": {
        "label": "Informacije o plaćanju"
      },
      "endDueTime": {
        "label": "Kraj razdoblja plaćanja"
      },
      "instrument": {
        "label": "Instrument plaćanja"
      },
      "reference": {
        "label": "Referenca o plaćanju"
      },
      "startDueTime": {
        "label": "Početak razdoblja plaćanja"
      },
      "details": {
        "label": "Pojedinosti o plaćanju"
      }
    },
    "VATRate": {
      "label": "Stopa PDV-a"
    },
    "overview": {
      "label": "Pregled"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF stvoren dana"
        }
      },
      "number": {
        "label": "Broj dokumenta"
      }
    },
    "agent": {
      "number": {
        "label": "Broj kupca"
      },
      "company": {
        "TIN": {
          "label": "Porezni broj"
        }
      },
      "VATIN": {
        "label": "PDV identifikacijski broj"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Došlo je do pogreške u našoj usluzi. Molim vas pokušajte ponovno.",
        "title": "Pogreška"
      }
    },
    "downloads": {
      "label": "Preuzimanja",
      "downloadPDF": {
        "buttonLabel": "Preuzmite PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Sažetak izvještajnih podataka"
        }
      }
    },
    "details": {
      "label": "Detalji"
    },
    "reportingPeriod": {
      "label": "Izvještajno razdoblje",
      "startDate": {
        "label": "Datum početka izvještavanja"
      },
      "endDate": {
        "label": "Krajnji datum izvještavanja"
      }
    },
    "credit": {
      "label": "Kreditna",
      "amount": {
        "label": "Kreditna"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Opskrba unutar zajednice, ukupno"
      },
      "transfer": {
        "label": "Prijenos unutar zajednice"
      },
      "net": {
        "label": "Dostava bez poreza"
      }
    },
    "parkingRate": {
      "label": "Porezna stopa"
    },
    "sRR": {
      "label": "Porezna stopa"
    },
    "taxFreeSales": {
      "label": "Prodaja bez poreza"
    },
    "standardRate": {
      "label": "Porezna stopa"
    },
    "authority": {
      "label": "Vlast"
    },
    "references": {
      "order": {
        "id": {
          "label": "Id narudžbe"
        }
      }
    },
    "outputVAT": {
      "label": "Porez na dodanu vrijednost"
    },
    "export": {
      "total": {
        "label": "Izvoz"
      },
      "label": "Izvoz"
    },
    "totalPaymentDue": {
      "label": "Iznos plaćanja"
    },
    "interest": {
      "amount": {
        "label": "Iznos kamate"
      },
      "label": "Interes"
    },
    "order": {
      "date": {
        "label": "Datum narudžbe"
      }
    },
    "net": {
      "label": "Neto prodaja"
    },
    "report": {
      "type": {
        "label": "Vrsta Izvješća"
      },
      "label": "Izvješće"
    },
    "reverseCharge": {
      "net": {
        "label": "Neto prihod za obrnuto naplaćivanje (Reverse charge)"
      },
      "label": "Ukidanje porezne obveze (Reverse charge)"
    },
    "reducedRate": {
      "label": "Porezna stopa"
    },
    "taxableSales": {
      "label": "Oporeziva prodaja"
    },
    "iCAOG": {
      "label": "Akvizicije unutar Zajednice",
      "total": {
        "net": {
          "label": "Zbir akvizicija unutar zajednice"
        }
      }
    },
    "deductibleTax": {
      "label": "Odbitni pretporez"
    },
    "inputVAT": {
      "amount": {
        "label": "Ulazni porez iz računa"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Porez na promet"
      }
    }
  },
  "lv-LV": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institūts",
          "country": {
            "label": "Institūta galvenā mītnes valsts"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Konta īpašnieks"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Maksājuma saņēmēja institūts",
          "country": {
            "label": "Institūta galvenā mītnes valsts"
          }
        },
        "account": {
          "owner": {
            "label": "Konta īpašnieks"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Lietošana"
      },
      "status": {
        "paid": {
          "label": "samaksāts"
        },
        "label": "Statusu",
        "unpaid": {
          "label": "Gaida"
        },
        "edit": {
          "buttonLabel": "Rediģēt maksājuma informāciju"
        },
        "set": {
          "buttonLabel": "Rediģēt maksājuma informāciju"
        }
      },
      "amount": {
        "label": "Samaksātā summa"
      },
      "information": {
        "label": "Informācija par maksājumu"
      },
      "endDueTime": {
        "label": "Maksājuma termiņa beigas"
      },
      "instrument": {
        "label": "Maksāšanas līdzeklis"
      },
      "reference": {
        "label": "Maksājuma atsauce"
      },
      "startDueTime": {
        "label": "Maksājuma perioda sākums"
      },
      "details": {
        "label": "Maksājumu informācija"
      }
    },
    "VATRate": {
      "label": "PVN likme"
    },
    "overview": {
      "label": "Pārskats"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF izveidots"
        }
      },
      "number": {
        "label": "Dokumenta numurs"
      }
    },
    "agent": {
      "number": {
        "label": "Klienta numurs"
      },
      "company": {
        "TIN": {
          "label": "nodokļu numurs"
        }
      },
      "VATIN": {
        "label": "PVN identifikācijas numurs"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Mūsu pakalpojumā ir radusies kļūda. Lūdzu, mēģiniet vēlreiz.",
        "title": "Kļūda"
      }
    },
    "downloads": {
      "label": "Lejupielādes",
      "downloadPDF": {
        "buttonLabel": "Lejupielādējiet PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Pārskatu datu kopsavilkums"
        }
      }
    },
    "details": {
      "label": "Sīkāka informācija"
    },
    "reportingPeriod": {
      "label": "Pārskata periods",
      "startDate": {
        "label": "Ziņošanas sākuma datums"
      },
      "endDate": {
        "label": "Pārskata beigu datums"
      }
    },
    "credit": {
      "label": "Kredīts",
      "amount": {
        "label": "Kredīts"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Krājumi Kopienas iekšienē, Kopā"
      },
      "transfer": {
        "label": "Pārvietošana Kopienas iekšienē"
      },
      "net": {
        "label": "Piegādes bez nodokļiem"
      }
    },
    "parkingRate": {
      "label": "Nodokļa likme"
    },
    "sRR": {
      "label": "Nodokļa likme"
    },
    "taxFreeSales": {
      "label": "Beznodokļu pārdošana"
    },
    "standardRate": {
      "label": "Nodokļa likme"
    },
    "authority": {
      "label": "Autoritāte"
    },
    "references": {
      "order": {
        "id": {
          "label": "Pasūtījuma ID"
        }
      }
    },
    "outputVAT": {
      "label": "Pievienotās vērtības nodoklis"
    },
    "export": {
      "total": {
        "label": "Eksportēt"
      },
      "label": "Eksportēt"
    },
    "totalPaymentDue": {
      "label": "Maksājuma summa"
    },
    "interest": {
      "amount": {
        "label": "Procentu summa"
      },
      "label": "Interese"
    },
    "order": {
      "date": {
        "label": "Pasūtījuma datums"
      }
    },
    "net": {
      "label": "Neto apgrozījums"
    },
    "report": {
      "type": {
        "label": "Pārskata Veids"
      },
      "label": "Ziņot"
    },
    "reverseCharge": {
      "net": {
        "label": "Neto ieņēmumi par apgriezto maksāšanu (Reverse charge)"
      },
      "label": "Nodokļu saistības atcelšana (Reverse charge)"
    },
    "reducedRate": {
      "label": "Nodokļa likme"
    },
    "taxableSales": {
      "label": "Ar nodokli apliekama pārdošana"
    },
    "iCAOG": {
      "label": "Legāde Kopienas iekšienē",
      "total": {
        "net": {
          "label": "Kopienas iekšējo iegāžu summa"
        }
      }
    },
    "deductibleTax": {
      "label": "Atskaitāms priekšnodoklis"
    },
    "inputVAT": {
      "amount": {
        "label": "Priekšnodoklis no rēķiniem"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Importa PVN"
      }
    }
  },
  "lt-LT": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institutas",
          "country": {
            "label": "Instituto buveinės šalis"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Paskyros savininkas"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Gavėjo institutas",
          "country": {
            "label": "Instituto buveinės šalis"
          }
        },
        "account": {
          "owner": {
            "label": "Paskyros savininkas"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Naudojimas"
      },
      "status": {
        "paid": {
          "label": "mokama"
        },
        "label": "Statusas",
        "unpaid": {
          "label": "Kol"
        },
        "edit": {
          "buttonLabel": "Redaguoti mokėjimo informaciją"
        },
        "set": {
          "buttonLabel": "Redaguoti mokėjimo informaciją"
        }
      },
      "amount": {
        "label": "Sumokėta suma"
      },
      "information": {
        "label": "Mokėjimo informacija"
      },
      "endDueTime": {
        "label": "Mokėjimo laikotarpio pabaiga"
      },
      "instrument": {
        "label": "Mokėjimo priemonė"
      },
      "reference": {
        "label": "Mokėjimo tvarka"
      },
      "startDueTime": {
        "label": "Mokėjimo laikotarpio pradžia"
      },
      "details": {
        "label": "Mokėjimo detalės"
      }
    },
    "VATRate": {
      "label": "PVM tarifas"
    },
    "overview": {
      "label": "Apžvalga"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF sukurta"
        }
      },
      "number": {
        "label": "Dokumento numeris"
      }
    },
    "agent": {
      "number": {
        "label": "Kliento numeris"
      },
      "company": {
        "TIN": {
          "label": "Mokesčių numeris"
        }
      },
      "VATIN": {
        "label": "PVM mokėtojo kodas"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Mūsų tarnyboje įvyko klaida. Bandykite dar kartą.",
        "title": "Klaida"
      }
    },
    "downloads": {
      "label": "Atsisiuntimai",
      "downloadPDF": {
        "buttonLabel": "Atsisiųsti PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Ataskaitų duomenų santrauka"
        }
      }
    },
    "details": {
      "label": "Išsami informacija"
    },
    "reportingPeriod": {
      "label": "Ataskaitinis laikotarpis",
      "startDate": {
        "label": "Ataskaitų teikimo pradžios data"
      },
      "endDate": {
        "label": "Ataskaitos pabaigos data"
      }
    },
    "credit": {
      "label": "Kreditas",
      "amount": {
        "label": "Kreditas"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Atsargos bendruomenės viduje, viso"
      },
      "transfer": {
        "label": "Perkėlimas bendruomenės viduje"
      },
      "net": {
        "label": "Nemokamas pristatymas"
      }
    },
    "parkingRate": {
      "label": "Mokesčio tarifas"
    },
    "sRR": {
      "label": "Mokesčio tarifas"
    },
    "taxFreeSales": {
      "label": "Neapmokestinami pardavimai"
    },
    "standardRate": {
      "label": "Mokesčio tarifas"
    },
    "authority": {
      "label": "Autoritetas"
    },
    "references": {
      "order": {
        "id": {
          "label": "Užsakymo ID"
        }
      }
    },
    "outputVAT": {
      "label": "Pridėtinės vertės mokestis"
    },
    "export": {
      "total": {
        "label": "Eksportuoti"
      },
      "label": "Eksportuoti"
    },
    "totalPaymentDue": {
      "label": "Mokėjimo suma"
    },
    "interest": {
      "amount": {
        "label": "Palūkanų suma"
      },
      "label": "Palūkanų"
    },
    "order": {
      "date": {
        "label": "Užsakymo data"
      }
    },
    "net": {
      "label": "Grynieji pardavimai"
    },
    "report": {
      "type": {
        "label": "Ataskaitos Tipas"
      },
      "label": "Ataskaita"
    },
    "reverseCharge": {
      "net": {
        "label": "Grynosios pajamos už atvirkštinį apmokestinimą (Reverse charge)"
      },
      "label": "Mokestinės prievolės panaikinimas (Reverse charge)"
    },
    "reducedRate": {
      "label": "Mokesčio tarifas"
    },
    "taxableSales": {
      "label": "Apmokestinamas pardavimas"
    },
    "iCAOG": {
      "label": "Įsigijimai Bendrijos viduje",
      "total": {
        "net": {
          "label": "Bendrovės vidaus įsigijimų suma"
        }
      }
    },
    "deductibleTax": {
      "label": "Atskaitytinas pirkimo mokestis"
    },
    "inputVAT": {
      "amount": {
        "label": "Pirkimo mokestis iš sąskaitų faktūrų"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Importo pardavimo mokestis"
      }
    }
  },
  "pt-PT": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Instituto",
          "country": {
            "label": "País de Sede do Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Dono da conta"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Instituto do beneficiário",
          "country": {
            "label": "País de Sede do Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Dono da conta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Uso"
      },
      "status": {
        "paid": {
          "label": "pago"
        },
        "label": "Status",
        "unpaid": {
          "label": "Pendente"
        },
        "edit": {
          "buttonLabel": "Editar detalhes do pagamento"
        },
        "set": {
          "buttonLabel": "Editar detalhes do pagamento"
        }
      },
      "amount": {
        "label": "Quantia paga"
      },
      "information": {
        "label": "Informação de pagamento"
      },
      "endDueTime": {
        "label": "Fim do período de pagamento"
      },
      "instrument": {
        "label": "Instrumento de pagamento"
      },
      "reference": {
        "label": "Referência de pagamento"
      },
      "startDueTime": {
        "label": "Início do período de pagamento"
      },
      "details": {
        "label": "Detalhes do pagamento"
      }
    },
    "VATRate": {
      "label": "taxa do IVA"
    },
    "overview": {
      "label": "Visão geral"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF criado em"
        }
      },
      "number": {
        "label": "Número do documento"
      }
    },
    "agent": {
      "number": {
        "label": "Número do cliente"
      },
      "company": {
        "TIN": {
          "label": "número de identificação fiscal"
        }
      },
      "VATIN": {
        "label": "Número de identificação VAT"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Ocorreu um erro no nosso serviço. Tente novamente.",
        "title": "Erro"
      }
    },
    "downloads": {
      "label": "Transferências",
      "downloadPDF": {
        "buttonLabel": "Baixar PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Resumo dos dados de relatório"
        }
      }
    },
    "details": {
      "label": "Detalhes"
    },
    "reportingPeriod": {
      "label": "Período coberto pelo relatório",
      "startDate": {
        "label": "Data de início do relatório"
      },
      "endDate": {
        "label": "Data de término do relatório"
      }
    },
    "credit": {
      "label": "Crédito",
      "amount": {
        "label": "Crédito"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Suprimentos intracomunitários, total"
      },
      "transfer": {
        "label": "Transferência intracomunitária"
      },
      "net": {
        "label": "Entregas isentas de impostos"
      }
    },
    "parkingRate": {
      "label": "Taxa de imposto"
    },
    "sRR": {
      "label": "Taxa de imposto"
    },
    "taxFreeSales": {
      "label": "Vendas isentas de impostos"
    },
    "standardRate": {
      "label": "Taxa de imposto"
    },
    "authority": {
      "label": "Autoridade"
    },
    "references": {
      "order": {
        "id": {
          "label": "Id do pedido"
        }
      }
    },
    "outputVAT": {
      "label": "Imposto sobre o Valor Acrescentado"
    },
    "export": {
      "total": {
        "label": "Exportar"
      },
      "label": "Exportar"
    },
    "totalPaymentDue": {
      "label": "Valor do pagamento"
    },
    "interest": {
      "amount": {
        "label": "Montante de juros"
      },
      "label": "Interesse"
    },
    "order": {
      "date": {
        "label": "Data do pedido"
      }
    },
    "net": {
      "label": "Vendas líquidas"
    },
    "report": {
      "type": {
        "label": "Tipo de Relatório"
      },
      "label": "Relatório"
    },
    "reverseCharge": {
      "net": {
        "label": "Receita líquida de cobrança reversa (Reverse charge)"
      },
      "label": "Reversão de passivo fiscal (Reverse charge)"
    },
    "reducedRate": {
      "label": "Taxa de imposto"
    },
    "taxableSales": {
      "label": "Vendas tributáveis"
    },
    "iCAOG": {
      "label": "Aquisições intracomunitárias",
      "total": {
        "net": {
          "label": "Soma das aquisições intracomunitárias"
        }
      }
    },
    "deductibleTax": {
      "label": "Insumos fiscais dedutíveis"
    },
    "inputVAT": {
      "amount": {
        "label": "Inserção de impostos de faturas"
      }
    },
    "importVAT": {
      "amount": {
        "label": "IVA de importação"
      }
    }
  },
  "ro-RO": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Țara de reședință a Institutului"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Proprietarul contului"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institutul beneficiarului",
          "country": {
            "label": "Țara de reședință a Institutului"
          }
        },
        "account": {
          "owner": {
            "label": "Proprietarul contului"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Utilizare"
      },
      "status": {
        "paid": {
          "label": "plătit"
        },
        "label": "Stare",
        "unpaid": {
          "label": "In asteptarea"
        },
        "edit": {
          "buttonLabel": "Editați detaliile de plată"
        },
        "set": {
          "buttonLabel": "Editați detaliile de plată"
        }
      },
      "amount": {
        "label": "Suma plătită"
      },
      "information": {
        "label": "Informatii de plata"
      },
      "endDueTime": {
        "label": "Sfârșitul perioadei de plată"
      },
      "instrument": {
        "label": "Instrument de plată"
      },
      "reference": {
        "label": "Referință de plată"
      },
      "startDueTime": {
        "label": "Începutul perioadei de plată"
      },
      "details": {
        "label": "Detaliile platii"
      }
    },
    "VATRate": {
      "label": "cota TVA"
    },
    "overview": {
      "label": "Prezentare generală"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF creat pe"
        }
      },
      "number": {
        "label": "Numarul documentului"
      }
    },
    "agent": {
      "number": {
        "label": "Numarul clientului"
      },
      "company": {
        "TIN": {
          "label": "cod fiscal"
        }
      },
      "VATIN": {
        "label": "Toate numerele de identificare a impozitului pe vânzări"
      }
    },
    "notification": {
      "asyncError": {
        "message": "A apărut o eroare în serviciu. Încercați din nou.",
        "title": "Eroare"
      }
    },
    "downloads": {
      "label": "Descărcări",
      "downloadPDF": {
        "buttonLabel": "Descarcă PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Rezumatul datelor de raportare"
        }
      }
    },
    "details": {
      "label": "Detalii"
    },
    "reportingPeriod": {
      "label": "Perioadă de raportare",
      "startDate": {
        "label": "Data începerii raportării"
      },
      "endDate": {
        "label": "Data de încheiere a raportării"
      }
    },
    "credit": {
      "label": "Credit",
      "amount": {
        "label": "Credit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Furnizare intracomunitară, total"
      },
      "transfer": {
        "label": "Transferul intra-comunitar"
      },
      "net": {
        "label": "Livrări fără taxe"
      }
    },
    "parkingRate": {
      "label": "Cota de impozitare"
    },
    "sRR": {
      "label": "Cota de impozitare"
    },
    "taxFreeSales": {
      "label": "Vânzări fără taxe"
    },
    "standardRate": {
      "label": "Cota de impozitare"
    },
    "authority": {
      "label": "Autoritate"
    },
    "references": {
      "order": {
        "id": {
          "label": "Comanda ID"
        }
      }
    },
    "outputVAT": {
      "label": "Taxa pe valoare adaugata"
    },
    "export": {
      "total": {
        "label": "Export"
      },
      "label": "Export"
    },
    "totalPaymentDue": {
      "label": "Suma de plată"
    },
    "interest": {
      "amount": {
        "label": "Suma dobânzii"
      },
      "label": "Interes"
    },
    "order": {
      "date": {
        "label": "Data comandă"
      }
    },
    "net": {
      "label": "Vânzări nete"
    },
    "report": {
      "type": {
        "label": "Tip de Raport"
      },
      "label": "Raport"
    },
    "reverseCharge": {
      "net": {
        "label": "Venit net pentru taxa inversă (Reverse charge)"
      },
      "label": "Inversarea obligației fiscale (Reverse charge)"
    },
    "reducedRate": {
      "label": "Cota de impozitare"
    },
    "taxableSales": {
      "label": "Vânzări impozabile"
    },
    "iCAOG": {
      "label": "Achiziții intracomunitare",
      "total": {
        "net": {
          "label": "Suma achizițiilor intracomunitare"
        }
      }
    },
    "deductibleTax": {
      "label": "Taxă de intrare deductibilă"
    },
    "inputVAT": {
      "amount": {
        "label": "Taxa de intrare din facturi"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Import TVA"
      }
    }
  },
  "ru-RU": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Институт",
          "country": {
            "label": "Страна местонахождения института"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "владелец счета"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Институт получателя платежей",
          "country": {
            "label": "Страна местонахождения института"
          }
        },
        "account": {
          "owner": {
            "label": "владелец счета"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "использование"
      },
      "status": {
        "paid": {
          "label": "оплаченный"
        },
        "label": "положение дел",
        "unpaid": {
          "label": "В ожидании"
        },
        "edit": {
          "buttonLabel": "Изменить платежные реквизиты"
        },
        "set": {
          "buttonLabel": "Изменить платежные реквизиты"
        }
      },
      "amount": {
        "label": "Оплаченная сумма"
      },
      "information": {
        "label": "Платежная информация"
      },
      "endDueTime": {
        "label": "Конец платежного периода"
      },
      "instrument": {
        "label": "Платежный инструмент"
      },
      "reference": {
        "label": "Ссылка на платеж"
      },
      "startDueTime": {
        "label": "Начало платежного периода"
      },
      "details": {
        "label": "Детали оплаты"
      }
    },
    "VATRate": {
      "label": "Ставка НДС"
    },
    "overview": {
      "label": "обзор"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF создан на"
        }
      },
      "number": {
        "label": "Номер счёта"
      }
    },
    "agent": {
      "number": {
        "label": "Номер клиента"
      },
      "company": {
        "TIN": {
          "label": "Идентификационный номер налогоплательщика"
        }
      },
      "VATIN": {
        "label": "Идентификационный номер плательщика НДС"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Произошла ошибка в нашем сервисе. Пожалуйста, попробуйте еще раз.",
        "title": "Ошибка"
      }
    },
    "downloads": {
      "label": "Загрузки",
      "downloadPDF": {
        "buttonLabel": "Скачать PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Резюме отчетных данных"
        }
      }
    },
    "details": {
      "label": "подробности"
    },
    "reportingPeriod": {
      "label": "Отчетный период",
      "startDate": {
        "label": "Дата начала периода отчётности"
      },
      "endDate": {
        "label": "Дата окончания периода отчётности"
      }
    },
    "credit": {
      "label": "Положительное сальдо",
      "amount": {
        "label": "Положительное сальдо"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Внутриобщественные поставки, общее количество"
      },
      "transfer": {
        "label": "Передача внутри сообщества"
      },
      "net": {
        "label": "Безналоговые поставки"
      }
    },
    "parkingRate": {
      "label": "Ставка налога"
    },
    "sRR": {
      "label": "Ставка налога"
    },
    "taxFreeSales": {
      "label": "Сделки не облагаемые налогом"
    },
    "standardRate": {
      "label": "Ставка налога"
    },
    "authority": {
      "label": "учреждение"
    },
    "references": {
      "order": {
        "id": {
          "label": "Номер заказа"
        }
      }
    },
    "outputVAT": {
      "label": "налог на добавленную стоимость"
    },
    "export": {
      "total": {
        "label": "экспорт"
      },
      "label": "экспорт"
    },
    "totalPaymentDue": {
      "label": "Сумма к оплате"
    },
    "interest": {
      "amount": {
        "label": "Сумма процентов"
      },
      "label": "Интерес"
    },
    "order": {
      "date": {
        "label": "Дата предоставления данных"
      }
    },
    "net": {
      "label": "Чистые продажи"
    },
    "report": {
      "type": {
        "label": "Вид отчёта"
      },
      "label": "Oтчет"
    },
    "reverseCharge": {
      "net": {
        "label": "Чистый доход за обратный платеж (Reverse charge)"
      },
      "label": "восстановление налогового обязательства (Reverse charge)"
    },
    "reducedRate": {
      "label": "Ставка налога"
    },
    "taxableSales": {
      "label": "Сделки, облагаемые налогом"
    },
    "iCAOG": {
      "label": "приобретение в пределах сообщества (ЕС)",
      "total": {
        "net": {
          "label": "Сумма приобретений внутри сообщества"
        }
      }
    },
    "deductibleTax": {
      "label": "вычитаемый предварительный налог"
    },
    "inputVAT": {
      "amount": {
        "label": "Предварительный налог из счетов-фактур"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Налог с продаж на импорт"
      }
    }
  },
  "sv-SE": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Inleda",
          "country": {
            "label": "Institutets säte"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Konto ägare"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Betalningsmottagarens institut",
          "country": {
            "label": "Institutets säte"
          }
        },
        "account": {
          "owner": {
            "label": "Konto ägare"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Användande"
      },
      "status": {
        "paid": {
          "label": "betald"
        },
        "label": "Status",
        "unpaid": {
          "label": "I väntan på"
        },
        "edit": {
          "buttonLabel": "Redigera betalningsinformation"
        },
        "set": {
          "buttonLabel": "Redigera betalningsinformation"
        }
      },
      "amount": {
        "label": "Betalt belopp"
      },
      "information": {
        "label": "Information om betalning"
      },
      "endDueTime": {
        "label": "Betalningsperiodens slut"
      },
      "instrument": {
        "label": "Betalningsinstrument"
      },
      "reference": {
        "label": "Betalningsreferens"
      },
      "startDueTime": {
        "label": "Början på betalningsperioden"
      },
      "details": {
        "label": "Betalningsinformation"
      }
    },
    "VATRate": {
      "label": "Skattesats"
    },
    "overview": {
      "label": "Översikt"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF skapad den"
        }
      },
      "number": {
        "label": "Dokumentnummer"
      }
    },
    "agent": {
      "number": {
        "label": "Kundnummer"
      },
      "company": {
        "TIN": {
          "label": "skattenummer"
        }
      },
      "VATIN": {
        "label": "Momsnummer"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Ett fel har uppstått i vår tjänst. Var god försök igen.",
        "title": "Fel"
      }
    },
    "downloads": {
      "label": "Nedladdningar",
      "downloadPDF": {
        "buttonLabel": "Ladda ner PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Sammanfattning av rapporteringsdata"
        }
      }
    },
    "details": {
      "label": "Detaljer"
    },
    "reportingPeriod": {
      "label": "Rapporteringsperiod",
      "startDate": {
        "label": "Rapporteringsstartdatum"
      },
      "endDate": {
        "label": "Rapporteringens slutdatum"
      }
    },
    "credit": {
      "label": "Kreditera",
      "amount": {
        "label": "Kreditera"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Tillbehör inom gemenskapen, total"
      },
      "transfer": {
        "label": "Överföring inom gemenskapen"
      },
      "net": {
        "label": "Skattefria leveranser"
      }
    },
    "parkingRate": {
      "label": "Skattenivå"
    },
    "sRR": {
      "label": "Skattenivå"
    },
    "taxFreeSales": {
      "label": "Skattefri försäljning"
    },
    "standardRate": {
      "label": "Skattenivå"
    },
    "authority": {
      "label": "Auktoritet"
    },
    "references": {
      "order": {
        "id": {
          "label": "Beställnings-id"
        }
      }
    },
    "outputVAT": {
      "label": "Mervärdesskatt"
    },
    "export": {
      "total": {
        "label": "Exportera"
      },
      "label": "Exportera"
    },
    "totalPaymentDue": {
      "label": "Betalningsbelopp"
    },
    "interest": {
      "amount": {
        "label": "Räntebelopp"
      },
      "label": "Intressera"
    },
    "order": {
      "date": {
        "label": "Orderdatum"
      }
    },
    "net": {
      "label": "Nettointäkter"
    },
    "report": {
      "type": {
        "label": "Rapporttyp"
      },
      "label": "Rapportera"
    },
    "reverseCharge": {
      "net": {
        "label": "Nettoomsättning för omvänd avgift (Reverse charge)"
      },
      "label": "Återföring av skatteskuld (Reverse charge)"
    },
    "reducedRate": {
      "label": "Skattenivå"
    },
    "taxableSales": {
      "label": "Skattepliktig försäljning"
    },
    "iCAOG": {
      "label": "Förvärv inom gemenskapen",
      "total": {
        "net": {
          "label": "Summan av förvärv inom samhället"
        }
      }
    },
    "deductibleTax": {
      "label": "Avdragsgill ingående skatt"
    },
    "inputVAT": {
      "amount": {
        "label": "Ingående skatt från fakturor"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Importera moms"
      }
    }
  },
  "sk-SK": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Ústav",
          "country": {
            "label": "Krajina sídla inštitútu"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Vlastník účtu"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Inštitút príjemcu platby",
          "country": {
            "label": "Krajina sídla inštitútu"
          }
        },
        "account": {
          "owner": {
            "label": "Vlastník účtu"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Používanie"
      },
      "status": {
        "paid": {
          "label": "zaplatil"
        },
        "label": "Postavenie",
        "unpaid": {
          "label": "Nevykonaného"
        },
        "edit": {
          "buttonLabel": "Upravte podrobnosti platby"
        },
        "set": {
          "buttonLabel": "Upravte podrobnosti platby"
        }
      },
      "amount": {
        "label": "Čiastka vyplatená"
      },
      "information": {
        "label": "Informácie o platbe"
      },
      "endDueTime": {
        "label": "Koniec platobného obdobia"
      },
      "instrument": {
        "label": "Platobný nástroj"
      },
      "reference": {
        "label": "Označenie platby"
      },
      "startDueTime": {
        "label": "Začiatok platobného obdobia"
      },
      "details": {
        "label": "Platobné údaje"
      }
    },
    "VATRate": {
      "label": "Sadzba DPH"
    },
    "overview": {
      "label": "Prehľad"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF vytvorené dňa"
        }
      },
      "number": {
        "label": "Číslo dokumentu"
      }
    },
    "agent": {
      "number": {
        "label": "Zákaznícke číslo"
      },
      "company": {
        "TIN": {
          "label": "Daňové číslo"
        }
      },
      "VATIN": {
        "label": "IČ DPH"
      }
    },
    "notification": {
      "asyncError": {
        "message": "V našej službe sa vyskytla chyba. Prosím skúste znova.",
        "title": "Chyba"
      }
    },
    "downloads": {
      "label": "K stiahnutiu",
      "downloadPDF": {
        "buttonLabel": "Stiahnite si PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Súhrn vykazovaných údajov"
        }
      }
    },
    "details": {
      "label": "Podrobnosti"
    },
    "reportingPeriod": {
      "label": "Vykazované obdobie",
      "startDate": {
        "label": "Dátum začiatku vykazovania"
      },
      "endDate": {
        "label": "Dátum ukončenia prehľadu"
      }
    },
    "credit": {
      "label": "Úver",
      "amount": {
        "label": "Úver"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Dodávky v rámci Spoločenstva, Celkom"
      },
      "transfer": {
        "label": "Prenos v rámci Spoločenstva"
      },
      "net": {
        "label": "Dodávky bez dane"
      }
    },
    "parkingRate": {
      "label": "Sadzba dane"
    },
    "sRR": {
      "label": "Sadzba dane"
    },
    "taxFreeSales": {
      "label": "Predaj bez dane"
    },
    "standardRate": {
      "label": "Sadzba dane"
    },
    "authority": {
      "label": "Autorita"
    },
    "references": {
      "order": {
        "id": {
          "label": "Číslo objednávky"
        }
      }
    },
    "outputVAT": {
      "label": "Daň z pridanej hodnoty"
    },
    "export": {
      "total": {
        "label": "Export"
      },
      "label": "Export"
    },
    "totalPaymentDue": {
      "label": "Čiastka na úhradu"
    },
    "interest": {
      "amount": {
        "label": "Výška úroku"
      },
      "label": "Úrok"
    },
    "order": {
      "date": {
        "label": "Dátum objednávky"
      }
    },
    "net": {
      "label": "Čisté tržby"
    },
    "report": {
      "type": {
        "label": "Typ Hlásenia"
      },
      "label": "Správa"
    },
    "reverseCharge": {
      "net": {
        "label": "Čistý výnos z prenesenia daňovej povinnosti (Reverse charge)"
      },
      "label": "Zrušenie daňovej povinnosti (Reverse charge)"
    },
    "reducedRate": {
      "label": "Sadzba dane"
    },
    "taxableSales": {
      "label": "Zdaniteľný predaj"
    },
    "iCAOG": {
      "label": "Nadobudnutie v rámci Spoločenstva",
      "total": {
        "net": {
          "label": "Súčet akvizícií v rámci Spoločenstva"
        }
      }
    },
    "deductibleTax": {
      "label": "Odpočítateľná daň na vstupe"
    },
    "inputVAT": {
      "amount": {
        "label": "Daň na vstupe z faktúr"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Dovozná daň z obratu"
      }
    }
  },
  "cs-CZ": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Ústav",
          "country": {
            "label": "Země sídla institutu"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Majitel účtu"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut příjemce platby",
          "country": {
            "label": "Země sídla institutu"
          }
        },
        "account": {
          "owner": {
            "label": "Majitel účtu"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Používání"
      },
      "status": {
        "paid": {
          "label": "zaplaceno"
        },
        "label": "Postavení",
        "unpaid": {
          "label": "Čekající"
        },
        "edit": {
          "buttonLabel": "Upravit platební údaje"
        },
        "set": {
          "buttonLabel": "Upravit platební údaje"
        }
      },
      "amount": {
        "label": "Částka vyplacená"
      },
      "information": {
        "label": "Informace o platbě"
      },
      "endDueTime": {
        "label": "Konec platebního období"
      },
      "instrument": {
        "label": "Platební nástroj"
      },
      "reference": {
        "label": "Odkaz na platbu"
      },
      "startDueTime": {
        "label": "Začátek platebního období"
      },
      "details": {
        "label": "Platební údaje"
      }
    },
    "VATRate": {
      "label": "Sazba DPH"
    },
    "overview": {
      "label": "Přehled"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF vytvořeno dne"
        }
      },
      "number": {
        "label": "Číslo dokumentu"
      }
    },
    "agent": {
      "number": {
        "label": "Zákaznické číslo"
      },
      "company": {
        "TIN": {
          "label": "daňové číslo"
        }
      },
      "VATIN": {
        "label": "DIČ"
      }
    },
    "notification": {
      "asyncError": {
        "message": "V naší službě se vyskytla chyba. Zkuste to prosím znovu.",
        "title": "Chyba"
      }
    },
    "downloads": {
      "label": "Soubory ke stažení",
      "downloadPDF": {
        "buttonLabel": "Stáhnout PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Souhrn údajů z hlášení"
        }
      }
    },
    "details": {
      "label": "Podrobnosti"
    },
    "reportingPeriod": {
      "label": "Doba ohlášení",
      "startDate": {
        "label": "Datum zahájení hlášení"
      },
      "endDate": {
        "label": "Datum ukončení vykazování"
      }
    },
    "credit": {
      "label": "Kredit",
      "amount": {
        "label": "Kredit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Zásoby uvnitř Společenství, celkový"
      },
      "transfer": {
        "label": "Přenos v rámci Společenství"
      },
      "net": {
        "label": "Dodávky bez daně"
      }
    },
    "parkingRate": {
      "label": "Sazba daně"
    },
    "sRR": {
      "label": "Sazba daně"
    },
    "taxFreeSales": {
      "label": "Prodej bez daně"
    },
    "standardRate": {
      "label": "Sazba daně"
    },
    "authority": {
      "label": "Autoritet"
    },
    "references": {
      "order": {
        "id": {
          "label": "Číslo objednávky"
        }
      }
    },
    "outputVAT": {
      "label": "Daň z přidané hodnoty"
    },
    "export": {
      "total": {
        "label": "Vývozní"
      },
      "label": "Vývozní"
    },
    "totalPaymentDue": {
      "label": "Částka k úhradě"
    },
    "interest": {
      "amount": {
        "label": "Výše úroku"
      },
      "label": "Zájem"
    },
    "order": {
      "date": {
        "label": "Datum objednávky"
      }
    },
    "net": {
      "label": "Čisté tržby"
    },
    "report": {
      "type": {
        "label": "Typ Zprávy"
      },
      "label": "Zpráva"
    },
    "reverseCharge": {
      "net": {
        "label": "Čistý výnos z převodu (Reverse charge)"
      },
      "label": "Zrušení daňové povinnosti (Reverse charge)"
    },
    "reducedRate": {
      "label": "Sazba daně"
    },
    "taxableSales": {
      "label": "Zdanitelný prodej"
    },
    "iCAOG": {
      "label": "Akvizice uvnitř Společenství",
      "total": {
        "net": {
          "label": "Součet akvizic uvnitř Společenství"
        }
      }
    },
    "deductibleTax": {
      "label": "Odpočitatelná daň na vstupu"
    },
    "inputVAT": {
      "amount": {
        "label": "Daň na vstupu z faktur"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Dovozní DPH"
      }
    }
  },
  "tr-TR": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Enstitü",
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Alacaklı enstitüsü",
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          }
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Kullanım"
      },
      "status": {
        "paid": {
          "label": "ödenmiş"
        },
        "label": "Durum",
        "unpaid": {
          "label": "Askıda olan"
        },
        "edit": {
          "buttonLabel": "Ödeme ayrıntılarını düzenleme"
        },
        "set": {
          "buttonLabel": "Ödeme ayrıntılarını düzenleme"
        }
      },
      "amount": {
        "label": "Ödenen miktar"
      },
      "information": {
        "label": "Ödeme bilgileri"
      },
      "endDueTime": {
        "label": "Ödeme süresinin sonu"
      },
      "instrument": {
        "label": "Ödeme aracı"
      },
      "reference": {
        "label": "Ödeme referansı"
      },
      "startDueTime": {
        "label": "Ödeme döneminin başlangıcı"
      },
      "details": {
        "label": "Ödeme detayları"
      }
    },
    "VATRate": {
      "label": "KDV oranı"
    },
    "overview": {
      "label": "Genel bakış"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF tarihinde oluşturuldu"
        }
      },
      "number": {
        "label": "Belge Numarası"
      }
    },
    "agent": {
      "number": {
        "label": "Müşteri numarası"
      },
      "company": {
        "TIN": {
          "label": "Vergi numarası"
        }
      },
      "VATIN": {
        "label": "VAT kimlik numarası"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin.",
        "title": "Hata"
      }
    },
    "downloads": {
      "label": "İndirilenler",
      "downloadPDF": {
        "buttonLabel": "PDF İndir"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Raporlama verilerinin özeti"
        }
      }
    },
    "details": {
      "label": "Ayrıntılar"
    },
    "reportingPeriod": {
      "label": "Raporlama dönemi",
      "startDate": {
        "label": "Raporlama başlangıç tarihi"
      },
      "endDate": {
        "label": "Raporlama bitiş tarihi"
      }
    },
    "credit": {
      "label": "Kredi",
      "amount": {
        "label": "Kredi"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Topluluk içi malzemeler, Toplam"
      },
      "transfer": {
        "label": "Topluluk içi aktarım"
      },
      "net": {
        "label": "Vergisiz teslimatlar"
      }
    },
    "parkingRate": {
      "label": "Vergi oranı"
    },
    "sRR": {
      "label": "Vergi oranı"
    },
    "taxFreeSales": {
      "label": "Vergisiz satışlar"
    },
    "standardRate": {
      "label": "Vergi oranı"
    },
    "authority": {
      "label": "Yetki"
    },
    "references": {
      "order": {
        "id": {
          "label": "Sipariş Kimliği"
        }
      }
    },
    "outputVAT": {
      "label": "Katma değer vergisi"
    },
    "export": {
      "total": {
        "label": "Ihracat"
      },
      "label": "Ihracat"
    },
    "totalPaymentDue": {
      "label": "Ödeme miktarı"
    },
    "interest": {
      "amount": {
        "label": "Faiz tutarı"
      },
      "label": "Faiz"
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi"
      }
    },
    "net": {
      "label": "Net satış"
    },
    "report": {
      "type": {
        "label": "Rapor Türü"
      },
      "label": "Rapor"
    },
    "reverseCharge": {
      "net": {
        "label": "Ters ibraz için net gelir (Reverse charge)"
      },
      "label": "Vergi borcunun iptali (Reverse charge)"
    },
    "reducedRate": {
      "label": "Vergi oranı"
    },
    "taxableSales": {
      "label": "Vergiye tabi satışlar"
    },
    "iCAOG": {
      "label": "Topluluk içi edinimler",
      "total": {
        "net": {
          "label": "Topluluk içi satın almaların toplamı"
        }
      }
    },
    "deductibleTax": {
      "label": "İndirilebilir girdi vergisi"
    },
    "inputVAT": {
      "amount": {
        "label": "Faturalardan giriş vergisi"
      }
    },
    "importVAT": {
      "amount": {
        "label": "İthalat satış vergisi"
      }
    }
  },
  "hu-HU": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Intézet",
          "country": {
            "label": "Az intézet székhelye"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Számlatulajdonos"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Kedvezményezett intézmény",
          "country": {
            "label": "Az intézet székhelye"
          }
        },
        "account": {
          "owner": {
            "label": "Számlatulajdonos"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Használat"
      },
      "status": {
        "paid": {
          "label": "fizetett"
        },
        "label": "Állapot",
        "unpaid": {
          "label": "Függőben levő"
        },
        "edit": {
          "buttonLabel": "A fizetési adatok szerkesztése"
        },
        "set": {
          "buttonLabel": "A fizetési adatok szerkesztése"
        }
      },
      "amount": {
        "label": "Kifizetett összeg"
      },
      "information": {
        "label": "Fizetési információ"
      },
      "endDueTime": {
        "label": "A fizetési időszak vége"
      },
      "instrument": {
        "label": "Fizetés módja"
      },
      "reference": {
        "label": "Fizetési referencia"
      },
      "startDueTime": {
        "label": "A fizetési időszak kezdete"
      },
      "details": {
        "label": "Fizetési részletek"
      }
    },
    "VATRate": {
      "label": "Áfakulcs"
    },
    "overview": {
      "label": "Áttekintés"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF létrehozva"
        }
      },
      "number": {
        "label": "Dokumentum szám"
      }
    },
    "agent": {
      "number": {
        "label": "Ügyfélszám"
      },
      "company": {
        "TIN": {
          "label": "Adószám"
        }
      },
      "VATIN": {
        "label": "HÉA-azonosító szám"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Hiba történt a szolgáltatásunkban. Próbálja meg újra.",
        "title": "Hiba"
      }
    },
    "downloads": {
      "label": "Letöltések",
      "downloadPDF": {
        "buttonLabel": "Töltse le a PDF fájlt"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "A jelentési adatok összefoglalása"
        }
      }
    },
    "details": {
      "label": "Részletek"
    },
    "reportingPeriod": {
      "label": "Jelentési időszak",
      "startDate": {
        "label": "Jelentés kezdete"
      },
      "endDate": {
        "label": "Jelentés befejezési dátuma"
      }
    },
    "credit": {
      "label": "Hitel",
      "amount": {
        "label": "Hitel"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Közösségen belüli ellátás, teljes"
      },
      "transfer": {
        "label": "Közösségen belüli transzfer"
      },
      "net": {
        "label": "Adómentes szállítás"
      }
    },
    "parkingRate": {
      "label": "Adókulcs"
    },
    "sRR": {
      "label": "Adókulcs"
    },
    "taxFreeSales": {
      "label": "Adómentes értékesítés"
    },
    "standardRate": {
      "label": "Adókulcs"
    },
    "authority": {
      "label": "Állami hatóság"
    },
    "references": {
      "order": {
        "id": {
          "label": "Rendelés azonosító"
        }
      }
    },
    "outputVAT": {
      "label": "Áfa"
    },
    "export": {
      "total": {
        "label": "Export"
      },
      "label": "Export"
    },
    "totalPaymentDue": {
      "label": "Végösszeg"
    },
    "interest": {
      "amount": {
        "label": "Kamatösszeg"
      },
      "label": "Érdeklődés"
    },
    "order": {
      "date": {
        "label": "Rendelés dátuma"
      }
    },
    "net": {
      "label": "Nettó árbevétele"
    },
    "report": {
      "type": {
        "label": "Jelentés Típusa"
      },
      "label": "Jelentés"
    },
    "reverseCharge": {
      "net": {
        "label": "Nettó bevétel fordított adózás céljából (Reverse charge)"
      },
      "label": "Az adófizetési kötelezettség megfordítása (Reverse charge)"
    },
    "reducedRate": {
      "label": "Adókulcs"
    },
    "taxableSales": {
      "label": "Adóköteles értékesítés"
    },
    "iCAOG": {
      "label": "Közösségen belüli beszerzések",
      "total": {
        "net": {
          "label": "A közösségen belüli beszerzések összege"
        }
      }
    },
    "deductibleTax": {
      "label": "Levonható előzetes adó"
    },
    "inputVAT": {
      "amount": {
        "label": "Adószámla számlákból"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Import forgalmi adó"
      }
    }
  },
  "fr-BE": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut du bénéficiaire",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "But du transfert"
      },
      "status": {
        "paid": {
          "label": "payé"
        },
        "label": "Statut",
        "unpaid": {
          "label": "En attente"
        },
        "edit": {
          "buttonLabel": "Modifier les détails du paiement"
        },
        "set": {
          "buttonLabel": "Modifier les détails du paiement"
        }
      },
      "amount": {
        "label": "Le montant payé"
      },
      "information": {
        "label": "Informations de paiement"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "reference": {
        "label": "Référence de paiement"
      },
      "startDueTime": {
        "label": "Début de la période de paiement"
      },
      "details": {
        "label": "Détails de paiement"
      }
    },
    "VATRate": {
      "label": "Taux de TVA"
    },
    "overview": {
      "label": "Aperçu"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF créé le"
        }
      },
      "number": {
        "label": "Numéro de document"
      }
    },
    "agent": {
      "number": {
        "label": "Numéro de client"
      },
      "company": {
        "TIN": {
          "label": "Numéro d'identification fiscale"
        }
      },
      "VATIN": {
        "label": "Numéro d'identification TVA"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Une erreur s'est produite dans notre service. Veuillez réessayer.",
        "title": "Erreur"
      }
    },
    "downloads": {
      "label": "Téléchargements",
      "downloadPDF": {
        "buttonLabel": "Télécharger le PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Résumé des données de rapport"
        }
      }
    },
    "details": {
      "label": "Détails"
    },
    "reportingPeriod": {
      "label": "Période de rapport",
      "startDate": {
        "label": "Date de début du rapport"
      },
      "endDate": {
        "label": "Date de fin du rapport"
      }
    },
    "credit": {
      "label": "Crédit",
      "amount": {
        "label": "Crédit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Fournitures intracommunautaires, total"
      },
      "transfer": {
        "label": "Transfert intra-communautaire"
      },
      "net": {
        "label": "Livraisons hors taxes"
      }
    },
    "parkingRate": {
      "label": "Taux d'imposition"
    },
    "sRR": {
      "label": "Taux d'imposition"
    },
    "taxFreeSales": {
      "label": "Ventes hors taxes"
    },
    "standardRate": {
      "label": "Taux d'imposition"
    },
    "authority": {
      "label": "Autorité"
    },
    "references": {
      "order": {
        "id": {
          "label": "Numéro de commande"
        }
      }
    },
    "outputVAT": {
      "label": "Taxe sur la valeur ajoutée"
    },
    "export": {
      "total": {
        "label": "Exportation"
      },
      "label": "Exportation"
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    },
    "interest": {
      "amount": {
        "label": "Montant des intérêts"
      },
      "label": "Intérêt"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "net": {
      "label": "Ventes nettes"
    },
    "report": {
      "type": {
        "label": "Type de rapport"
      },
      "label": "Rapport"
    },
    "reverseCharge": {
      "net": {
        "label": "Recettes nettes pour l'autoliquidation (Reverse charge)"
      },
      "label": "Inversion de l'obligation fiscale (Reverse charge)"
    },
    "reducedRate": {
      "label": "Taux d'imposition"
    },
    "taxableSales": {
      "label": "Ventes taxables"
    },
    "iCAOG": {
      "label": "Acquisition intracommunautaire",
      "total": {
        "net": {
          "label": "Somme des acquisitions intracommunautaires"
        }
      }
    },
    "deductibleTax": {
      "label": "Taxe en amont déductible"
    },
    "inputVAT": {
      "amount": {
        "label": "Taxe déductible des factures"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Taxe de vente à l'importation"
      }
    }
  },
  "fr-LU": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Institut",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Institut du bénéficiaire",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "But du transfert"
      },
      "status": {
        "paid": {
          "label": "payé"
        },
        "label": "Statut",
        "unpaid": {
          "label": "En attente"
        },
        "edit": {
          "buttonLabel": "Modifier les détails du paiement"
        },
        "set": {
          "buttonLabel": "Modifier les détails du paiement"
        }
      },
      "amount": {
        "label": "Le montant payé"
      },
      "information": {
        "label": "Informations de paiement"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "reference": {
        "label": "Référence de paiement"
      },
      "startDueTime": {
        "label": "Début de la période de paiement"
      },
      "details": {
        "label": "Détails de paiement"
      }
    },
    "VATRate": {
      "label": "Taux de TVA"
    },
    "overview": {
      "label": "Aperçu"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF créé le"
        }
      },
      "number": {
        "label": "Numéro de document"
      }
    },
    "agent": {
      "number": {
        "label": "Numéro de client"
      },
      "company": {
        "TIN": {
          "label": "Numéro d'identification fiscale"
        }
      },
      "VATIN": {
        "label": "Numéro d'identification TVA"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Une erreur s'est produite dans notre service. Veuillez réessayer.",
        "title": "Erreur"
      }
    },
    "downloads": {
      "label": "Téléchargements",
      "downloadPDF": {
        "buttonLabel": "Télécharger le PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Résumé des données de rapport"
        }
      }
    },
    "details": {
      "label": "Détails"
    },
    "reportingPeriod": {
      "label": "Période de rapport",
      "startDate": {
        "label": "Date de début du rapport"
      },
      "endDate": {
        "label": "Date de fin du rapport"
      }
    },
    "credit": {
      "label": "Crédit",
      "amount": {
        "label": "Crédit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Fournitures intracommunautaires, total"
      },
      "transfer": {
        "label": "Transfert intra-communautaire"
      },
      "net": {
        "label": "Livraisons hors taxes"
      }
    },
    "parkingRate": {
      "label": "Taux d'imposition"
    },
    "sRR": {
      "label": "Taux d'imposition"
    },
    "taxFreeSales": {
      "label": "Ventes hors taxes"
    },
    "standardRate": {
      "label": "Taux d'imposition"
    },
    "authority": {
      "label": "Autorité"
    },
    "references": {
      "order": {
        "id": {
          "label": "Numéro de commande"
        }
      }
    },
    "outputVAT": {
      "label": "Taxe sur la valeur ajoutée"
    },
    "export": {
      "total": {
        "label": "Exportation"
      },
      "label": "Exportation"
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    },
    "interest": {
      "amount": {
        "label": "Montant des intérêts"
      },
      "label": "Intérêt"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "net": {
      "label": "Ventes nettes"
    },
    "report": {
      "type": {
        "label": "Type de rapport"
      },
      "label": "Rapport"
    },
    "reverseCharge": {
      "net": {
        "label": "Recettes nettes pour l'autoliquidation (Reverse charge)"
      },
      "label": "Inversion de l'obligation fiscale (Reverse charge)"
    },
    "reducedRate": {
      "label": "Taux d'imposition"
    },
    "taxableSales": {
      "label": "Ventes taxables"
    },
    "iCAOG": {
      "label": "Acquisition intracommunautaire",
      "total": {
        "net": {
          "label": "Somme des acquisitions intracommunautaires"
        }
      }
    },
    "deductibleTax": {
      "label": "Taxe en amont déductible"
    },
    "inputVAT": {
      "amount": {
        "label": "Taxe déductible des factures"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Taxe de vente à l'importation"
      }
    }
  },
  "mt-MT": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Istitut",
          "country": {
            "label": "Pajjiż tas-Sede tal-Istitut"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "sid tal-kont"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "L-istitut ta 'Payee",
          "country": {
            "label": "Pajjiż tas-Sede tal-Istitut"
          }
        },
        "account": {
          "owner": {
            "label": "sid tal-kont"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Użu"
      },
      "status": {
        "paid": {
          "label": "imħallas"
        },
        "label": "Status",
        "unpaid": {
          "label": "Pendenti"
        },
        "edit": {
          "buttonLabel": "Editja d-dettalji tal-ħlas"
        },
        "set": {
          "buttonLabel": "Editja d-dettalji tal-ħlas"
        }
      },
      "amount": {
        "label": "Ammont imħallas"
      },
      "information": {
        "label": "Informazzjoni dwar il-ħlas"
      },
      "endDueTime": {
        "label": "Tmiem il-perjodu tal-ħlas"
      },
      "instrument": {
        "label": "Strument tal-ħlas"
      },
      "reference": {
        "label": "Referenza tal-ħlas"
      },
      "startDueTime": {
        "label": "Bidu tal-perjodu tal-ħlas"
      },
      "details": {
        "label": "Dettalji taż-Żahlungs"
      }
    },
    "VATRate": {
      "label": "Rata tal-VAT"
    },
    "overview": {
      "label": "Ħarsa ġenerali"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF maħluq fuq"
        }
      },
      "number": {
        "label": "Numru tad-dokument"
      }
    },
    "agent": {
      "number": {
        "label": "Numru tal-klijent"
      },
      "company": {
        "TIN": {
          "label": "Numru Tat-Taxxa"
        }
      },
      "VATIN": {
        "label": "Numru ta 'identifikazzjoni tal-VAT"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Seħħ żball fis-servizz tagħna. Jekk jogħġbok erġa' pprova.",
        "title": "Żball"
      }
    },
    "downloads": {
      "label": "Tniżżil",
      "downloadPDF": {
        "buttonLabel": "Tniżżel PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Sommarju tad-dejta tar-rappurtar"
        }
      }
    },
    "details": {
      "label": "Dettalji"
    },
    "reportingPeriod": {
      "label": "Perjodu ta 'Rappurtar",
      "startDate": {
        "label": "Bidu tal-perjodu tar-rappurtar"
      },
      "endDate": {
        "label": "Tmiem tal-perjodu tar-rappurtar"
      }
    },
    "credit": {
      "label": "Kreditu",
      "amount": {
        "label": "Kreditu"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Kunsinni intra-komunitarji, totali"
      },
      "transfer": {
        "label": "Trasferiment intra-komunitarju"
      },
      "net": {
        "label": "Kunsinni bla taxxa"
      }
    },
    "parkingRate": {
      "label": "Rata tat-taxxa"
    },
    "sRR": {
      "label": "Rata tat-taxxa"
    },
    "taxFreeSales": {
      "label": "Bejgħ bla taxxa"
    },
    "standardRate": {
      "label": "Rata tat-taxxa"
    },
    "authority": {
      "label": "Awtorità"
    },
    "references": {
      "order": {
        "id": {
          "label": "Id tal-Ordni"
        }
      }
    },
    "outputVAT": {
      "label": "Taxxa fuq il-valur miżjud"
    },
    "export": {
      "total": {
        "label": "Esportazzjoni"
      },
      "label": "Esportazzjoni"
    },
    "totalPaymentDue": {
      "label": "Ammont tal-ħlas"
    },
    "interest": {
      "amount": {
        "label": "Ammont ta 'imgħax"
      },
      "label": "Interess"
    },
    "order": {
      "date": {
        "label": "data tal-ordni"
      }
    },
    "net": {
      "label": "Bejgħ nett"
    },
    "report": {
      "type": {
        "label": "Tip ta 'Rapport"
      },
      "label": "Rapport"
    },
    "reverseCharge": {
      "net": {
        "label": "Bejgħ nett għal reverse charge"
      },
      "label": "Treġġigħ lura ta 'obbligazzjoni tat-taxxa"
    },
    "reducedRate": {
      "label": "Rata tat-taxxa"
    },
    "taxableSales": {
      "label": "Bejgħ taxxabbli"
    },
    "iCAOG": {
      "label": "Akkwisti intra-komunitarji",
      "total": {
        "net": {
          "label": "Somma ta 'akkwisti intra-komunitarji"
        }
      }
    },
    "deductibleTax": {
      "label": "Taxxa fuq l-input deduċibbli"
    },
    "inputVAT": {
      "amount": {
        "label": "Taxxa fuq l-input mill-fatturi"
      }
    },
    "importVAT": {
      "amount": {
        "label": "VAT tal-importazzjoni"
      }
    }
  },
  "sl-SI": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Inštitut",
          "country": {
            "label": "Država sedeža Inštituta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Lastnik računa"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Inštitut prejemnika plačila",
          "country": {
            "label": "Država sedeža Inštituta"
          }
        },
        "account": {
          "owner": {
            "label": "Lastnik računa"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Uporaba"
      },
      "status": {
        "paid": {
          "label": "plačan"
        },
        "label": "Stanje",
        "unpaid": {
          "label": "V teku"
        },
        "edit": {
          "buttonLabel": "Uredite podatke o plačilu"
        },
        "set": {
          "buttonLabel": "Uredite podatke o plačilu"
        }
      },
      "amount": {
        "label": "Plačani znesek"
      },
      "information": {
        "label": "Informacije o plačilu"
      },
      "endDueTime": {
        "label": "Konec plačilnega obdobja"
      },
      "instrument": {
        "label": "Plačilni instrument"
      },
      "reference": {
        "label": "Sklic na plačilo"
      },
      "startDueTime": {
        "label": "Začetek plačilnega obdobja"
      },
      "details": {
        "label": "Podatki o plačilu"
      }
    },
    "VATRate": {
      "label": "Stopnja DDV"
    },
    "overview": {
      "label": "Pregled"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF, ustvarjen dne"
        }
      },
      "number": {
        "label": "Številka dokumenta"
      }
    },
    "agent": {
      "number": {
        "label": "Številka kupca"
      },
      "company": {
        "TIN": {
          "label": "Davčna številka"
        }
      },
      "VATIN": {
        "label": "Identifikacijska številka za DDV"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Prišlo je do napake v naši storitvi. Poskusite ponovno.",
        "title": "Napaka"
      }
    },
    "downloads": {
      "label": "Prenosi",
      "downloadPDF": {
        "buttonLabel": "Prenesite PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Povzetek podatkov poročanja"
        }
      }
    },
    "details": {
      "label": "Podrobnosti"
    },
    "reportingPeriod": {
      "label": "Obdobje poročanja",
      "startDate": {
        "label": "Datum začetka poročanja"
      },
      "endDate": {
        "label": "Končni datum poročanja"
      }
    },
    "credit": {
      "label": "Kredit",
      "amount": {
        "label": "Kredit"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Potrebščine znotraj skupnosti, skupaj"
      },
      "transfer": {
        "label": "Prenos znotraj skupnosti"
      },
      "net": {
        "label": "Brez davkov"
      }
    },
    "parkingRate": {
      "label": "Davčna stopnja"
    },
    "sRR": {
      "label": "Davčna stopnja"
    },
    "taxFreeSales": {
      "label": "Prodaja brez davkov"
    },
    "standardRate": {
      "label": "Davčna stopnja"
    },
    "authority": {
      "label": "Avtoriteto"
    },
    "references": {
      "order": {
        "id": {
          "label": "Številka naročila"
        }
      }
    },
    "outputVAT": {
      "label": "Davek na dodano vrednost"
    },
    "export": {
      "total": {
        "label": "Izvoz"
      },
      "label": "Izvoz"
    },
    "totalPaymentDue": {
      "label": "Znesek plačila"
    },
    "interest": {
      "amount": {
        "label": "Znesek obresti"
      },
      "label": "Obresti"
    },
    "order": {
      "date": {
        "label": "Datum naročila"
      }
    },
    "net": {
      "label": "Čisti prihodki od prodaje"
    },
    "report": {
      "type": {
        "label": "Vrsta Poročila"
      },
      "label": "Poročilo"
    },
    "reverseCharge": {
      "net": {
        "label": "Čisti prihodek za povratno bremenitev (Reverse charge)"
      },
      "label": "Razveljavitev davčne obveznosti (Reverse charge)"
    },
    "reducedRate": {
      "label": "Davčna stopnja"
    },
    "taxableSales": {
      "label": "Obdavčljiva prodaja"
    },
    "iCAOG": {
      "label": "Pridobitve znotraj Skupnosti",
      "total": {
        "net": {
          "label": "Vsota pridobitev znotraj skupnosti"
        }
      }
    },
    "deductibleTax": {
      "label": "Odbitni vstopni davek"
    },
    "inputVAT": {
      "amount": {
        "label": "Vhodni davek iz računov"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Uvozni prometni davek"
      }
    }
  },
  "el-CY": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Ινστιτούτο",
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Ινστιτούτο δικαιούχου πληρωμής",
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          }
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Σκοπός μεταφοράς"
      },
      "status": {
        "paid": {
          "label": "επί πληρωμή"
        },
        "label": "κατάσταση",
        "unpaid": {
          "label": "Εκκρεμής"
        },
        "edit": {
          "buttonLabel": "Επεξεργασία στοιχείων πληρωμής"
        },
        "set": {
          "buttonLabel": "Επεξεργασία στοιχείων πληρωμής"
        }
      },
      "amount": {
        "label": "Ποσό που καταβάλλεται"
      },
      "information": {
        "label": "Πληροφορίες Πληρωμής"
      },
      "endDueTime": {
        "label": "Λήξη της περιόδου πληρωμής"
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      },
      "reference": {
        "label": "Αναφορά πληρωμής"
      },
      "startDueTime": {
        "label": "Έναρξη της περιόδου πληρωμής"
      },
      "details": {
        "label": "Οι λεπτομέρειες πληρωμής"
      }
    },
    "VATRate": {
      "label": "Συντελεστή ΦΠΑ"
    },
    "overview": {
      "label": "ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "Το PDF δημιουργήθηκε στις"
        }
      },
      "number": {
        "label": "Αριθμός Εγγράφου"
      }
    },
    "agent": {
      "number": {
        "label": "Αριθμός των πελατών"
      },
      "company": {
        "TIN": {
          "label": "ΑΦΜ"
        }
      },
      "VATIN": {
        "label": "Αριθμός αναγνώρισης ΦΠΑ"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.",
        "title": "Σφάλμα"
      }
    },
    "downloads": {
      "label": "Λήψεις",
      "downloadPDF": {
        "buttonLabel": "Λήψη PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Περίληψη των δεδομένων αναφοράς"
        }
      }
    },
    "details": {
      "label": "Λεπτομέριες"
    },
    "reportingPeriod": {
      "label": "Περίοδος αναφοράς",
      "startDate": {
        "label": "Ημερομηνία έναρξης αναφοράς"
      },
      "endDate": {
        "label": "Ημερομηνία λήξης αναφοράς"
      }
    },
    "credit": {
      "label": "Πίστωση",
      "amount": {
        "label": "Πίστωση"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Ενδοκοινοτικές προμήθειες, σύνολο"
      },
      "transfer": {
        "label": "Ενδοκοινοτική μεταφορά"
      },
      "net": {
        "label": "Αφορολόγητες παραδόσεις"
      }
    },
    "parkingRate": {
      "label": "Φορολογικός συντελεστής"
    },
    "sRR": {
      "label": "Φορολογικός συντελεστής"
    },
    "taxFreeSales": {
      "label": "Αφορολόγητες πωλήσεις"
    },
    "standardRate": {
      "label": "Φορολογικός συντελεστής"
    },
    "authority": {
      "label": "εξουσία"
    },
    "references": {
      "order": {
        "id": {
          "label": "Αριθμός Παραγγελίας"
        }
      }
    },
    "outputVAT": {
      "label": "Φόρος Προστιθέμενης Αξίας"
    },
    "export": {
      "total": {
        "label": "εξαγωγή"
      },
      "label": "εξαγωγή"
    },
    "totalPaymentDue": {
      "label": "Ποσό ΠΛΗΡΩΜΗΣ"
    },
    "interest": {
      "amount": {
        "label": "Ποσό τόκου"
      },
      "label": "Ενδιαφέρον"
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας"
      }
    },
    "net": {
      "label": "Καθαρές πωλήσεις"
    },
    "report": {
      "type": {
        "label": "Τύπος Aναφοράς"
      },
      "label": "Κανω ΑΝΑΦΟΡΑ"
    },
    "reverseCharge": {
      "net": {
        "label": "Καθαρά έσοδα για αντίστροφη χρέωση (Reverse charge)"
      },
      "label": "αντιστροφή της φορολογικής υποχρέωσης (Reverse charge)"
    },
    "reducedRate": {
      "label": "Φορολογικός συντελεστής"
    },
    "taxableSales": {
      "label": "Steuerpflichtiger Umsatz"
    },
    "iCAOG": {
      "label": "Ενδοκοινοτικές εξαγορές",
      "total": {
        "net": {
          "label": "Άθροισμα ενδοκοινοτικών εξαγορών"
        }
      }
    },
    "deductibleTax": {
      "label": "Εκπεστέος φόρος εισροών"
    },
    "inputVAT": {
      "amount": {
        "label": "Φόρος εισροών από τιμολόγια"
      }
    },
    "importVAT": {
      "amount": {
        "label": "Εισαγωγή ΦΠΑ"
      }
    }
  },
  "tr-CY": {
    "payment": {
      "agent": {
        "bank": {
          "label": "Enstitü",
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "Alacaklı enstitüsü",
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          }
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "purpose": {
        "label": "Aktarımın amacı"
      },
      "status": {
        "paid": {
          "label": "ödenmiş"
        },
        "label": "Durum",
        "unpaid": {
          "label": "Askıda olan"
        },
        "edit": {
          "buttonLabel": "Ödeme ayrıntılarını düzenleme"
        },
        "set": {
          "buttonLabel": "Ödeme ayrıntılarını düzenleme"
        }
      },
      "amount": {
        "label": "Ödenen miktar"
      },
      "information": {
        "label": "Ödeme bilgileri"
      },
      "endDueTime": {
        "label": "Ödeme süresinin sonu"
      },
      "instrument": {
        "label": "Ödeme aracı"
      },
      "reference": {
        "label": "Ödeme referansı"
      },
      "startDueTime": {
        "label": "Ödeme döneminin başlangıcı"
      },
      "details": {
        "label": "Ödeme detayları"
      }
    },
    "VATRate": {
      "label": "KDV oranı"
    },
    "overview": {
      "label": "Genel bakış"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF tarihinde oluşturuldu"
        }
      },
      "number": {
        "label": "Belge Numarası"
      }
    },
    "agent": {
      "number": {
        "label": "Müşteri numarası"
      },
      "company": {
        "TIN": {
          "label": "Vergi numarası"
        }
      },
      "VATIN": {
        "label": "VAT kimlik numarası"
      }
    },
    "notification": {
      "asyncError": {
        "message": "Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin.",
        "title": "Hata"
      }
    },
    "downloads": {
      "label": "İndirilenler",
      "downloadPDF": {
        "buttonLabel": "PDF İndir"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "Raporlama verilerinin özeti"
        }
      }
    },
    "details": {
      "label": "Ayrıntılar"
    },
    "reportingPeriod": {
      "label": "Raporlama dönemi",
      "startDate": {
        "label": "Raporlama başlangıç tarihi"
      },
      "endDate": {
        "label": "Raporlama bitiş tarihi"
      }
    },
    "credit": {
      "label": "Kredi",
      "amount": {
        "label": "Kredi"
      }
    },
    "iCSOG": {
      "total": {
        "label": "Topluluk içi malzemeler, Toplam"
      },
      "transfer": {
        "label": "Topluluk içi aktarım"
      },
      "net": {
        "label": "Vergisiz teslimatlar"
      }
    },
    "parkingRate": {
      "label": "Vergi oranı"
    },
    "sRR": {
      "label": "Vergi oranı"
    },
    "taxFreeSales": {
      "label": "Vergisiz satışlar"
    },
    "standardRate": {
      "label": "Vergi oranı"
    },
    "authority": {
      "label": "Yetki"
    },
    "references": {
      "order": {
        "id": {
          "label": "Sipariş Kimliği"
        }
      }
    },
    "outputVAT": {
      "label": "Katma değer vergisi"
    },
    "export": {
      "total": {
        "label": "Ihracat"
      },
      "label": "Ihracat"
    },
    "totalPaymentDue": {
      "label": "Ödeme miktarı"
    },
    "interest": {
      "amount": {
        "label": "Faiz tutarı"
      },
      "label": "Faiz"
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi"
      }
    },
    "net": {
      "label": "Net satış"
    },
    "report": {
      "type": {
        "label": "Rapor Türü"
      },
      "label": "Rapor"
    },
    "reverseCharge": {
      "net": {
        "label": "Ters ibraz için net gelir (Reverse charge)"
      },
      "label": "Vergi borcunun iptali (Reverse charge)"
    },
    "reducedRate": {
      "label": "Vergi oranı"
    },
    "taxableSales": {
      "label": "Steuerpflichtiger Umsatz"
    },
    "iCAOG": {
      "label": "Topluluk içi edinimler",
      "total": {
        "net": {
          "label": "Topluluk içi satın almaların toplamı"
        }
      }
    },
    "deductibleTax": {
      "label": "İndirilebilir girdi vergisi"
    },
    "inputVAT": {
      "amount": {
        "label": "Faturalardan giriş vergisi"
      }
    },
    "importVAT": {
      "amount": {
        "label": "İthalat satış vergisi"
      }
    }
  },
  "uk-UA": {
    "payment": {
      "agent": {
        "bank": {
          "country": {}
        },
        "iban": {},
        "swiftcode": {},
        "account": {
          "owner": {}
        }
      },
      "recipient": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "iban": {},
        "swiftcode": {}
      },
      "purpose": {},
      "status": {
        "paid": {},
        "unpaid": {
          "label": "В очікуванні"
        },
        "edit": {},
        "set": {}
      },
      "amount": {},
      "information": {},
      "endDueTime": {},
      "instrument": {},
      "reference": {},
      "startDueTime": {},
      "details": {}
    },
    "VATRate": {},
    "overview": {},
    "document": {
      "created": {
        "startTime": {}
      },
      "number": {}
    },
    "agent": {
      "number": {},
      "company": {
        "TIN": {}
      },
      "VATIN": {}
    },
    "notification": {
      "asyncError": {}
    },
    "downloads": {
      "downloadPDF": {}
    },
    "reporting": {
      "data": {
        "summary": {}
      }
    },
    "details": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "credit": {
      "amount": {}
    },
    "iCSOG": {
      "total": {},
      "transfer": {},
      "net": {}
    },
    "parkingRate": {},
    "sRR": {},
    "taxFreeSales": {},
    "standardRate": {},
    "authority": {},
    "references": {
      "order": {
        "id": {}
      }
    },
    "outputVAT": {
      "label": "податок на додану вартість"
    },
    "export": {
      "total": {}
    },
    "totalPaymentDue": {},
    "interest": {
      "amount": {}
    },
    "order": {
      "date": {}
    },
    "net": {},
    "report": {
      "type": {}
    },
    "reverseCharge": {
      "net": {}
    },
    "reducedRate": {},
    "taxableSales": {},
    "iCAOG": {
      "total": {
        "net": {}
      }
    },
    "deductibleTax": {},
    "inputVAT": {
      "amount": {}
    },
    "importVAT": {
      "amount": {}
    }
  },
  "zh-CN": {
    "payment": {
      "agent": {
        "bank": {
          "label": "研究所",
          "country": {
            "label": "研究所所在地的国家"
          }
        },
        "iban": {
          "label": "伊班"
        },
        "swiftcode": {
          "label": "工商银行"
        },
        "account": {
          "owner": {
            "label": "帐户所有者"
          }
        }
      },
      "recipient": {
        "bank": {
          "label": "收款人学院",
          "country": {
            "label": "研究所所在地的国家"
          }
        },
        "account": {
          "owner": {
            "label": "帐户所有者"
          }
        },
        "iban": {
          "label": "伊班"
        },
        "swiftcode": {
          "label": "工商银行"
        }
      },
      "purpose": {
        "label": "用法"
      },
      "status": {
        "paid": {
          "label": "已付"
        },
        "label": "状态",
        "unpaid": {
          "label": "待定"
        },
        "edit": {
          "buttonLabel": "修改付款明细"
        },
        "set": {
          "buttonLabel": "修改付款明细"
        }
      },
      "amount": {
        "label": "支付的金额"
      },
      "information": {
        "label": "支付信息"
      },
      "endDueTime": {
        "label": "付款期结束"
      },
      "instrument": {
        "label": "付款方式"
      },
      "reference": {
        "label": "付款凭据"
      },
      "startDueTime": {
        "label": "付款期开始"
      },
      "details": {
        "label": "付款详情"
      }
    },
    "VATRate": {
      "label": "增值税率"
    },
    "overview": {
      "label": "总览"
    },
    "document": {
      "created": {
        "startTime": {
          "label": "PDF创建于"
        }
      },
      "number": {
        "label": "文件编号"
      }
    },
    "agent": {
      "number": {
        "label": "顾客号码"
      },
      "company": {
        "TIN": {
          "label": "税号"
        }
      },
      "VATIN": {
        "label": "增值税识别号"
      }
    },
    "notification": {
      "asyncError": {
        "message": "我们的服务发生了错误。 请再试一遍。",
        "title": "错误"
      }
    },
    "downloads": {
      "label": "资料下载",
      "downloadPDF": {
        "buttonLabel": "下载PDF"
      }
    },
    "reporting": {
      "data": {
        "summary": {
          "label": "报告数据汇总"
        }
      }
    },
    "details": {
      "label": "细节"
    },
    "reportingPeriod": {
      "label": "报告期",
      "startDate": {
        "label": "报告开始日期"
      },
      "endDate": {
        "label": "报告结束日期"
      }
    },
    "credit": {
      "label": "信用",
      "amount": {
        "label": "信用"
      }
    },
    "iCSOG": {
      "total": {
        "label": "社区内用品， 总"
      },
      "transfer": {
        "label": "社区内转移"
      },
      "net": {
        "label": "免税送货"
      }
    },
    "parkingRate": {
      "label": "税率"
    },
    "sRR": {
      "label": "税率"
    },
    "taxFreeSales": {
      "label": "免税销售"
    },
    "standardRate": {
      "label": "税率"
    },
    "authority": {
      "label": "权威"
    },
    "references": {
      "order": {
        "id": {
          "label": "订单编号"
        }
      }
    },
    "outputVAT": {
      "label": "增值税"
    },
    "export": {
      "total": {
        "label": "出口"
      },
      "label": "出口"
    },
    "totalPaymentDue": {
      "label": "支付金额"
    },
    "interest": {
      "amount": {
        "label": "利息金额"
      },
      "label": "兴趣"
    },
    "order": {
      "date": {
        "label": "订购日期"
      }
    },
    "net": {
      "label": "净销售额"
    },
    "report": {
      "type": {
        "label": "报告类型"
      },
      "label": "报告"
    },
    "reverseCharge": {
      "net": {
        "label": "冲销净收入 (Reverse charge)"
      },
      "label": "冲销应纳税额 (Reverse charge)"
    },
    "reducedRate": {
      "label": "税率"
    },
    "taxableSales": {
      "label": "应税销售"
    },
    "iCAOG": {
      "label": "社区内收购",
      "total": {
        "net": {
          "label": "社区内收购的总和"
        }
      }
    },
    "deductibleTax": {
      "label": "可抵扣进项税"
    },
    "inputVAT": {
      "amount": {
        "label": "发票进项税"
      }
    },
    "importVAT": {
      "amount": {
        "label": "进口销售税"
      }
    }
  }
}
</i18n>
